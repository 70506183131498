import React, { useEffect, useState } from 'react';

const ProtectedImage = ({ imageUrl, imageName,bearerToken }) => {
    const [imageData, setImageData] = useState(null);

    useEffect(() => {

        const fetchImage = async ()  => {
            try {
                const response = await fetch(imageUrl, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });

                if (response.ok) {
                    const imageBlob = await response.blob();
                    setImageData(URL.createObjectURL(imageBlob));
                } else {
                    // Handle the case when the image couldn't be fetched
                    console.error('Failed to fetch the image');
                }
            } catch (error) {
                console.error('Error fetching the image:', error);
            }
        };

        fetchImage();
    }, [imageUrl, bearerToken]);

    const handleDownload = () => {
        // Create a temporary anchor element and trigger the download
        const link = document.createElement('a');
        link.href = imageData;
        link.download = imageName; // Set the desired file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className='justify-content-center w-100 d-flex flex-column'>
            {imageData && <img src={imageData} alt="Protected Image" className='d-block my-4 item-center' />}
            {imageData && (
                <button className='btn btn-primary w-100 justify-content-center' onClick={handleDownload}>Download Image</button>
            )}
        </div>
    );
};

export default ProtectedImage;
