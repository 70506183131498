import React, {useEffect, useState} from "react";


import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import useLinodeApi from "../../../api/server/linodeApi";
import {useForm} from "react-hook-form";
import {Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle} from "../../../components/block/Block";
import {
    Button, Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon, PaginationComponent, RSelect, TooltipComponent
} from "../../../components/Component";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Modal,
    ModalBody,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import {location} from "../../../utils/Utils";
import Loader from "../../../layout/loader/Loader";
import Dropzone from "react-dropzone";
import axios from "axios";
import useStorageApi from "../../../api/storage/storageApi";

import useUserApi from "../../../api/user/userApi";
import useAzureStorageApi from "../../../api/storage/AzurestorageApi";

const Azure = ()=>{
    const {
        list,
        edit,
        store,
        totalStorage
    } = useAzureStorageApi();

    const  userApi = useUserApi(); //

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);

    const [modal, setModal] = useState({
        edit: false,
        add: false,
        url: false,
    });
    const [formData, setFormData] = useState({
        name: "",
        location: ""
    });

    const [loading, setLoading] = useState(true);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");

    const [data, setData] = useState();
    const [idEdit, setIdEdit] = useState();
    const [links, setLinks] = useState({});
    const [users, setusers] = useState();
    const [files, setFiles] = useState([]);
    const [StorageAzure, setStorageAzure] = useState({});


    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [currentItems, setIcurrentItems] = useState([]);
    const [indexOfLastItem, setindexOfLastItem] = useState(0);
    const [indexOfFirstItem, setindexOfFirstItem] = useState(0);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const init = async () => {
        setLoading(false)
        const result = await list(itemPerPage, currentPage ,onSearchText)
        if (result.success) {
            setData([...result.data.data])
            setCurrentPage(result.data.current_page)
            setItemPerPage(result.data.per_page)
            setIcurrentItems(result.data.data)
            setindexOfFirstItem(result.from)
            setindexOfLastItem(result.data.last_page)
            setTotalItems(result.data.total)
            setLoading(true)
           const Storage= await   totalStorage()
            if (Storage.success) {
                const processedStorageObject = Object.fromEntries(
                    Object.entries(Storage.data).map(([region, array]) => {
                        // Perform some operation on the array, for example, getting the length
                        const arrayLength = array.length;

                        // Return a new key-value pair with the processed array
                        return [region, arrayLength];
                    })
                );
                setStorageAzure(processedStorageObject)
            }





        }
    }

    useEffect(async () => {
        await init()
    }, [currentPage, itemPerPage]);

    const selectorCheck = (e) => {
        const  newData = currentItems.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setIcurrentItems([...newData]);
    };

    const onSelectChange = (e, id) => {
        let newData = currentItems;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setIcurrentItems([...newData]);
    };

    const toggle = () => {
        setonSearch(!onSearch);
        setSearchText('')
    };

    const handleeUrl = async (item) => {
        const result = await edit(item.name)
        if (result.success) {
            setIdEdit(item.id)
            setLinks(result.data)
            setModal({url: true})
        }
    };

    const resetForm = () => {
        setFormData({

        });
        setModal({
            edit: false,
            add: false,
            url: false,
        });

    };

    const onFormCancel = () => {
        setModal({
            edit: false,
            add: false,
            url: false,
        });
        handleClearFiles();

        resetForm();
    };

    const {errors, register, handleSubmit} = useForm();

    const onFormSubmit = async (submitData) => {
        const form = new FormData();
        files.forEach((file) => {
            form.append('files[]', file);
        });

        form.append('name', submitData.name);
        form.append('location', formData.location);
        onFormCancel()

        const result =await  store(form)
        if (result.success) {
            await init()
        }

    };

    const onEditSubmit = async (submitData) => {

        const { subscriptionId, tenantId,clientId,clientSecret } = submitData;
        let submittedData = {
            subscriptionId: subscriptionId,
            tenantId:tenantId,
            clientId:clientId,
            clientSecret:clientSecret,
        };

        const result = await userApi.updateAzure({ credentials:submittedData})
        if (result.success) {
            await init()
            resetForm();
        }

    };
    const onClickEditAws= async () => {

        const result = await userApi.editAws()
        if (result.success) {
            setIdEdit(result.data)
            setModal({edit: true})
            console.log(idEdit)
        }

    };



    const handleDropChange = (acceptedFiles) => {
        setFiles(
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
    };

    const handleClearFiles = () => {
        setFiles([]);
    };

    async function copyToClipboard(textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport, so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    }
    const fillterhandel = async (e) => {
        console.log('fsfs',onSearchText)
        await init()
    };

    const onFilterChange = async (e) => {
        setSearchText(e.target.value);
    };

    return (<>
        <Head title="Azure"></Head>

        <Content>
            {loading ? <>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Azure
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>
                                    vous avez un total de {   currentItems.length } File.
                                </p>
                            </BlockDes>
                        </BlockHeadContent>

                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content" style={{display: sm ? "block" : "none"}}>
                                    <ul className="nk-block-tools  g-1">

                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon btn-sm"
                                                    onClick={() => setModal({add: true})}>
                                                <Icon name="plus"></Icon>
                                            </Button>

                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <Button color="danger" className="btn-icon btn-sm"
                                                    onClick={() => setModal({edit: true})}>
                                                <Icon name="trash"></Icon>
                                            </Button>
                                        </li>
                                        <li className="nk-block-tools-opt ">
                                            <Button color="warning" className="btn-icon btn-sm"
                                                    onClick={() => {onClickEditAws()}}>
                                                <Icon name="opt-dot"></Icon>
                                            </Button>

                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block className='w-full'>
                    <DataTable className="card-stretch text-nowrap">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className='row'>
                                {Array.isArray(StorageAzure)
                                    ? StorageAzure.map(([region, value], index) => (
                                        <div key={index} className='col-1'>
                                            <div className='font-bold text-uppercase'>{region}</div>
                                            <div className='text-primary font-bold'>{value}<sup className='text-dark'> 250</sup></div>
                                        </div>
                                    ))
                                    : Object.entries(StorageAzure).map(([region, value], index) => (
                                        <div key={index} className='col-1'>
                                            <div className='font-bold text-uppercase'>{region}</div>
                                            <div className='text-primary font-bold'>{value}<sup className='text-dark'> 250</sup></div>
                                        </div>
                                    ))}

                            </div>


                        </div>
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-tools">
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                                                    <ul className="btn-toolbar gx-1">

                                                        <li className="btn-toolbar-sep"></li>
                                                        <li>
                                                            <Button
                                                                href="#search"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    toggle();
                                                                }}
                                                                className="btn-icon search-toggle toggle-search"
                                                            >
                                                                <Icon name="search"></Icon>
                                                            </Button>
                                                        </li>

                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="a"
                                                                                className="btn btn-trigger btn-icon dropdown-toggle">
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        <li className={itemPerPage === 10 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 20 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)
                                                                                    setItemPerPage(20);
                                                                                }}
                                                                            >
                                                                                20
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 30 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)
                                                                                    setItemPerPage(30);
                                                                                }}
                                                                            >
                                                                                30
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 20 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)

                                                                                    setItemPerPage(50);
                                                                                }}
                                                                            >
                                                                                50
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 100 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)

                                                                                    setItemPerPage(100);
                                                                                }}
                                                                            >
                                                                                100
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 150 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)

                                                                                    setItemPerPage(150);
                                                                                }}
                                                                            >
                                                                                150
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 150 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)

                                                                                    setItemPerPage(500);
                                                                                }}
                                                                            >
                                                                                500
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 1000 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)

                                                                                    setItemPerPage(1000);
                                                                                }}
                                                                            >
                                                                                1000
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                        onClick={ () => {
                                        toggle()
                                    }}>
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Search by server "
                                            onChange={(e) => onFilterChange(e)}


                                        />
                                        <Button className="search-submit btn-icon" onClick={ () => {
                                             fillterhandel()
                                        }}>
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody>
                            <DataTableHead>
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text">Offer Name</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text">File Name</span>
                                </DataTableRow>

                                <DataTableRow size="lg">
                                    <span className="sub-text" size="sm">User</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">Location</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">Created At </span>
                                </DataTableRow>


                                <DataTableRow size="lg" className="nk-tb-col-tools text-end">

                                </DataTableRow>
                            </DataTableHead>
                            {/*Head*/}
                            {

                                currentItems.length > 0

                                    ?  currentItems.map((item,index) => {
                                        return (<DataTableItem key={item.id}>
                                                { index > 0 && item.name ===currentItems[index-1]['name']?<>
                                                    <DataTableRow className=" border-0  bg-secondary-dim">

                                                    </DataTableRow>
                                                    <DataTableRow  className=" border-0  bg-secondary-dim">

                                                    </DataTableRow>


                                                </>:<>
                                                    <DataTableRow className="nk-tb-col-check">
                                                        <div
                                                            className="custom-control custom-control-sm custom-checkbox notext ">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                defaultChecked={item.checked}
                                                                id={item.id + "uid1"}
                                                                key={Math.random()}
                                                                onChange={(e) => onSelectChange(e, item.id)}
                                                            />
                                                            <label className="custom-control-label"
                                                                   htmlFor={item.id + "uid1"}></label>
                                                        </div>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                  <span className="tb-amount ">
                                                    {item?.name}
                                                  </span>
                                                    </DataTableRow>

                                                </>}

                                                <DataTableRow>
                                                  <span className="tb-amount">
                                                    {
                                                        item?.url.split('/')[item?.url.split('/').length - 1]
                                                    }
                                                  </span>
                                                </DataTableRow>

                                                <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.responsible?.name}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.location}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow size="lg">
                                                    <ul className="list-status">
                                                        <li>
                                                            <Icon
                                                                className={`text-success`}
                                                                name="alarm-alt"
                                                            ></Icon>{" "}
                                                            <span> {item?.created_at}</span>
                                                        </li>

                                                    </ul>
                                                </DataTableRow>
                                                <DataTableRow size="lg" className="nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        <li className="nk-tb-action-hidden" onClick={() => handleeUrl(item)}>
                                                            <TooltipComponent
                                                                tag="a"
                                                                containerClassName="btn btn-trigger btn-icon"
                                                                id={"Url" + item.id}
                                                                icon="unlink"
                                                                direction="top"
                                                                text="Obtenir des liens d'offre"
                                                            />
                                                        </li>


                                                    </ul>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })
                                    : null}
                        </DataTableBody>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={totalItems}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
                <Modal isOpen={modal.add} toggle={() => setModal({add: false})} className="modal-dialog-centered"
                       size="lg">
                    <ModalBody>
                        <a
                            href="#close"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Add New File in Azure</h5>
                            <div className="mt-4">
                                <Form className="mt-4" onSubmit={handleSubmit(onFormSubmit)} noValidate>
                                    <Row className="g-gs mt-2">
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Name Offer</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    defaultValue={formData.name}
                                                    className="form-control"
                                                    ref={register({required: "This field is required"})}
                                                />
                                                {errors.name &&
                                                    <span className="invalid">{errors.name.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Location</label>
                                                <div className="form-control-wrap">
                                                    <RSelect
                                                        name='location'
                                                        options={[
                                                            {
                                                                value: 'eastus',
                                                                label: 'eastus'
                                                            },
                                                            {
                                                                value: 'eastus2',
                                                                label: 'eastus2'
                                                            },
                                                            {
                                                                value: 'southcentralus',
                                                                label: 'southcentralus'
                                                            },
                                                            {
                                                                value: 'westus2',
                                                                label: 'westus2'
                                                            },
                                                            {
                                                                value: 'westus3',
                                                                label: 'westus3'
                                                            },
                                                            {
                                                                value: 'centralus',
                                                                label: 'centralus'
                                                            },
                                                            {
                                                                value: 'westus',
                                                                label: 'westus'
                                                            },
                                                            {
                                                                value: 'westcentralus',
                                                                label: 'westcentralus'
                                                            },
                                                            {
                                                                value: 'northcentralus',
                                                                label: 'northcentralus'
                                                            },
                                                            {
                                                                value: 'uksouth',
                                                                label: 'northcentralus'
                                                            },
                                                            {
                                                                value: 'ukwest',
                                                                label: 'ukwest'
                                                            },
                                                            {
                                                                value: 'canadacentral',
                                                                label: 'canadacentral'
                                                            },
                                                            {
                                                                value: 'canadaeast',
                                                                label: 'canadaeast'
                                                            },
                                                        ]}
                                                        defaultValue={  {
                                                            value: 'eastus',
                                                            label: 'eastus'
                                                        }}
                                                        ref={register({required: "This field is required"})}
                                                        onChange={(e) => setFormData({...formData, location: e.value})}
                                                    />
                                                </div>
                                                {errors.location && <span className="invalid">{errors.location.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Files</label>
                                                <div className="form-control-wrap">
                                                    <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)} accept={['.png', '.jpg', '.jpeg', '.gif', '.html']}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                                                    <input {...getInputProps()} />
                                                                    {files.length === 0 && (
                                                                        <div className="dz-message">
                                                                            <span className="dz-message-text">Drag and drop file</span>
                                                                            <span className="dz-message-or">or</span>
                                                                            <button className="btn btn-primary" type='button'>UPLOAD</button>
                                                                        </div>
                                                                    )}
                                                                    {files.map((file) => (
                                                                        <div key={file.name} className="dz-preview dz-image-preview">
                                                                            <div className="dz-image">
                                                                                {file.type === 'text/html' ? (
                                                                                    <svg className="w-[40px] h-[40px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                                                        <path d="M.906 0 2.5 17.683l7.5 2.159 7.544-2.158L19.092 0H.906ZM15.1 6H7.044l.174 2h7.776l-.632 6.513-4.29 1.371-4.326-1.444-.29-2.909H7.5l.163 1.4 2.424.809 2.37-.757.3-2.982H5.368L4.8 4h10.519L15.1 6Z"/>
                                                                                    </svg>
                                                                                ) : (
                                                                                    <img src={file.preview} alt="preview" />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </div>
                                        </Col>


                                    </Row>

                                    <Row className="gy-4 mt-2">

                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button color="primary" size="md" type="submit">
                                                        upload Files
                                                    </Button>
                                                </li>

                                                <li>
                                                    <a
                                                        href="# Clear"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            handleClearFiles();
                                                        }}
                                                        className="link link-danger "
                                                    >
                                                        Clear Files
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#cancel"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            onFormCancel();
                                                            handleClearFiles();
                                                        }}
                                                        className="link link-light"
                                                    >
                                                        Annuler
                                                    </a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Form>

                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={modal.edit} toggle={() => setModal({edit: false})} className="modal-dialog-centered"
                       size="lg">
                    <ModalBody>
                        <a
                            href="#close"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Key For Azure</h5>
                            <div className="mt-2">
                                <Form className="mt-2" onSubmit={handleSubmit(onEditSubmit)} noValidate>
                                    <Row className="g-gs mt-2">
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Subscription Id</label>
                                                <input
                                                    type="text"
                                                    name="subscriptionId"
                                                    defaultValue={idEdit?.azure?.subscriptionId}

                                                    className="form-control"
                                                    ref={register({required: "This field is required"})}
                                                />
                                                {errors.subscriptionId &&
                                                    <span className="invalid">{errors.subscriptionId.message}</span>}
                                            </div>
                                        </Col>

                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Tenant Id</label>
                                                <input
                                                    type="text"
                                                    name="tenantId"
                                                    defaultValue={idEdit?.azure?.tenantId}

                                                    className="form-control"
                                                    ref={register({required: "This field is required"})}
                                                />
                                                {errors.tenantId &&
                                                    <span className="invalid">{errors.tenantId.message}</span>}
                                            </div>
                                        </Col>


                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Client Id</label>
                                                <input
                                                    type="text"
                                                    name="clientId"
                                                    defaultValue={idEdit?.azure?.clientId}

                                                    className="form-control"
                                                    ref={register({required: "This field is required"})}
                                                />
                                                {errors.clientId &&
                                                    <span className="invalid">{errors.clientId.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">client Secret</label>
                                                <input
                                                    type="text"
                                                    name="clientSecret"
                                                    defaultValue={idEdit?.azure?.clientSecret}

                                                    className="form-control"
                                                    ref={register({required: "This field is required"})}
                                                />
                                                {errors.clientSecret &&
                                                    <span className="invalid">{errors.clientSecret.message}</span>}
                                            </div>
                                        </Col>

                                    </Row>

                                    <Row className="gy-4 mt-2">

                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button color="primary" size="md" type="submit">
                                                        Save
                                                    </Button>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#cancel"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            onFormCancel();
                                                        }}
                                                        className="link link-light"
                                                    >
                                                        Cancel
                                                    </a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Form>

                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={modal.url} toggle={() => setModal({url: false})} className="modal-dialog-centered"
                       size="xl" style={{maxWidth: '55em'}}>
                    <ModalBody>
                        <a
                            href="#close"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Obtenir des liens d'offre</h5>
                            <div className="mt-4">
                                <table className="table table-ulogs">
                                    <thead className="bg-lighter">
                                    <tr className='d-none'>
                                        <th colSpan={5} className={'text-center'}>
                                            <Button className='btn btn-primary  btn-sm w-100 text-center justify-content-center' >Copy</Button>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th  colSpan={4} className="tb-col-os">
                                            <span className="overline-title">Link</span>
                                        </th>
                                        <th className="tb-col-action">
                                        </th>

                                    </tr>
                                    </thead>

                                    <tbody>
                                    {(links?.length) ? links?.map((item) => {
                                        return (
                                            <tr  key={Math.random()}>
                                                <td  colSpan={4} className="tb-col-os">
                                                    <a className="text-primary text-center " href={item?.url} target={'_blank'}>{item.url}</a>
                                                </td>
                                                <td className="tb-col-action">
                                                    <Button className='btn btn-primary btn-xs'
                                                            onClick={async () => {
                                                                await copyToClipboard(
                                                                    '' + item.url
                                                                )
                                                            }}>Copy</Button>
                                                </td>
                                            </tr>
                                        )}) :<></>}


                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </ModalBody>
                </Modal>


            </> : <Loader/>}
        </Content>
    </>)
}
export default Azure;
