import { Card, Spinner } from "reactstrap";
import React from "react";


const Loader = () =>{
  return (<Card  style={{ width: "100%", height: "80vh" }} className="justify-content-around" color="white">
    <div className="card-inner  text-center justify-content-center my-4">
      <Spinner style={{ width: "6rem", height: "6rem" }}  color="primary" />
      <div className='text-uppercase fw-bold text-secondary mt-4 '>chargement des données</div>
      <div className='text-sm text-gray'>Merci de patienter</div>
    </div>
  </Card>)
}

export default Loader