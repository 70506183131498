import React from "react";
import Select from "react-select";

const RSelect = ({ ...props }) => {
  return (
    <div className="form-control-select w-100">
      <Select

        className={`react-select-container z-10 ${props.className ? props.className : ""}`}
        classNamePrefix="react-select"
        {...props}
      />
    </div>
  );
};

export default RSelect;
