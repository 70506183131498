import React, {  useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useForm } from "react-hook-form";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../../components/block/Block";
import {
    Button, Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon, PaginationComponent, RSelect, TooltipComponent
} from "../../../components/Component";
import { DropdownItem, DropdownMenu, DropdownToggle, Form, Modal, ModalBody, UncontrolledDropdown } from "reactstrap";
import Loader from "../../../layout/loader/Loader";
import  useGroupApi from "../../../api/group/groupApi";
import useUserApi from "../../../api/user/userApi";

const Group = () => {
    const {
        list,
        create,
        store,
        edit,
        update,
        remove
    } = useGroupApi();

    const userApi = useUserApi();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [users, setusers] = useState();
    const [sm, updateSm] = useState(false);
    const [modal, setModal] = useState({
        edit: false,
        add: false,
    });
    const [editId, setEditedId] = useState();
    const [totalItems, setTotalItems] = useState(0);
    const [formData, setFormData] = useState({
        name: "",
        leader:{},
        users:[],
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [currentItems, setIcurrentItems] = useState([]);
    const [indexOfLastItem, setindexOfLastItem] = useState(0);
    const [indexOfFirstItem, setindexOfFirstItem] = useState(0);

    const init =async () => {
        const result = await list(itemPerPage, currentPage)
        if (result.success) {
            setData([...result.data.data])
            setCurrentPage(result.data.current_page)
            setItemPerPage(result.data.per_page)
            setIcurrentItems(result.data.data)
            setindexOfFirstItem(result.from)
            setindexOfLastItem(result.data.last_page)
            setTotalItems(result.data.total)
            setLoading(false)
        }
    }
    //  loads data
    useEffect(async () => {
        setLoading(true)
        const result = await userApi.list(10000, 1)
        if (result.success) {
            setusers([...result.data.data])
        }
        await init()

    }, [currentPage]);

    // function to reset the form
    const resetForm = () => {
        setFormData({
            name: "",
        });
    };

    // function to close the form modal
    const onFormCancel = () => {
        setModal({ edit: false, add: false });
        resetForm();
    };

    // submit function to add a new item
    const onFormSubmit = async ({ name }) => {

        let submittedData = {
            id: data.length + 1,
            name: name,
            leader: formData.leader,
            users: formData.users,
        };
        const result = await store(submittedData)
        if(result.success){
            await init()

            resetForm();
            setModal({ edit: false }, { add: false });
        }
    };

    // submit function to update a new item
    const onEditSubmit = async (submitData) => {


        const submitDataUpdate ={
            id: formData.id,
            name: formData.name,
            leader:{label:formData.leader.name,value:formData.leader.id},
            users: formData?.users?.map((item)=>{ return {label:item.name,value:item.id}}),
        };



        const result = await update(formData,editId)
        if(result.success){
            await init()
            setModal({ edit: false });
            resetForm();
        }
    };

    // function that loads the want to editted data
    const onEditClick = (item) => {
        let submittedData = {
            id: item.id,
            name: item.name,
            leader:{label:item.team_leader.name,value:item.team_leader.id},

            users: item?.users?.map((item)=>{ return {label:item.name,value:item.id}}),

        };

        setFormData(submittedData)
        setEditedId(item.id)
        console.log(formData)
        setModal({ edit: true }, { add: false });

    };

    // function delete item
    const onDeleteClick = async (id) => {
        const result = await remove(id)
        if (result.success){
            setIcurrentItems(currentItems.filter((item) => item.id !== id));
            setData([currentItems])
        }
    };

    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const { errors, register, handleSubmit } = useForm();

    const [selectedUsers, setSelectedUsers] = useState(null);
    const [selectedLeader, setSelectedLeader] = useState(null);

    const handleUsersChange = (selectedOption) => {
        setSelectedUsers(selectedOption);
    };
    const handleLeaderChange = (selectedOption) => {
        setSelectedLeader(selectedOption);
    };

    return (
        <React.Fragment>
            <Head title="Catégories des dépenses"></Head>
            <Content>
                {!loading? <>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle tag="h3" page>
                                    Manage Group
                                </BlockTitle>
                                <BlockDes className="text-soft">
                                    <p></p>
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <Button
                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                                        onClick={() => updateSm(!sm)}
                                    >
                                        <Icon name="menu-alt-r"></Icon>
                                    </Button>
                                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                        <ul className="nk-block-tools g-3">
                                            <li className="nk-block-tools-opt">
                                                <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                                                    <Icon name="plus"></Icon>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>

                    <Block>
                        <DataTable className="card-stretch">

                            <DataTableBody>
                                <DataTableHead>
                                    <DataTableRow>
                                        <span className="sub-text">Groups</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Leader</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Users</span>
                                    </DataTableRow>

                                    <DataTableRow className="nk-tb-col-tools text-end">

                                    </DataTableRow>
                                </DataTableHead>
                                {/*Head*/}
                                {currentItems.length > 0
                                    ? currentItems.map((item) => {
                                        return (
                                            <DataTableItem key={item.id}>
                                                <DataTableRow size="mb">
                          <span className="tb-amount">
                            {item.name}
                          </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="user-card sm">
                                                        <div className="user-avatar text-uppercase">
                                                            <span>{item?.team_leader?.name.substring(0, 2)}</span>
                                                        </div>
                                                        <div className="user-info">
                                                            <span className="lead-text"> {item.team_leader?.name}</span>
                                                            <span className="sub-text"> {item.team_leader?.email}</span>
                                                        </div>
                                                    </div>

                                                </DataTableRow>
                                                <DataTableRow>
                                                    {item?.users?.map((user)=>{
                                                        return(<>
                                                            <div className="user-card sm my-2" key={user.id}>
                                                                <div className="user-avatar text-uppercase">
                                                                    <span>{user?.name.substring(0, 2)}</span>
                                                                </div>
                                                                <div className="user-info">
                                                                    <span className="lead-text"> {user?.name}</span>
                                                                    <span className="sub-text"> {user?.email}</span>
                                                                </div>
                                                            </div>
                                                        </>)
                                                    })}
                                                </DataTableRow>
                                                <DataTableRow className="nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        <li className="nk-tb-action-hidden" onClick={() => onEditClick(item)}>
                                                            <TooltipComponent
                                                                tag="a"
                                                                containerClassName="btn btn-trigger btn-icon"
                                                                id={"edit" + item.id}
                                                                icon="edit-alt-fill"
                                                                direction="top"
                                                                text="Modifer"
                                                            />
                                                        </li>
                                                        <li className="nk-tb-action-hidden" onClick={() => onDeleteClick(item.id)}>
                                                            <TooltipComponent
                                                                tag="a"
                                                                containerClassName="btn btn-trigger btn-icon"
                                                                id={"edit" + item.id}
                                                                icon="trash"
                                                                direction="top"
                                                                text="Supprimer"
                                                            />
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                                                    <Icon name="more-h"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end>
                                                                    <ul className="link-list-opt no-bdr">
                                                                        <li onClick={() => onEditClick(item)}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#edit"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                            >
                                                                                <Icon name="edit"></Icon>
                                                                                <span>Modifer</span>
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li onClick={() => onDeleteClick(item.id)}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#edit"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                            >
                                                                                <Icon name="trash"></Icon>
                                                                                <span>Supprimer</span>
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })
                                    : null}
                            </DataTableBody>
                            <div className="card-inner">
                                {currentItems.length > 0 ? (
                                    <PaginationComponent
                                        itemPerPage={itemPerPage}
                                        totalItems={totalItems}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <span className="text-silent">No data found</span>
                                    </div>
                                )}
                            </div>
                        </DataTable>
                    </Block>
                    <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
                        <ModalBody>
                            <a
                                href="#close"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    onFormCancel();
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">Add New Group</h5>
                                <div className="mt-4">
                                    <Form className="row gy-4" noValidate onSubmit={handleSubmit(onFormSubmit)}>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Nom</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="name"
                                                    defaultValue={formData.name}
                                                    placeholder="Enter Name "
                                                    ref={register({ required: "This field is required" })}
                                                />
                                                {errors.name && <span className="invalid">{errors.name.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">User leader</label>
                                                <RSelect
                                                          name='accounts'
                                                          options={users?.map((item)=>{ return {label:item.name,value:item.id}})}
                                                          onChange={(e)=>{
                                                              setFormData({formData,leader:e})

                                                          }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Users</label>
                                                <RSelect
                                                    isMulti
                                                    name='accounts'
                                                    options={users?.map((item)=>{ return {label:item.name,value:item.id}})}
                                                    onChange={(e)=>{
                                                        setFormData({...formData,users:e})
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Actions</label>
                                                <RSelect
                                                    isMulti
                                                    name='actions'
                                                    options={[
                                                        {label:'Start',value:'Start'},
                                                        {label:'Stop',value:'Stop'},
                                                        {label:'Clean ',value:'Clean'},
                                                        {label:'Delete Vmta',value:'Vmta'},
                                                        {label:'Get Ips',value:'Ips'},
                                                    ]}
                                                    onChange={(e)=>{
                                                        setFormData({...formData,actions:e})
                                                    }}
                                                />
                                            </div>
                                        </Col>


                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button color="primary" size="md" type="submit">
                                                        Add New Group
                                                    </Button>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#cancel"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            onFormCancel();
                                                        }}
                                                        className="link link-light"
                                                    >
                                                        Cancel
                                                    </a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
                        <ModalBody>
                            <a
                                href="#cancel"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    onFormCancel();
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">Update Group {formData.name}</h5>
                                <div className="mt-4">
                                    <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Nom</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="name"
                                                    defaultValue={formData.name}
                                                    placeholder="Enter Name Group"
                                                    ref={register({ required: "This field is required" })}
                                                />
                                                {errors.name && <span className="invalid">{errors.name.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">User leader</label>
                                                <RSelect
                                                    name='accounts'
                                                    options={users?.map((item)=>{ return {label:item.name,value:item.id}})}
                                                    defaultValue={ [{label:formData?.leader?.label,value:formData?.leader?.value}]}

                                                    onChange={(e)=>{
                                                        setFormData({...formData,leader: e})


                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Users</label>
                                                <RSelect
                                                    isMulti
                                                    name='accounts'
                                                    options={users?.map((item)=>{ return {label:item.name,value:item.id}})}
                                                    defaultValue={formData?.users?.map((item)=>{ return {label:item.label,value:item.value}})}
                                                    onChange={(e)=>{
                                                        setFormData({...formData,users:e})
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Actions</label>
                                                <RSelect
                                                    isMulti
                                                    name='actions'
                                                    options={[
                                                        {label:'Start',value:'Start'},
                                                        {label:'Stop',value:'Stop'},
                                                        {label:'Clean ',value:'Clean'},
                                                        {label:'Delete Vmta',value:'Vmta'},
                                                        {label:'Get Ips',value:'Ips'},
                                                    ]}
                                                    defaultValue={formData?.actions?.map((item)=>{ return {label:item.label,value:item.value}})}

                                                    onChange={(e)=>{
                                                        setFormData({...formData,actions:e})
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button color="primary" size="md" type="submit">
                                                        Update Group
                                                    </Button>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#cancel"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            onFormCancel();
                                                        }}
                                                        className="link link-light"
                                                    >
                                                        Cancel
                                                    </a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </>:<Loader/>}

            </Content>
        </React.Fragment>
    );
};

export default Group;
