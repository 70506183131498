import React, {useEffect, useState} from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {useForm} from "react-hook-form";
import RDnsSelect from "../../../components/select/ReverseDnsSelect";

import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../../components/block/Block";
import {
    Button, Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon, PaginationComponent, RSelect, TooltipComponent
} from "../../../components/Component";
import {
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Modal,
    ModalBody,
    Row, Spinner, Tooltip,
    UncontrolledDropdown
} from "reactstrap";
import Loader from "../../../layout/loader/Loader";
import useAzureApi from "../../../api/server/azureApi";
import {doesValueExist, location, todaysDate} from "../../../utils/Utils";
import LoaderTable from "../../../layout/loader/LoaderTable";
import DatePicker from "react-datepicker";
import Server from "../../../layout/sidebar/Servers";
import useAccountApi from "../../../api/account/accountApi";
import useUserApi from "../../../api/user/userApi";
import useAuth from "../../../hooks/useAuth";
import useNamecheapApi from "../../../api/namecheap/namecheapApi";

const Azure = () => {
    const {
        list,
        store,
        update,
        rdns,
        remove,
        handlePostAction
    } = useAzureApi(); //  use ActivityApi custom hook
    const userApi = useUserApi(); //
    const namecheapApi = useNamecheapApi(); //

    const {setAuth, auth} = useAuth();


    const [loading, setLoading] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");

    const [data, setData] = useState();
    const [users, setusers] = useState();
    const [serverTransfer, setServerTransfer] = useState({});

    const [sm, updateSm] = useState(false);
    const [modal, setModal] = useState({
        edit: false,
        clock: false,
        add: false,
        ips: false,
        allrdns: false,
        users: false,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [formData, setFormData] = useState({
        id: "",
        team: "",
        location: "",
        ips: "",
        size: "",
    });
    const [idEdit, setIdEdit] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [currentItems, setIcurrentItems] = useState([]);
    const [indexOfLastItem, setindexOfLastItem] = useState(0);
    const [indexOfFirstItem, setindexOfFirstItem] = useState(0);
    const [options, setOptions] = useState([
        {value: "", apiKey: '', selected: false},

        {value: "", apiKey: '', selected: false},
        {value: "", apiKey: '', selected: false},
    ]);


    const [choosinDns, setChoosinDns] = useState(false);

    const [accounts, setAccounts] = useState(options[0]);
    const [mapping, setMapping] = useState([]);


    const init = async () => {

        const result = await list(itemPerPage, currentPage, onSearchText)
        if (result.success) {
            setData([...result.data.data])
            setCurrentPage(result.data.current_page)
            setItemPerPage(result.data.per_page)
            setIcurrentItems(result.data.data)
            setindexOfFirstItem(result.from)
            setindexOfLastItem(result.data.last_page)
            setTotalItems(result.data.total)
            setLoading(true)

        }

    }
    const getUsers = async () => {
        const result = await userApi.list(1000, 1)
        if (result.success) {
            setusers([...result.data.data])

        }
        const namecheapResult = await namecheapApi.list(1000, 1);
        if (namecheapResult.success) {
            const Data = namecheapResult.data.data;
            setOptions(Data.map((item) => {
                return { value: item.user_name, apiKey: item.key, selected: false };
            }));
        }
    }
    //  loads data
    useEffect(async () => {
        await getUsers()
    }, []);

    //  loads data
    useEffect(async () => {
        init()
    }, [currentPage, itemPerPage]);

    // function to reset the form
    const resetForm = () => {
        setFormData({
            id: "",
            name: "",
            apiKey: "",
            url: "",
            type: "",
            created_by: "",
            created_at: "",
            updated_at: "",
        });
        setModal({edit: false}, {add: false});

    };

    // function to close the form modal
    const onFormCancel = () => {
        setModal({
            edit: false,
            clock: false,
            add: false,
            ips: false,
            allrdns: false,
            users: false,
        });

        resetForm();
    };

    // submit function to add a new item
    const onFormSubmit = async (submitData) => {
        let submittedData = {
            id: data.length + 1,
            location: formData.location,
            ips: submitData.ips,
            team: submitData.team,
            type: 'azure',
            vmSize: formData.size,
            dns: formData.dns,
            domaine: formData.domaine,
        };

        const result = await store(submittedData)
        if (result.success) {
            await init()

        }
    };
    const onUpdateSubmit = async (submitData) => {
        let submittedData = {
            id: formData.id,
            location: formData.location,
            team: submitData.team,
            name: formData.name,
        };
        const result = await update(submittedData)
        if (result.success) {
            await init()
        }
        onFormCancel()
    };
    const handleedit = (data) => {
        setFormData({
            id: data.id,
            name: data.name,
            team: data.team,
            location: data.location,
            ips: data.ips,
            size: data.size
        })

        setModal({edit: true});

        console.log('handleedit', data);
    };

    // function delete item
    const onDeleteClick = async (id) => {
        const result = await remove(id)
        if (result.success) {
            await init()
        }
    };

    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const {errors, register, handleSubmit} = useForm();


    async function handleAction(action, data, option = 'normal') {

        data.map(async (item) => {
            await handlePostAction(action, {
                option: option,
                id: item.id,
                name: item.name,
                main: item?.main,
            })
        })
        await init()

    }

    async function handlePostActionwithData(action, data) {

        const result = await handlePostAction(action, data)
        if (result.success) {
            await init()
        }
    }

    async function getPublicIp(action, item, option = 'normal') {
        return await handlePostAction(action, {
            option: option,
            id: item.id,
            name: item.name,
        })

    }

    const [tooltipOpen, setOpen] = useState(false);
    const [tooltipOpenSpam, setOpenSpam] = useState(false);
    const togglebtn = () => {
        setOpen(!tooltipOpen)
    };

    const togglebtnSpam = () => {
        setOpenSpam(!tooltipOpenSpam)
    };

    const selectorCheck = (e) => {
        let newData;
        newData = currentItems.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setIcurrentItems([...newData]);
    };

    const onSelectChange = (e, id) => {
        console.log(id)
        let newData = currentItems;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setIcurrentItems([...newData]);
    };

    async function copyToClipboard(textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport, so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
        ;
    }

    const [isloadingModal, setIsloadingModal] = useState(true);
    const [ipsList, setipsList] = useState([]);
    const [ips, setIps] = useState('');

    const getAllips = async () => {
        setIps('')
        setipsList([])
        let ipscopy = ""
        setModal({ips: true})
        setIsloadingModal(true)

        let ipslist = []


        const serverChecked = data.filter((item) => item.checked === true)
        Promise.all(serverChecked.map(async (item) => {
            await getPublicIp('public-ips', item)
                .then((res) => {
                    res.data.ip.map((item) => {
                        ipscopy += item.ip + '\n';
                        ipslist.push({
                            main: res.data.id,
                            ip: item.ip,
                            name: item.name,
                            barracudaca: item.barracudaca,
                            dpamcop: item.dpamcop,
                            spamhaus: item.spamhaus,
                            dns: item.dns,
                            privateIp: item.privateIPAddress[0]
                        });
                    })
                })
                .catch(() => {
                    setIsloadingModal(false)
                });

        })).then((values) => {
            setIps(ipscopy)
            setipsList(ipslist)
        });


    }

    const randomRDNS = async () => {
        const serverChecked = data.filter((item) => item.checked === true)
        Promise.all(serverChecked.map(async (item) => {
            await getPublicIp('public-ips', item)
                .then((res) => {
                })
                .catch(() => {
                });

        })).then((values) => {

        });


    }


    const onMappingChange = (event) => {

        setFormData({...formData, domaine: event})


    }

    const handleChange = e => {
        const {value} = e.target;

        setFormData({...formData, dns: value})

    };

    const [startDate, setStartDate] = useState(new Date());
    const [stopDate, setStopDate] = useState(new Date());
    const [weekly, setWeekly] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });
    const [WeeklyStartStop, setWeeklyStartStop] = useState({
        start: false,
        stop: false,
    });

    function addZeroBefore(n) {
        return n;
    }

    const onShoulder = async () => {

        const trueDays = await Object.keys(weekly).filter((day) => weekly[day] !== false);
        const dataSelected = await data.filter((item) => item.checked === true)
        await dataSelected.map(async (item) => {
            await handlePostAction('scheduling',
                {
                    name: item.name,
                    hour: addZeroBefore(startDate.getHours()),
                    minute: addZeroBefore(startDate.getMinutes()),
                    hourStop: addZeroBefore(stopDate.getHours()),
                    minuteStop: addZeroBefore(stopDate.getMinutes()),
                    daysOfWeek: trueDays
                })
                .then(response => {

                })
                .catch((errors) => console.log(errors))
                .finally(() => {
                })
        })

    }

    const [actionText, setActionText] = useState("");

    const [tablesm, updateTableSm] = useState(false);

    const [rdnsForm, setRdnsForm] = useState({
        main: "",
        name: "",
        ip: "",
        dns: "",
    });

    const onRdnsAllIpsFormSubmit = async () => {
        console.log(rdnsForm)
        const result = await rdns(mapping, rdnsForm.main)
        if (result.success) {
            await init()
        }
    }

    async function handleGetIpsForRdns(data) {

        let ipslist = []
        let ipscopy = ""
        setipsList(ipslist)
        //  setIsloadingModal(true)
        setRdnsForm({main: data.name, name: data.name, ip: data.main_ip});

        setModal({allrdns: true});


        await getPublicIp('public-ips', data)
            .then((res) => {
                res.data.ip.map((item) => {
                    ipscopy += item.ip + '\n';
                    ipslist.push({
                        main: data.name,
                        ip: item.ip,
                        name: item.name,
                        barracudaca: item.barracudaca,
                        dpamcop: item.dpamcop,
                        spamhaus: item.spamhaus,
                        dns: item.dns
                    });
                })

                setipsList(ipslist)

            })
            .catch(() => {
                setIsloadingModal(false)
            });


    }

    const addMapping = () => {
        setMapping([...mapping, {
            accounts: null,
            ips: [],
            dns: ''
        }]);

    };


    async function copyMainIps() {
        let mainIps = '';

        // Assuming 'data' is an array of objects with a 'checked' property and a 'main_ip' property
        const dataSelected = await data.filter((item) => {
            if (item.checked === true) {
                mainIps += item.main_ip + '\n';
            }
        });

        // Reverse the array of selected main IP addresses
        const reversedMainIps = mainIps.trim().split('\n').reverse().join('\n');

        // Copy the reversed main IP addresses to the clipboard
        copyToClipboard(reversedMainIps);
    }

    const toggle = () => {
        setonSearch(!onSearch);
    };

    const onFilterChange = async (e) => {
        setSearchText(e.target.value);
    };

    const fillterhandel = async (e) => {
        await init()
    };


    return (
        <React.Fragment>
            <Head title="Servers "></Head>
            <Content>
                {loading ? <>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle tag="h3" page>
                                    Servers
                                </BlockTitle>
                                <BlockDes className="text-soft">
                                    <p>
                                        vous avez un total de {totalItems} Servers.
                                    </p>
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <Button
                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active " : ""}`}
                                        onClick={() => updateSm(!sm)}
                                    >
                                        <Icon name="menu-alt-r"></Icon>
                                    </Button>
                                    <div className="toggle-expand-content" style={{display: sm ? "block" : "none"}}>
                                        <ul className="nk-block-tools  g-1"
                                            style={{display: sm ? "flex" : "", flexDirection: sm ? "column" : ""}}>
                                            {(doesValueExist(auth?.user?.data, 'create') ?
                                                <li className="nk-block-tools-opt">
                                                    <Button color="primary" className="btn-icon btn-sm"
                                                            onClick={() => setModal({add: true})}>
                                                        <Icon name="plus"></Icon>
                                                    </Button>

                                                </li> : null)}
                                        </ul>
                                    </div>
                                </div>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>

                    <Block className='w-full'>
                        <DataTable className="card-stretch text-nowrap">
                            <div className="card-inner position-relative card-tools-toggle">
                                <div className="card-title-group">
                                    <div className="card-tools">
                                    </div>
                                    <div className="card-tools me-n1">
                                        <ul className="btn-toolbar gx-1">
                                            <li>
                                                <div className="toggle-wrap">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                                                        onClick={() => updateTableSm(true)}
                                                    >
                                                        <Icon name="menu-right"></Icon>
                                                    </Button>
                                                    <div
                                                        className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                                                        <ul className="btn-toolbar gx-1">

                                                            <li>
                                                                <button
                                                                    onClick={async () => {
                                                                        await getAllips()
                                                                    }}
                                                                    className="btn  btn-white btn-outline-light">

                                                                    <Icon name="copy-fill"/>
                                                                    <span>Get Public Ips </span>
                                                                </button>
                                                            </li>

                                                            <li className="btn-toolbar-sep"></li>
                                                            {(doesValueExist(auth?.user?.data, 'rotation') ? <li>
                                                                <a
                                                                    id='id'
                                                                    href="#rotation"
                                                                    onClick={() => {
                                                                        handleAction('rotation', data.filter((item) => item.checked === true));
                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >
                                                                    <Icon name="network"/>
                                                                    <span>Rotation</span>
                                                                </a>
                                                                <Tooltip placement='top' isOpen={tooltipOpen}
                                                                         target="id"
                                                                         toggle={togglebtn}>
                                                                    change all additional public ip address of server
                                                                    selected
                                                                </Tooltip>
                                                            </li> : null)}

                                                            {(doesValueExist(auth?.user?.data, 'rotation_spam') ? <li>
                                                                <a
                                                                    id='rotationSpam'
                                                                    href="#rotation"
                                                                    onClick={() => {
                                                                        handleAction('rotation', data.filter((item) => item.checked === true), 'spam');
                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >
                                                                    <Icon name="network"/>
                                                                    <span>Rotation Spam Filter</span>
                                                                </a>
                                                                <Tooltip placement='top' isOpen={tooltipOpenSpam}
                                                                         target="rotationSpam"
                                                                         toggle={togglebtnSpam}>
                                                                    change all additional public ip address of server
                                                                    selected <br/> (
                                                                    with spamhaus filter )
                                                                </Tooltip>
                                                            </li> : null)}


                                                            <li className="btn-toolbar-sep"></li>
                                                            <li>
                                                                <button
                                                                    onClick={() => {
                                                                        handleAction('RandomRDNS', data.filter((item) => item.checked === true));
                                                                    }}
                                                                    className="btn  btn-white btn-outline-light">

                                                                    <Icon name="copy-fill"/>
                                                                    <span> Random  RDNS ( Azure ) </span>
                                                                </button>
                                                            </li>
                                                            <li className="btn-toolbar-sep"></li>

                                                            <li>
                                                                <a
                                                                    href="#restart"
                                                                    onClick={() => {
                                                                        handleAction('restart', currentItems.filter((item) => item.checked === true));

                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >
                                                                    <Icon name="reload-alt"/>
                                                                    <span>Restart</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="#powerOn"
                                                                    onClick={() => {
                                                                        handleAction('powerOn', currentItems.filter((item) => item.checked === true));

                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >
                                                                    <Icon name="play"/>
                                                                    <span>Power On</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="#powerOn"
                                                                    onClick={() => {
                                                                        handleAction('powerOn', currentItems.filter((item) => item.checked === true), 'spam');

                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >
                                                                    <Icon name="play-circle"/>
                                                                    <span>Power On <small>(filter)</small></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="#powerOff"
                                                                    onClick={() => {
                                                                        handleAction('powerOff', currentItems.filter((item) => item.checked === true));

                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >
                                                                    <Icon name="power"/>
                                                                    <span>Power Off</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="#poweroffdeallocate"
                                                                    onClick={() => {
                                                                        handleAction('powerOff', currentItems.filter((item) => item.checked === true), 'deallocate');

                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >
                                                                    <Icon name="pause-circle-fill"/>
                                                                    <span>Power Off <small>(deallocate)</small></span>
                                                                </a>
                                                            </li>

                                                            {(doesValueExist(auth?.user?.data, 'users') ? <>
                                                                <li className="btn-toolbar-sep"></li>
                                                                <li>
                                                                    <button
                                                                        onClick={() => setModal({users: true})}
                                                                        className="btn  btn-white btn-outline-light"
                                                                    >
                                                                        <Icon name="users"/>
                                                                        <span>Users</span>
                                                                    </button>
                                                                </li>
                                                            </> : null)}

                                                            {(doesValueExist(auth?.user?.data, 'delete') ? <>
                                                                <li className="btn-toolbar-sep"></li>
                                                                <li>
                                                                    <a
                                                                        href="#delete"
                                                                        onClick={() => {
                                                                            handleAction('delete', currentItems.filter((item) => item.checked === true));

                                                                        }}
                                                                        className="btn  btn-white btn-outline-danger"
                                                                    >
                                                                        <Icon name="trash-alt"/>
                                                                        <span>Delete</span>
                                                                    </a>
                                                                </li>
                                                            </> : null)}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card-inner position-relative card-tools-toggle">
                                <div className="card-title-group">
                                    <div className="card-tools">
                                    </div>
                                    <div className="card-tools me-n1">
                                        <ul className="btn-toolbar gx-1">
                                            <li>
                                                <div className="toggle-wrap">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                                                        onClick={() => updateTableSm(true)}
                                                    >
                                                        <Icon name="menu-right"></Icon>
                                                    </Button>
                                                    <div
                                                        className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                                                        <ul className="btn-toolbar gx-1">
                                                            <li>
                                                                <button
                                                                    onClick={async () => {
                                                                        await init()
                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >

                                                                    <Icon name="reload"/>
                                                                    <span>Reload</span>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => {
                                                                        handleAction('resume', data.filter((item) => item.checked === true));
                                                                    }}
                                                                    className="btn  btn-white btn-outline-info"
                                                                >

                                                                    <Icon name="play"/>
                                                                    <span> Resume  PMTA</span>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => {
                                                                        handleAction('cache', data.filter((item) => item.checked === true));
                                                                    }}
                                                                    className="btn  btn-white btn-outline-warning"
                                                                >

                                                                    <Icon name="db-fill"/>
                                                                    <span>Clean cache</span>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button onClick={() => {
                                                                    handleAction('vmtas', data.filter((item) => item.checked === true));
                                                                }}

                                                                        className="btn  btn-white btn-outline-primary"
                                                                >

                                                                    <Icon name="trash-empty-fill"/>
                                                                    <span>Delete VMTAs</span>
                                                                </button>
                                                            </li>

                                                            <li className="toggle-close">
                                                                <Button className="btn-icon btn-trigger toggle"
                                                                        onClick={() => updateTableSm(false)}>
                                                                    <Icon name="arrow-left"></Icon>
                                                                </Button>
                                                            </li>
                                                            <li>
                                                                <Button
                                                                    onClick={(e) => copyMainIps()}

                                                                    className=" btn-white btn-outline-light">
                                                                    <Icon name="copy-fill "/>
                                                                    <span>Get Main Ip </span>
                                                                </Button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => {
                                                                        handleAction('vm-status', data.filter((item) => item.checked === true));
                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >
                                                                    <Icon name="linux-server"/>
                                                                    <span>Get Status VM</span>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={async () => {
                                                                        setModal({clock: true})
                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >

                                                                    <Icon name="clock"/>
                                                                    <span>Scheduling</span>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => {
                                                                        handleAction('setup', data.filter((item) => item.checked === true));
                                                                    }}
                                                                    className="btn  btn-white btn-outline-light"
                                                                >

                                                                    <Icon name="hot-fill"/>
                                                                    <span>Firewall</span>
                                                                </button>
                                                            </li>

                                                            {(doesValueExist(auth?.user?.data, 'detach') ? <>
                                                                <li>
                                                                    <a
                                                                        id='Detach'
                                                                        href="#detach"
                                                                        onClick={() => {
                                                                            handleAction('detach', data.filter((item) => item.checked === true));
                                                                        }}
                                                                        className="btn  btn-white btn-outline-light"
                                                                    >
                                                                        <Icon name="unfold-more"/>
                                                                        <span>Detach</span>
                                                                    </a>
                                                                </li>
                                                            </> : null)}
                                                            {(doesValueExist(auth?.user?.data, 'attach') ? <>
                                                                <li>
                                                                    <a
                                                                        id='Attach'
                                                                        href="#attach"
                                                                        onClick={() => {
                                                                            handleAction('attach', data.filter((item) => item.checked === true));
                                                                        }}
                                                                        className="btn  btn-white btn-outline-light"
                                                                    >
                                                                        <Icon name="unfold-less"/>
                                                                        <span>Attach</span>
                                                                    </a>

                                                                </li>

                                                            </> : null)}

                                                            {(doesValueExist(auth?.user?.data, 'rotation_auto') ? <>
                                                                <li className="btn-toolbar-sep"></li>
                                                                <li>
                                                                    <a

                                                                        href="#rotationAuto"
                                                                        onClick={async () => {
                                                                            await handleAction('rotation', data.filter((item) => item.checked === true), 'auto_start');
                                                                        }}
                                                                        className="btn  btn-white btn-outline-info"
                                                                    >
                                                                        <Icon name="network"/>
                                                                        <span>Start Rotation Auto</span>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a

                                                                        href="#rotationAuto"
                                                                        onClick={async () => {
                                                                            await handleAction('delete-rotation', data.filter((item) => item.checked === true));
                                                                        }}
                                                                        className="btn btn-white  btn-outline-danger"
                                                                    >
                                                                        <Icon name="network"/>
                                                                        <span>Stop Rotation Auto</span>
                                                                    </a>
                                                                </li>

                                                            </> : null)}

                                                            <li className="btn-toolbar-sep"></li>
                                                            <li>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="a"
                                                                                    className="btn btn-trigger btn-icon dropdown-toggle">
                                                                        <Icon name="setting"></Icon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu end className="dropdown-menu-xs">
                                                                        <ul className="link-check">
                                                                            <li>
                                                                                <span>Show</span>
                                                                            </li>
                                                                            <li className={itemPerPage === 10 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setCurrentPage(1)
                                                                                        setItemPerPage(10);
                                                                                    }}
                                                                                >
                                                                                    10
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={itemPerPage === 20 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setCurrentPage(1)
                                                                                        setItemPerPage(20);
                                                                                    }}
                                                                                >
                                                                                    20
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={itemPerPage === 30 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setCurrentPage(1)
                                                                                        setItemPerPage(30);
                                                                                    }}
                                                                                >
                                                                                    30
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={itemPerPage === 20 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setCurrentPage(1)

                                                                                        setItemPerPage(50);
                                                                                    }}
                                                                                >
                                                                                    50
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={itemPerPage === 100 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setCurrentPage(1)

                                                                                        setItemPerPage(100);
                                                                                    }}
                                                                                >
                                                                                    100
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={itemPerPage === 150 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setCurrentPage(1)

                                                                                        setItemPerPage(150);
                                                                                    }}
                                                                                >
                                                                                    150
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={itemPerPage === 150 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setCurrentPage(1)

                                                                                        setItemPerPage(500);
                                                                                    }}
                                                                                >
                                                                                    500
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={itemPerPage === 1000 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setCurrentPage(1)

                                                                                        setItemPerPage(1000);
                                                                                    }}
                                                                                >
                                                                                    1000
                                                                                </DropdownItem>
                                                                            </li>
                                                                        </ul>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </li>
                                                            <li>
                                                                <Button
                                                                    href="#search"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        toggle();
                                                                    }}
                                                                    className="btn-icon search-toggle toggle-search"
                                                                >
                                                                    <Icon name="search"></Icon>
                                                                </Button>
                                                            </li>


                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                    <div className="search-content">
                                        <Button
                                            onClick={() => {
                                                setSearchText("");
                                                toggle();
                                            }}
                                            className="search-back btn-icon toggle-search p-2"
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Recherche par Nom ou Description"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon text-primary p-4 " outline="true"
                                                onClick={async () => {
                                                    await fillterhandel()
                                                }}>
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>

                            </div>
                            <DataTableBody>
                                <DataTableHead>
                                    <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={(e) => selectorCheck(e)}
                                                id="uid"
                                            />
                                            <label className="custom-control-label" htmlFor="uid"></label>
                                        </div>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Name</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Name (SendApp)</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Status</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Status Vm</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Last Action </span>
                                    </DataTableRow>
                                    <DataTableRow size="lg">
                                        <span className="sub-text">Next Start</span>
                                    </DataTableRow>
                                    <DataTableRow size="lg">
                                        <span className="sub-text">Next Stop</span>
                                    </DataTableRow>
                                    <DataTableRow size="lg">
                                        <span className="sub-text" size="sm">User</span>
                                    </DataTableRow>
                                    <DataTableRow size="lg">
                                        <span className="sub-text">Location</span>
                                    </DataTableRow>
                                    <DataTableRow size="lg">
                                        <span className="sub-text">Main Ip</span>
                                    </DataTableRow>
                                    <DataTableRow size="lg">
                                        <span className="sub-text">IPs </span>
                                    </DataTableRow>
                                    <DataTableRow size="lg">
                                        <span className="sub-text">Size Vm </span>
                                    </DataTableRow>
                                    <DataTableRow size="lg">
                                        <span className="sub-text">Created At </span>
                                    </DataTableRow>


                                    <DataTableRow size="lg" className="nk-tb-col-tools text-end">

                                    </DataTableRow>
                                </DataTableHead>
                                {/*Head*/}
                                {currentItems.length > 0
                                    ? currentItems.map((item) => {
                                        return (
                                            <DataTableItem key={item.id}>
                                                <DataTableRow className="nk-tb-col-check">
                                                    <div
                                                        className="custom-control custom-control-sm custom-checkbox notext">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            defaultChecked={item.checked}
                                                            id={item.id + "uid1"}
                                                            key={Math.random()}
                                                            onChange={(e) => onSelectChange(e, item.id)}
                                                        />
                                                        <label className="custom-control-label"
                                                               htmlFor={item.id + "uid1"}></label>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                  <span className="tb-amount">
                                                    {item?.name}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                  <span className="tb-amount">
                                                    {item?.team}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                  <span className={
                                                      item.status === "Power Off Deallocate" || item.status === 'VM deallocated' ? 'tb-amount  text-danger text-uppercase' :
                                                          (item.status === "On power Off " || item.status === "Power Off ") ? 'tb-amount  text-danger text-uppercase' :
                                                              item.status === "In progress changing IPS" ? 'tb-amount  text-warning text-uppercase' :
                                                                  item.status === "Succeeded" ? 'tb-amount  text-success text-uppercase' :
                                                                      item?.status === 'Deleted' ? 'tb-amount  text-danger text-uppercase' :
                                                                          item?.status === 'being created' ? 'tb-amount  text-info text-uppercase' :
                                                                              'tb-amount  text-primary text-uppercase'}>
                                                    {item?.status}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                  <span className={
                                                      item.status_Vm === "Stopped Deallocate" || item.status_Vm === 'VM deallocated' ? 'tb-amount  text-danger text-uppercase' :
                                                          (item.status_Vm === "On power Off " || item.status_Vm === "Power Off ") ? 'tb-amount  text-danger text-uppercase' :
                                                              item.status_Vm === "In progress changing IPS" ? 'tb-amount  text-warning text-uppercase' :
                                                                  item.status_Vm === "Succeeded" || item.status_Vm === "VM running" || item.status_Vm === "Running" ? 'tb-amount  text-success text-uppercase' :
                                                                      item?.status_Vm === 'On delete' ? 'tb-amount  text-danger text-uppercase' :
                                                                          item?.status_Vm === 'being created' ? 'tb-amount  text-info text-uppercase' :
                                                                              'tb-amount  text-primary text-uppercase'}>
                                                    {item?.status_Vm}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                  <span className="tb-amount  text-primary text-uppercase">
                                                    {item?.action}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow size="lg">
                                                    <ul className="list-status">
                                                        <li>
                                                            <Icon
                                                                className={`text-success`}
                                                                name="alarm-alt"
                                                            ></Icon>{" "}
                                                            <span> {item?.start_at ?? ' -- -- -- --:--'}</span>
                                                        </li>

                                                    </ul>
                                                </DataTableRow>
                                                <DataTableRow size="lg">
                                                    <ul className="list-status">
                                                        <li>
                                                            <Icon
                                                                className={`text-warning`}
                                                                name="alarm-alt"
                                                            ></Icon>{" "}
                                                            <span> {item?.stop_at ?? ' -- -- -- --:--'}</span>
                                                        </li>

                                                    </ul>
                                                </DataTableRow>
                                                <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.responsible?.name}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.location}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.main_ip}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                      {item?.ips}
                                                  </span>
                                                </DataTableRow>

                                                <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.vmSize}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow size="lg">
                                                    <ul className="list-status">
                                                        <li>
                                                            <Icon
                                                                className={`text-success`}
                                                                name="alarm-alt"
                                                            ></Icon>{" "}
                                                            <span> {item?.created_at}</span>
                                                        </li>

                                                    </ul>
                                                </DataTableRow>
                                                <DataTableRow size="lg" className="nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        <li className="nk-tb-action-hidden"
                                                            onClick={() => handleedit(item)}>
                                                            <TooltipComponent
                                                                tag="a"
                                                                containerClassName="btn btn-trigger btn-icon"
                                                                id={"edit" + item.id}
                                                                icon="edit"
                                                                direction="top"
                                                                text="Rename"
                                                            />
                                                        </li>
                                                        <li className="nk-tb-action-hidden"
                                                            onClick={() => handleGetIpsForRdns(item)}>
                                                            <TooltipComponent
                                                                tag="a"
                                                                containerClassName="btn btn-trigger btn-icon"
                                                                id={"exchange" + item.id}
                                                                icon="exchange"
                                                                direction="top"
                                                                text="Rdns"
                                                            />
                                                        </li>

                                                    </ul>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })
                                    : null}
                            </DataTableBody>
                            <div className="card-inner">
                                {currentItems.length > 0 ? (
                                    <PaginationComponent
                                        itemPerPage={itemPerPage}
                                        totalItems={totalItems}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <span className="text-silent">No data found</span>
                                    </div>
                                )}
                            </div>
                        </DataTable>
                    </Block>


                    <Modal isOpen={modal.add} toggle={() => setModal({add: false})} className="modal-dialog-centered"
                           size="lg">
                        <ModalBody>
                            <a
                                href="#close"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    onFormCancel();
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">Add New Server Azure</h5>
                                <div className="mt-4">
                                    <Form className="mt-4" onSubmit={handleSubmit(onFormSubmit)} noValidate>
                                        <Row className="g-gs mt-2">
                                            <Col md="12">
                                                <div className="form-group">
                                                    <label className="form-label">Name Team</label>
                                                    <input
                                                        type="text"
                                                        name="team"
                                                        defaultValue={formData.team}
                                                        className="form-control"
                                                        ref={register({required: "This field is required"})}
                                                    />
                                                    {errors.team &&
                                                        <span className="invalid">{errors.team.message}</span>}
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <label className="form-label">Count of IP Addresses</label>
                                                    <input
                                                        type="text"
                                                        name="ips"
                                                        defaultValue={formData.ips}
                                                        className="form-control"
                                                        ref={register({required: "This field is required"})}
                                                    />
                                                    {errors.ips &&
                                                        <span className="invalid">{errors.ips.message}</span>}
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <label className="form-label">Location</label>
                                                    <div className="form-control-wrap">
                                                        <RSelect
                                                            name='location'
                                                            options={location}
                                                            defaultValue={{
                                                                value: location.value,
                                                                label: location.label
                                                            }}
                                                            ref={register({required: "This field is required"})}

                                                            onChange={(e) => setFormData({
                                                                ...formData,
                                                                location: e.value
                                                            })}
                                                        />
                                                    </div>
                                                    {errors.location &&
                                                        <span className="invalid">{errors.location.message}</span>}

                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <label className="form-label">Ram Size</label>
                                                    <div className="form-control-wrap">
                                                        <RSelect
                                                            name='size'
                                                            ref={register({required: "This field is required"})}

                                                            defaultValue={[]}
                                                            options={[
                                                                {
                                                                    value: 'Standard_B1ls',
                                                                    label: 'Standard_B1ls (RAM 0.5 Go)'
                                                                },
                                                                {
                                                                    value: 'Standard_B1s',
                                                                    label: 'Standard_B1s (RAM 1Go)'
                                                                },
                                                                {
                                                                    value: 'Standard_B2s',
                                                                    label: 'Standard_B2s (RAM 4Go)'
                                                                },
                                                                {
                                                                    value: 'Standard_B1ms',
                                                                    label: 'Standard_B1ms  (RAM 2Go) '
                                                                },
                                                                {
                                                                    value: 'Standard_DS1_v2',
                                                                    label: 'Standard_DS1_v2  (RAM 3,5Go) '
                                                                },
                                                                {
                                                                    value: 'Standard_F4s_v2',
                                                                    label: 'Standard_F4s_v2  (RAM 8Go) '
                                                                },
                                                                {
                                                                    value: 'Standard_B4ms',
                                                                    label: 'Standard_B4ms  (RAM 8Go) '
                                                                },
                                                            ]}
                                                            onChange={(e) => setFormData({...formData, size: e.value})}
                                                        />

                                                        {errors.size &&
                                                            <span className="invalid">{errors.size.message}</span>}

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <label className="form-label">Choosing domain name</label>

                                                    <div
                                                        className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked w-100"
                                                        onChange={() => {
                                                        }}>
                                                        <input
                                                            type="radio"
                                                            className="custom-control-input w-100"
                                                            id="btnOnlyIconCheck1"
                                                            value="custom"
                                                            name="dns"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                setChoosinDns(true)
                                                            }}
                                                        />
                                                        <label
                                                            className="custom-control-label w-100 text-center justify-content-center"
                                                            htmlFor="btnOnlyIconCheck1"
                                                        >
                                                            <Icon name="exchange"></Icon>
                                                        </label>
                                                    </div>
                                                </div>
                                            </Col>

                                            {(choosinDns) ? <RDnsSelect key={1} item={[]} setMapping={setMapping}
                                                                        onMappingChange={onMappingChange}
                                                                        accounts={accounts} options={options}
                                                                        ipsList={[]}/> : null}
                                            <Col md="12">
                                                <div
                                                    className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked w-100"

                                                >
                                                    <input type="radio" className="custom-control-input w-100"
                                                           id="btnOnlyIconCheck2"
                                                           value="azure"
                                                           name="dns"
                                                           onChange={(e) => {
                                                               handleChange(e)
                                                               setChoosinDns(false)
                                                           }}
                                                    />
                                                    <label
                                                        className="custom-control-label w-100 text-center justify-content-center"
                                                        htmlFor="btnOnlyIconCheck2">
                                                        Random.{formData.location}.cloudapp.azure.com
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                        <Row className="gy-4 mt-2">

                                            <Col size="12">
                                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                    <li>
                                                        <Button color="primary" size="md" type="submit">
                                                            Add server
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#cancel"
                                                            onClick={(ev) => {
                                                                ev.preventDefault();
                                                                onFormCancel();
                                                            }}
                                                            className="link link-light"
                                                        >
                                                            Annuler
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Form>

                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={modal.edit} toggle={() => setModal({edit: false})}
                           className="modal-dialog-centered" size="xl" style={{maxWidth: '55em'}}>
                        <ModalBody>
                            <a
                                href="#close"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    onFormCancel();
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>

                            <div className="p-2">
                                <h5 className="title">Edit Server Azure</h5>
                                <div className="mt-4">
                                    <Form className="mt-4" onSubmit={handleSubmit(onUpdateSubmit)} noValidate>
                                        <Row className="g-gs mt-2">
                                            <Col md="12">
                                                <div className="form-group">
                                                    <label className="form-label">Name SandApp</label>
                                                    <input
                                                        type="text"
                                                        name="team"
                                                        defaultValue={formData.team}
                                                        className="form-control"
                                                        ref={register({required: "This field is required"})}
                                                    />
                                                    {errors.team &&
                                                        <span className="invalid">{errors.team.message}</span>}
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className="gy-4 mt-2">

                                            <Col size="12">
                                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                    <li>
                                                        <Button color="primary" size="md" type="submit">
                                                            Save
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#cancel"
                                                            onClick={(ev) => {
                                                                ev.preventDefault();
                                                                onFormCancel();
                                                            }}
                                                            className="link link-light"
                                                        >
                                                            Annuler
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Form>

                                </div>
                            </div>
                        </ModalBody>

                    </Modal>

                    <Modal isOpen={modal.ips} toggle={() => setModal({ips: false})} className="modal-dialog-centered"
                           style={{maxWidth: '110em'}}>
                        <ModalBody>
                            <a
                                href="#cancel"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    onFormCancel();
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title"> Additional Public Ip Address</h5>

                                <div className="mt-4">
                                    <div className="row gy-4">
                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>

                                                    <Button color="primary" size="md" className='w-100'
                                                            onClick={async (ev) => {
                                                                ev.preventDefault();
                                                                try {
                                                                    await copyToClipboard(ips);
                                                                } catch (error) {
                                                                }
                                                            }}>
                                                        <Icon name="copy-fill"/>
                                                        <span>Copy Public Ips </span>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col className="col-12">
                                            <table className="table ">
                                                <thead className="">
                                                <tr>
                                                    <th className="tb-col-os">
                                                      <span className="overline-title">
                                                       #
                                                      </span>
                                                    </th>
                                                    <th className="tb-col-ip">
                                                        <span className="sub-text">IP Public</span>
                                                    </th>
                                                    <th className="tb-col-ip">
                                                        <span className="sub-text">IP Private </span>
                                                    </th>
                                                    <th className="tb-col-name">
                                                        <span className="sub-text">Name</span>
                                                    </th>
                                                    <th className="tb-col-dns">
                                                        <span className="sub-text">RDns</span>
                                                    </th>
                                                    <th className="tb-col-spamCop">
                                                        <span className="sub-text">SpamCop</span>
                                                    </th>
                                                    <th className="tb-col-spamhaus">
                                                        <span className="sub-text">Spamhaus</span>
                                                    </th>
                                                    <th className="tb-col-baracoda">
                                                        <span className="sub-text">Barracuda</span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="sub-text"> </span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="sub-text"> </span>
                                                    </th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(ipsList?.length > 0) ? ipsList?.map((item, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td className="tb-col-xs">{idx + 1}</td>
                                                            <td className="tb-col-xs">{item.ip}</td>
                                                            <td className="tb-col-xs">{item.privateIp}</td>
                                                            <td className="tb-col-xs">{item.name}</td>
                                                            <td className="tb-col-xs">{item?.dns?.reverseFqdn}</td>
                                                            <td className="tb-col-xs">
                                                                {!item.dpamcop ?
                                                                    <Badge color="success">Not listed</Badge> :
                                                                    <Badge color="danger">Listed</Badge>}
                                                            </td>
                                                            <td className="tb-col-os">
                                                                {!item.spamhaus ?
                                                                    <Badge color="success">Not listed</Badge> :
                                                                    <Badge color="danger">Listed</Badge>}
                                                            </td>
                                                            <td className="tb-col-os">
                                                                {!item.barracudaca ?
                                                                    <Badge color="success">Not listed</Badge> :
                                                                    <Badge color="danger">Listed</Badge>}

                                                            </td>
                                                            <td className="tb-col-action ">
                                                                <a
                                                                    href="#rotation"
                                                                    onClick={async () => {
                                                                        await handleAction('rotation', [item]);
                                                                    }}
                                                                    className="btn btn-white btn-sm btn-outline-light"
                                                                >
                                                                    <Icon name="network"/>
                                                                    <span>Rotation</span>
                                                                </a>

                                                            </td>
                                                            <td className="tb-col-action ">
                                                                <a
                                                                    href="#rotation"
                                                                    className="btn btn-white btn-sm btn-outline-light"
                                                                    onClick={async () => {
                                                                        await handleAction('rotation', [item], 'spam');
                                                                    }}
                                                                >
                                                                    <Icon name="network"/>
                                                                    <span>Rotation Spam Filter</span>
                                                                </a>

                                                            </td>
                                                        </tr>
                                                    );
                                                }) : isloadingModal ? (
                                                    <tr>
                                                        <td className="tb-col-os " colSpan={7}>
                                                            <LoaderTable/>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td className="tb-col-os text-center" colSpan={7}>
                                                            No data found
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>

                                        </Col>

                                    </div>


                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modal.clock} toggle={() => setModal({clock: false})}
                           className="modal-dialog-centered" size="xl" style={{maxWidth: '55em'}}>
                        <ModalBody>
                            <a
                                href="#cancel"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    onFormCancel();
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title"> Scheduling Azure Server</h5>
                                <div className="mt-4">
                                    <Form className="row gy-4" onSubmit={handleSubmit(onShoulder)}>
                                        <Col md="6">
                                            <div className="form-group">
                                                <div className="custom-control custom-radio my-3 checked ">
                                                    <input type="checkbox" onChange={(e) => {
                                                        setWeeklyStartStop((prevWeeklyStartStop) => ({
                                                            ...prevWeeklyStartStop,
                                                            start: !WeeklyStartStop.start
                                                        }))
                                                    }} checked={WeeklyStartStop.start}
                                                           className="custom-control-input checked" id="btnStart"/>
                                                    <label className="custom-control-label form-label"
                                                           htmlFor="btnStart">
                                                        Start Date
                                                    </label>
                                                </div>

                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={30}
                                                    timeCaption="Time"
                                                    dateFormat="hh:mm aa"


                                                    className="form-control date-picker"
                                                />

                                                <input type="checkbox"
                                                       className="custom-control-input custom-control-lg"/>

                                                {errors.startDate &&
                                                    <span className="invalid">{errors.startDate.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="6">

                                            <div className="form-group">
                                                <div className="custom-control custom-radio my-3 ">
                                                    <input type="checkbox" onChange={(e) => {
                                                        setWeeklyStartStop((prevWeeklyStartStop) => ({
                                                            ...prevWeeklyStartStop,
                                                            stop: !WeeklyStartStop.stop
                                                        }))
                                                    }} checked={WeeklyStartStop.stop} className="custom-control-input"
                                                           id="btnStop"/>
                                                    <label className="custom-control-label form-label"
                                                           htmlFor="btnStop">
                                                        Stop Date
                                                    </label>
                                                </div>

                                                <DatePicker
                                                    selected={stopDate}
                                                    onChange={(date) => setStopDate(date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={30}
                                                    timeCaption="Time"
                                                    dateFormat="hh:mm aa"
                                                    className="form-control date-picker z-999999"
                                                />


                                                {errors.stopDate &&
                                                    <span className="invalid">{errors.stopDate.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className=" align-items-center">
                                                <ul className="custom-control-group align-items-center">


                                                    <li>
                                                        <div
                                                            className="custom-control custom-radio custom-control-pro  no-control checked">
                                                            <input type="checkbox" onChange={(e) => {
                                                                setWeekly((prevWeekly) => ({
                                                                    ...prevWeekly,
                                                                    monday: !weekly.monday
                                                                }))
                                                            }} checked={weekly.monday} className="custom-control-input"
                                                                   id="btnMonday"/>
                                                            <label className="custom-control-label" htmlFor="btnMonday">
                                                                <span>Monday</span>
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div
                                                            className="custom-control custom-radio custom-control-pro  no-control checked">
                                                            <input type="checkbox" onChange={(e) => {
                                                                setWeekly((prevWeekly) => ({
                                                                    ...prevWeekly,
                                                                    tuesday: !weekly.tuesday
                                                                }))
                                                            }} checked={weekly.tuesday} className="custom-control-input"
                                                                   id="btnTuesday"/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="btnTuesday">
                                                                <span>Tuesday</span>

                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div
                                                            className="custom-control custom-radio custom-control-pro  no-control checked">
                                                            <input type="checkbox" onChange={(e) => {
                                                                setWeekly((prevWeekly) => ({
                                                                    ...prevWeekly,
                                                                    wednesday: !weekly.wednesday
                                                                }))
                                                            }} checked={weekly.wednesday}
                                                                   className="custom-control-input" id="btnWednesday"/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="btnWednesday">
                                                                <span>Wednesday</span>

                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div
                                                            className="custom-control custom-radio custom-control-pro  no-control checked">
                                                            <input type="checkbox" onChange={(e) => {
                                                                setWeekly((prevWeekly) => ({
                                                                    ...prevWeekly,
                                                                    thursday: !weekly.thursday
                                                                }))
                                                            }} checked={weekly.thursday}
                                                                   className="custom-control-input" id="btnThursday"/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="btnThursday">
                                                                <span>Thursday</span>

                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div
                                                            className="custom-control custom-radio custom-control-pro  no-control checked">
                                                            <input type="checkbox" onChange={(e) => {
                                                                setWeekly((prevWeekly) => ({
                                                                    ...prevWeekly,
                                                                    friday: !weekly.friday
                                                                }))
                                                            }} checked={weekly.friday} className="custom-control-input"
                                                                   id="btnFriday"/>
                                                            <label className="custom-control-label" htmlFor="btnFriday">
                                                                <span>Friday</span>

                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div
                                                            className="custom-control custom-radio custom-control-pro  no-control checked">
                                                            <input type="checkbox" onChange={(e) => {
                                                                setWeekly((prevWeekly) => ({
                                                                    ...prevWeekly,
                                                                    saturday: !weekly.saturday
                                                                }))
                                                            }} checked={weekly.saturday}
                                                                   className="custom-control-input" id="btnSaturday"/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="btnSaturday">
                                                                <span>Saturday</span>

                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div
                                                            className="custom-control custom-radio custom-control-pro  no-control checked">
                                                            <input type="checkbox" onChange={(e) => {
                                                                setWeekly((prevWeekly) => ({
                                                                    ...prevWeekly,
                                                                    sunday: !weekly.sunday
                                                                }))
                                                            }} checked={weekly.Sunday} className="custom-control-input"
                                                                   id="btnSameday"/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="btnSameday">
                                                                <span>Sunday</span>

                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>

                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button color="primary" size="md" type="submit">
                                                        Add Scheduling
                                                    </Button>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#cancel"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            onFormCancel();
                                                        }}
                                                        className="link link-light"
                                                    >
                                                        Cancel
                                                    </a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={modal.users} toggle={() => setModal({users: false})}
                           className="modal-dialog-centered" size="xl" style={{maxWidth: '55em'}}>
                        <ModalBody>
                            <a
                                href="#cancel"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    onFormCancel();
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title"> Set Azure Server To user</h5>
                                <div className="mt-4">
                                    <Form className="row gy-4" onSubmit={handleSubmit(onShoulder)}>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Users</label>
                                                <RSelect
                                                    name='accounts'
                                                    options={users?.map((item) => {
                                                        return {label: item.name, value: item.id}
                                                    })}
                                                    defaultValue={formData?.users?.map((item) => {
                                                        return {label: item.label, value: item.value}
                                                    })}
                                                    onChange={(e) => setServerTransfer({
                                                        user: e.value,
                                                        server: data.filter((item) => item.checked === true)
                                                    })}

                                                />
                                            </div>
                                        </Col>
                                        <Col size="12">
                                            <Button outline onClick={async (e) => {
                                                e.preventDefault();
                                                await handlePostActionwithData('transfer', serverTransfer)

                                            }}
                                                    className="w-100 text-center btn-dim justify-center" color="primary"
                                                    size="md" type="button">
                                                Save
                                            </Button>
                                        </Col>

                                    </Form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={modal.allrdns} toggle={() => {
                        setModal({allrdns: false})
                    }} className="modal-dialog-centered" size="xl">
                        <ModalBody>
                            <a
                                href="#cancel"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal({edit: false, add: false, rdns: false});

                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">Add Reverse DNS </h5>
                                <div className="mt-4">
                                    <Form className="row gy-4" onSubmit={handleSubmit(onRdnsAllIpsFormSubmit)}>
                                        <Col md="8">
                                            <div className="form-group">
                                                <label className="form-label">IP Address Name </label>
                                                <input
                                                    readOnly={true}
                                                    className="form-control"
                                                    type="text"
                                                    name="name"
                                                    defaultValue={rdnsForm.name}
                                                    onChange={(e) => setRdnsForm({...rdnsForm, name: e.value})}

                                                    placeholder="Enter interface name "
                                                    ref={register({required: "This field is required"})}
                                                />
                                                {errors.name && <span className="invalid">{errors.name.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="form-group">
                                                <label className="form-label">IP Address </label>
                                                <input
                                                    className="form-control"
                                                    readOnly={true}
                                                    type="text"
                                                    name="ip"
                                                    defaultValue={rdnsForm.ip}
                                                    onChange={(e) => setRdnsForm({...rdnsForm, ip: e.value})}

                                                    placeholder="Enter interface name "
                                                    ref={register({required: "This field is required"})}
                                                />

                                                {errors.name && <span className="invalid">{errors.name.message}</span>}
                                            </div>
                                        </Col>


                                        {mapping.length ? mapping.map((item, index) => {
                                            return (<RDnsSelect key={index} item={item} setMapping={setMapping}
                                                                onMappingChange={onMappingChange}
                                                                accounts={accounts}
                                                                options={options}
                                                                ipsList={ipsList}/>)
                                        }) : null}

                                        <Col size="12">
                                            <Button outline onClick={addMapping}
                                                    className="w-100 text-center btn-dim justify-center" color="primary"
                                                    size="md" type="button">
                                                Add Mapping
                                            </Button>
                                        </Col>

                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button color="primary" size="md" type="submit">
                                                        Add Dns
                                                    </Button>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#cancel"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            setModal({edit: false, add: false, rdns: false});
                                                            setMapping([]);
                                                        }}
                                                        className="link link-light"
                                                    >
                                                        Cancel
                                                    </a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>


                    <Server isOpen={true} users={users}/>

                </> : <Loader/>}

            </Content>
        </React.Fragment>
    );
};

export default Azure;
