
import useAxiosPrivate from './../axiosClient';
import {notification, urlBackend, ValidationMessageError} from "../../utils/Utils";

const uri =`${urlBackend}/api/user`

const MESSAG_RESPONSE ={
  loading: 'Chargement...',
  create: 'Utilisateur créé avec succès.',
  update: 'Utilisateur mis à jour avec succès.',
  delete: 'Utilisateur supprimé avec succès.',
  aws: 'Aws mis à jour avec succès',
  error: 'Une erreur est survenue. Veuillez réessayer.'
}



const useUserApi = () => {
  const axiosPrivate = useAxiosPrivate();

  const list = async (itemPerPage,current_page) => {
    return await axiosPrivate.get(`${uri}?page=${current_page}&per_page=${itemPerPage}`).then((require) => {
      return require.data;

    }).catch((error) => {
      return  error.response

    })
  };

  const create = async () => {
    try {
      const response = await axiosPrivate.get(`${uri}/create`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };

  const store = async (data) => {
      return await axiosPrivate.post(`${uri}/`,data).then((response) => {
          if (response.data.success)
              notification('success', MESSAG_RESPONSE.create)
          else
              ValidationMessageError(response.data)
          return response.data

      }).catch((error) => {
          ValidationMessageError(error.data)
          return  error.response
      })
  };

  const edit = async (id) => {
      return await axiosPrivate.get(`${uri}/${id}`).then((response) => {
          if (response.data.success)
              return response.data
          else
              notification('error', MESSAG_RESPONSE.error)
          return response.data

      }).catch((error) => {
          ValidationMessageError(error.data)
          return  error.response
      })

  };
  const editAws = async (id) => {
      return await axiosPrivate.get(`${uri}/edit/aws`).then((response) => {
          if (response.data.success)
              return response.data
          else
              notification('error', MESSAG_RESPONSE.error)
          return response.data

      }).catch((error) => {
          ValidationMessageError(error.data)
          return  error.response
      })

  };

  const update = async (data, id) => {
    const toast_id=notification('info',MESSAG_RESPONSE.loading)
    return await axiosPrivate.patch(`${uri}/${id}`,data).then((response) => {
        if (response.data.success)
            notification('success', MESSAG_RESPONSE.update)
        else
            ValidationMessageError(response.data)
        return response.data
    }).catch((error) => {
      notification('error', MESSAG_RESPONSE.error)
      return  error.response
    })
  };
  const updateAws = async (data, id) => {
    const toast_id=notification('info',MESSAG_RESPONSE.loading)
    return await axiosPrivate.put(`${uri}/update/aws`,data).then((response) => {
        if (response.data.success)
            notification('success', MESSAG_RESPONSE.aws,toast_id)
        else
            ValidationMessageError(response.data)
        return response.data
    }).catch((error) => {
      notification('error', MESSAG_RESPONSE.error)
      return  error.response
    })
  };

    const updateAzure = async (data, id) => {
        const toast_id=notification('info',MESSAG_RESPONSE.loading)
        return await axiosPrivate.put(`${uri}/update/azure`,data).then((response) => {
            if (response.data.success)
                notification('success', MESSAG_RESPONSE.aws,toast_id)
            else
                ValidationMessageError(response.data)
            return response.data
        }).catch((error) => {
            notification('error', MESSAG_RESPONSE.error)
            return  error.response
        })
    };

    const remove = async (id) => {
      return await axiosPrivate.delete(`${uri}/${id}`).then((response) => {
          if (response.data.success)
              notification('success', MESSAG_RESPONSE.delete)
          else
              notification('error', MESSAG_RESPONSE.error)
          return response.data

      }).catch((error) => {
          ValidationMessageError(error.data)
          return  error.response
      })

  };

  return {
    list,
    create,
    store,
    edit,
      editAws,
    update,
      updateAws,
      updateAzure,
    remove,
  };
};

export default useUserApi;
