import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import axios from "axios";

const SelectAsyncPaginate = (props) => {


    const uri = 'http://65.109.37.27:3333'
    const ip = '65.109.37.27'
    const endpoint = `${uri}/Namecheap/namecheap`;




    const [regionName, setRegionName] = useState(null);
    useEffect(() => {
        setRegionName(props.regionName);
    }, [props.regionName]);



    const list = async (ApiUser, ApiKey, UserName, Command, ClientIp, SearchTerm, page = 1, pageSize = 10) => {
      // Make a request for a user with a given ID
      return await axios.get(`${endpoint}/getDomainList/${ApiUser}/${ApiKey}/${UserName}/${Command}:/${ip}/${SearchTerm}/${page}/${pageSize}`)
          .then(function (response) {
              // handle success
              console.log(response.data)
              return response.data;
          }).catch(function (error) {
              // handle error
              console.log(error);
          }).finally(function () {
              // always executed
          });

  }
  const loadOptions = async (searchQuery, loadedOptions, { page }) => {

    try{

      const response = await list(props.regionName, props.apiKey, props.regionName, 'namecheap.domains.getList', ip,(searchQuery.length != 0 )?searchQuery : 'all')

      const responseJSON = await response.data;

      return {
          options: (responseJSON.length !== undefined )?responseJSON : [responseJSON],
          hasMore: responseJSON.length >= 1,
          additional: {
            page: searchQuery ? 2 : page + 1,
          },
        };
    }catch (e) {

    }

  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate className={`react-select-container ${props.className ? props.className : ""}`} classNamePrefix="react-select"
      key={JSON.stringify(regionName)}
      value={props.value || ""}
      loadOptions={loadOptions}
      getOptionValue={(option) => option.Name}
      getOptionLabel={(option) => option.Name}
      onChange={onChange}
      isSearchable={true}
      isClearable={true}
      placeholder="Select DNS"
      additional={{
        page: 1,
      }}
    />
  );
};

SelectAsyncPaginate.propTypes = {
  regionName: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default SelectAsyncPaginate;
