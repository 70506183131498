import React, {useEffect, useState} from 'react';
import CopyToClipboard from "react-copy-to-clipboard";
import {Button} from "../../components/Component";

const Iframe = ({iframeUrl ,btnCopy=true}) => {

    const [iframeSource, setIframeSource] = useState(iframeUrl); // Replace with your desired URL
    const [iframeContent, setIframeContent] = useState('');
    const [copied, setCopied] = useState(false);
    // Function to fetch the iframe source code
    const fetchIframeContent = () => {
        fetch(iframeSource)
            .then((response) => response.text())
            .then((data) => {

                setIframeContent(data);
            });
    };

    useEffect(() => {
        fetchIframeContent();
    }, [iframeSource]);

    async function copyToClipboard(textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport, so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    }



    return (
        <div>
            <iframe
                title="Embedded Page"
                src={iframeUrl}
                style={{width:"100%"}}
                height="600"
            ></iframe>
            {btnCopy?  <div className='h-50 text-center'>
                <Button className='btn btn-primary btn-sm' onClick={()=>{
                    copyToClipboard(iframeContent)
                }}>Copy Html</Button>
            </div>:null}


        </div>
    );
};

export default Iframe;
