import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useForm } from "react-hook-form";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../../components/block/Block";
import {
    Button, Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon, PaginationComponent, RSelect, TooltipComponent
} from "../../../components/Component";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Modal,
    ModalBody,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import Loader from "../../../layout/loader/Loader";
import { todaysDate } from "../../../utils/Utils";
import useNamecheapApi from "../../../api/namecheap/namecheapApi";

const Namecheap = () => {
    const {
        list,
        create,
        store,
        edit,
        update,
        remove
    } = useNamecheapApi(); //  use  custom hook
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [sm, updateSm] = useState(false);
    const [modal, setModal] = useState({
        edit: false,
        add: false,
    });
    const [editId, setEditedId] = useState();
    const [totalItems, setTotalItems] = useState(0);
    const [formData, setFormData] = useState({
        id: "",
        name: "",
        apiKey: "",
    });
    const [idEdit, setIdEdit] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [currentItems, setIcurrentItems] = useState([]);
    const [indexOfLastItem, setindexOfLastItem] = useState(0);
    const [indexOfFirstItem, setindexOfFirstItem] = useState(0);

    const init =async () => {
        const result = await list(itemPerPage, currentPage)
        if (result.success) {
            setData([...result.data.data])
            setCurrentPage(result.data.current_page)
            setItemPerPage(result.data.per_page)
            setIcurrentItems(result.data.data)
            setindexOfFirstItem(result.from)
            setindexOfLastItem(result.data.last_page)
            setTotalItems(result.data.total)
            setLoading(true)
        }

    }
    //  loads data
    useEffect(async () => {
        init()
    }, [currentPage]);

    // function to reset the form
    const resetForm = () => {
        setFormData({
            id: "",
            name: "",
            apiKey: "",

        });
    };

    // function to close the form modal
    const onFormCancel = () => {
        setModal({ edit: false, add: false });
        resetForm();
    };

    // submit function to add a new item
    const onFormSubmit = async (submitData) => {
        let submittedData = {
            id: data.length + 1,
            name: submitData.name,
            apiKey: submitData.apiKey,

        };
        const result = await store(submittedData)
        if(result.success){
            await init()
            resetForm();
            setModal({ edit: false }, { add: false });
        }
    };

    // submit function to update a new item
    const onEditSubmit = async (submitData) => {

        const result = await update({
            id: data.length + 1,
            name: submitData.user_name,
            apiKey: submitData.key,
        },idEdit)
        if(result.success){
            await init()

            setModal({ edit: false });
            resetForm();
        }
    };

    // function that loads the want to editted data
    const onEditClick = (id) => {
        let index = data.findIndex((item) => item.id === id);
        setIdEdit(id)

        setFormData({id: id,apiKey:  data[index]?.key,name: data[index]?.user_name})
        setModal({ add: false,edit: true });
    };

    // function delete item
    const onDeleteClick = async (id) => {
        const result = await remove(id)
        if (result.success){
            setIcurrentItems(currentItems.filter((item) => item.id !== id));
            setData([currentItems])
        }
    };

    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Head title="Namecheap Accounts"></Head>
            <Content>
                {loading? <>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle tag="h3" page>
                                    Namecheap Accounts
                                </BlockTitle>
                                <BlockDes className="text-soft">
                                    <p>
                                    vous avez un total de {totalItems} Accounts.
                                </p>
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <Button
                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                                        onClick={() => updateSm(!sm)}
                                    >
                                        <Icon name="menu-alt-r"></Icon>
                                    </Button>
                                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                        <ul className="nk-block-tools g-3">
                                            <li className="nk-block-tools-opt">
                                                <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                                                    <Icon name="plus"></Icon>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>

                    <Block>
                        <DataTable className="card-stretch">

                            <DataTableBody>
                                <DataTableHead>
                                    <DataTableRow>
                                        <span className="sub-text">Accounts</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Create At</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Updated At</span>
                                    </DataTableRow>
                                    <DataTableRow className="nk-tb-col-tools text-end">

                                    </DataTableRow>
                                </DataTableHead>
                                {/*Head*/}
                                {currentItems.length > 0
                                    ? currentItems.map((item) => {
                                        return (
                                            <DataTableItem key={item.id}>
                                                <DataTableRow size="mb">
                                                  <span className="tb-amount">
                                                    {item?.user_name}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow size="mb">
                                                  <span className="tb-amount">
                                                    {item?.created_at}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow size="mb">
                                                  <span className="tb-amount">
                                                    {item?.updated_at??'---'}
                                                  </span>
                                                </DataTableRow>
                                                <DataTableRow className="nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                                                            <TooltipComponent
                                                                tag="a"
                                                                containerClassName="btn btn-trigger btn-icon"
                                                                id={"edit" + item.id}
                                                                icon="edit-alt-fill"
                                                                direction="top"
                                                                text="Modifer"
                                                            />
                                                        </li>
                                                        <li className="nk-tb-action-hidden" onClick={() => onDeleteClick(item.id)}>
                                                            <TooltipComponent
                                                                tag="a"
                                                                containerClassName="btn btn-trigger btn-icon"
                                                                id={"edit" + item.id}
                                                                icon="trash"
                                                                direction="top"
                                                                text="Supprimer"
                                                            />
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                                                    <Icon name="more-h"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end>
                                                                    <ul className="link-list-opt no-bdr">
                                                                        <li onClick={() => onEditClick(item.id)}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#edit"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                            >
                                                                                <Icon name="edit"></Icon>
                                                                                <span>Modifer</span>
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li onClick={() => onDeleteClick(item.id)}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#edit"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                            >
                                                                                <Icon name="trash"></Icon>
                                                                                <span>Supprimer</span>
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })
                                    : null}
                            </DataTableBody>
                            <div className="card-inner">
                                {currentItems.length > 0 ? (
                                    <PaginationComponent
                                        itemPerPage={itemPerPage}
                                        totalItems={totalItems}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <span className="text-silent">No data found</span>
                                    </div>
                                )}
                            </div>
                        </DataTable>
                    </Block>
                    <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
                        <ModalBody>
                            <a
                                href="#close"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    onFormCancel();
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">Ajouter un Account</h5>
                                <div className="mt-4">
                                    <Form className="mt-4" onSubmit={handleSubmit(onFormSubmit)} noValidate>
                                        <Row className="g-gs mt-2">
                                            <Col md="12">
                                                <div className="form-group">
                                                    <label className="form-label">Nom </label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        defaultValue={formData.name}
                                                        className="form-control"
                                                        ref={register({ required: "This field is required" })}
                                                    />
                                                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <label className="form-label">Api Key</label>
                                                    <input
                                                        type="text"
                                                        name="apiKey"
                                                        defaultValue={formData.apiKey}
                                                        className="form-control"
                                                        ref={register({ required: "This field is required" })}
                                                    />
                                                    {errors.apiKey && <span className="invalid">{errors.apiKey.message}</span>}
                                                </div>
                                            </Col>


                                        </Row>
                                        <Row className="gy-4 mt-2">

                                            <Col size="12">
                                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                    <li>
                                                        <Button type="submit" color="primary" size="md">
                                                            Add New Account
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#cancel"
                                                            onClick={(ev) => {
                                                                ev.preventDefault();
                                                                onFormCancel();
                                                            }}
                                                            className="link link-light"
                                                        >
                                                            Annuler
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Form>

                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
                        <ModalBody>
                            <a
                                href="#cancel"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    onFormCancel();
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">Update Account</h5>
                                <Form className="mt-4" onSubmit={handleSubmit(onEditSubmit)} noValidate>
                                    <Row className="g-gs mt-2">
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Nom </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    defaultValue={formData.name}
                                                    className="form-control"
                                                    ref={register({ required: "This field is required" })}
                                                />
                                                {errors.name && <span className="invalid">{errors.name.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Api Key</label>
                                                <input
                                                    type="text"
                                                    name="apiKey"
                                                    defaultValue={formData.apiKey}
                                                    className="form-control"
                                                    ref={register({ required: "This field is required" })}
                                                />
                                                {errors.apiKey && <span className="invalid">{errors.apiKey.message}</span>}
                                            </div>

                                        </Col>

                                    </Row>
                                    <Row className="gy-4 mt-2">

                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button type="submit" color="primary" size="md">
                                                        Modifer
                                                    </Button>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#cancel"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            onFormCancel();
                                                        }}
                                                        className="link link-light"
                                                    >
                                                        Annuler
                                                    </a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </ModalBody>
                    </Modal>

                </>:<Loader/>}

            </Content>
        </React.Fragment>
    );
};

export default Namecheap;
