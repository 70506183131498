import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../../components/block/Block";
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead, DataTableItem, DataTableRow,
    Icon, PaginationComponent, PreviewAltCard,
    Row,
    RSelect, TooltipComponent
} from "../../../components/Component";
import DatePicker from "react-datepicker";
import {
    Badge,
    Card,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Spinner,
    UncontrolledDropdown
} from "reactstrap";

import Loader from "../../../layout/loader/Loader";
import { convertDateFormat } from "../../../utils/Utils";
import useAccountApi from "../../../api/account/accountApi";
import LoaderTable from "../../../layout/loader/LoaderTable";
import Welcome from "../../../layout/welcome/Welcome";




const Campaign = ({ ...props }) =>  {
    const {
        list,
        filter,
    } = useAccountApi(); //  use  custom hook
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [campaings, setCampaings] = useState([]);

    const [account, setAccount] = useState({});

    const [rangeStart, setRangeStart] = useState(new Date());
    const [rangeEnd, setRangeEnd] = useState(new Date());
    const [campaing, setCampaing] = useState('');
    const [category, setCategory] = useState('');
    const [sort, setSortState] = useState ("desc");
    const [sortCol, setSorCol] = useState ("id");
    const [run, setRun] = useState (true);

    let result={};

    // unselects the data on mount
    useEffect(async () => {
        let result = await list()
        if (result.success) {
            setData(result.data.data)
        }
        setLoading(true)
    }, []);

    useEffect(async ()=>{
        setCampaings([])

        if(account?.type ==='ClixFlow'){
             result = await filter({
                 account:account,
                 action:'campaign' ,
                 type: 'ClixFlow',
                 campaign: campaing,
                 category: category,
                 order:sortCol,
                 direction:sort,
                 start: `${rangeStart.getMonth() + 1}/${rangeStart.getDate()}/${rangeStart.getFullYear()}`,
                 end: `${rangeEnd.getMonth() + 1}/${rangeEnd.getDate()}/${rangeEnd.getFullYear()}`,

             });
            if (result.success) {
                setCampaings(result.data.data)
            }
        }
        if(account?.type ==='B2direct'){
            result = await filter({
                account:account,
                type: 'B2direct',
                action:'campaign' ,
                start: `${rangeStart.getFullYear()}-${rangeStart.getMonth()+ 1}-${rangeStart.getDate()}`,
                end: `${rangeEnd.getFullYear()}-${rangeEnd.getMonth()+ 1}-${rangeEnd.getDate()}`,
                order: 'id',
                direction: sort,
            });
            if (result.success) {
                setCampaings(result.data)
            }
        }
        if (account?.type ==='Cx3ads'){
            result = await filter({
                account:account,
                type: 'Cx3ads',
                action:'campaign' ,
                start: `${rangeStart.getMonth() + 1}/${rangeStart.getDate()}/${rangeStart.getFullYear()}`,
                end: `${rangeEnd.getMonth() + 1}/${rangeEnd.getDate()+1}/${rangeEnd.getFullYear()}`,
                start_at_row : 1,
                row_limit : 0,
            });
            if (result.success) {
                setCampaings(result.data.data)
            }
        }

    },[account,sort,rangeStart,rangeEnd,campaing,category,run])


    const NumberFormatEnUS =(number = 0)=>{

        return  new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(number);

    }

    const handleSort = (column) => {
        if (sort ==='desc')setSortState('asc');
        else setSortState('desc')
        setSorCol(column)
    };

    return (
        <React.Fragment>
            <Head title="Campaigns Report"></Head>
            <Content>
                {loading ? <>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle page>Campaigns Report {account?.name?` ( ${account?.name} ) `:''}</BlockTitle>
                                <BlockDes className="text-soft">
                                    <p>Campaigns Report.</p>
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <ul className="nk-block-tools g-3">
                                    <li className="nk-block-tools-opt ">
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="transparent" className="btn btn-white btn-outline-light dropdown-toggle">
                                                <Icon name="building" />
                                                <span>{account?.name ? account?.name: "Accounts"}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <ul className="link-list-opt no-bdr">
                                                    {data?.map((item) => {
                                                        return (<li key={`account-${item.id}`}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href={`#${item.name}`}
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setAccount(item);
                                                                }}
                                                            >
                                                                <Icon name="building"></Icon>
                                                                <span>{item.name}</span>
                                                            </DropdownItem>
                                                        </li>);
                                                    })}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                    <li>
                                        <div className="form-group">
                                            <div className="form-control-wrap">
                                                <div className="input-daterange date-picker-range input-group">
                                                    <DatePicker
                                                        selected={rangeStart}
                                                        onChange={setRangeStart}
                                                        selectsStart
                                                        startDate={rangeStart}
                                                        endDate={rangeEnd}
                                                        wrapperClassName="start-m"
                                                        className="form-control"
                                                    />{" "}
                                                    <div className="input-group-addon">TO</div>
                                                    <DatePicker
                                                        selected={rangeEnd}
                                                        onChange={setRangeEnd}
                                                        startDate={rangeStart}
                                                        endDate={rangeEnd}
                                                        selectsEnd
                                                        minDate={rangeStart}
                                                        wrapperClassName="end-m"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <input className="form-control form-control-sm"  onChange={(e)=>setCampaing(e.target.value)} placeholder='Search Campaigns By ID...'/>
                                    </li>
                                    <li>
                                        <input className="form-control form-control-sm d-none"  onChange={(e)=>setCategory(e.target.value)} placeholder='Filter by Category...'/>
                                    </li>

                                    <li>
                                        <a
                                            id='id'
                                            href="#filter"
                                            onClick={() => {
                                                setRun(!run)
                                            }}
                                            className="btn btn-sm btn-white btn-outline-light"
                                        >
                                            <Icon name="reload-alt"/>
                                            <span>Run</span>
                                        </a>
                                    </li>
                                </ul>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                    {(account?.type ==='ClixFlow' )?<>
                            <Block>
                                <DataTable className="card-stretch">
                                    <div className="card-inner">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h5 className="title">Campaigns Report</h5>
                                            </div>

                                        </div>
                                    </div>
                                    <DataTableBody bodyclass="nk-tb-tnx">
                                        <DataTableHead>
                                            <DataTableRow>
                                                  <span className="tb-lead " onClick={() => handleSort('id')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='id' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='id' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>

                                                    Id
                                                </span>

                                            </DataTableRow>
                                            <DataTableRow >
                                                  <span className="tb-lead " onClick={() => handleSort('name')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='name' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='name' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>

                                                    Campaign Name
                                                </span>

                                            </DataTableRow>
                                            <DataTableRow >
                                                 <span className="tb-lead " onClick={() => handleSort('clicks')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='clicks' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='clicks' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>

                                                 Clicks
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow >
                                                 <span className="tb-lead " onClick={() => handleSort('sales')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='sales' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='sales' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                 Sales
                                                </span>

                                            </DataTableRow>
                                            <DataTableRow >
                                                 <span className="tb-lead " onClick={() => handleSort('conv')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='conv' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='conv' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                 Conv
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow >
                                                <span className="tb-lead " onClick={() => handleSort('epc')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='epc' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='epc' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                 Conv
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow >
                                                 <span className="tb-lead " onClick={() => handleSort('earnings')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='earnings' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='earnings' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                 Earnings
                                                </span>
                                            </DataTableRow>

                                        </DataTableHead>
                                        {campaings?.length? campaings?.map((item,index) => {
                                            return (<>
                                                    <DataTableItem key={index} >
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead ">{item?.id}</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead text-primary">{item?.name}</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{item?.clicks}</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{item?.sales}</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{item?.conversion} %</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{NumberFormatEnUS(item?.epc)} </span>

                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{NumberFormatEnUS(item?.earned)} </span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                    </DataTableItem>
                                                </>
                                            )

                                        }): null}
                                        {campaings?.length ? <>
                                            <DataTableItem className='bg-gray-200' >
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">TOTAL </span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size='xl' >
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">{campaings?.reduce((accumulator, item) => accumulator + item.clicks, 0)}</span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">{campaings?.reduce((accumulator, item) => accumulator + item.sales, 0)}</span>

                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">{campaings?.reduce((accumulator, item) => accumulator + item.conversion, 0)} %</span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">{NumberFormatEnUS(campaings?.reduce((accumulator, item) => accumulator + item.epc, 0)) } </span>

                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">{NumberFormatEnUS(campaings?.reduce((accumulator, item) => accumulator + item.earned, 0)) } </span>

                                                        </div>
                                                    </div>
                                                </DataTableRow>

                                            </DataTableItem>
                                        </> : null}
                                    </DataTableBody>

                                    <div className="card-inner">
                                        { campaings?.length ? null:<><LoaderTable/></>}

                                    </div>
                                </DataTable>
                            </Block>
                        </>:
                        (account?.type ==='B2direct' )? <><Block>

                            <Row className='mb-3'>
                                <Col size={2}>
                                    <PreviewAltCard className="card-full">
                                        <div className="card-title-group align-start mb-0">
                                            <div className="card-title">
                                                <h6 className="subtitle">Clicks</h6>
                                            </div>
                                            <div className="card-tools">
                                                <TooltipComponent
                                                    iconClass="card-hint"
                                                    icon="help-fill"
                                                    direction="left"
                                                    id="total_click"
                                                    text="total_click"
                                                ></TooltipComponent>
                                            </div>
                                        </div>
                                        <div className="card-amount">
                  <span className="amount">
                    {campaings?.summary?.total_click} <span className="currency currency-usd"></span>
                  </span>
                                        </div>

                                    </PreviewAltCard>
                                </Col>
                                <Col size={2}>
                                    <PreviewAltCard className="card-full">
                                        <div className="card-title-group align-start mb-0">
                                            <div className="card-title">
                                                <h6 className="subtitle">CV</h6>
                                            </div>
                                            <div className="card-tools">
                                                <TooltipComponent
                                                    iconClass="card-hint"
                                                    icon="help-fill"
                                                    direction="left"
                                                    id="cv"
                                                    text="cv"
                                                ></TooltipComponent>
                                            </div>
                                        </div>
                                        <div className="card-amount">
                  <span className="amount">
                    {campaings?.summary?.cv} <span className="currency currency-usd"></span>
                  </span>
                                        </div>

                                    </PreviewAltCard>
                                </Col>
                                <Col size={2}>
                                    <PreviewAltCard className="card-full">
                                        <div className="card-title-group align-start mb-0">
                                            <div className="card-title">
                                                <h6 className="subtitle">VT Convs</h6>
                                            </div>
                                            <div className="card-tools">
                                                <TooltipComponent
                                                    iconClass="card-hint"
                                                    icon="help-fill"
                                                    direction="left"
                                                    id="view_through_cv"
                                                    text="view_through_cv"
                                                ></TooltipComponent>
                                            </div>
                                        </div>
                                        <div className="card-amount">
                  <span className="amount">
                   {campaings?.summary?.view_through_cv} <span className="currency currency-usd"></span>
                  </span>
                                        </div>

                                    </PreviewAltCard>
                                </Col>
                                <Col size={2}>
                                    <PreviewAltCard className="card-full">
                                        <div className="card-title-group align-start mb-0">
                                            <div className="card-title">
                                                <h6 className="subtitle">Event</h6>
                                            </div>
                                            <div className="card-tools">
                                                <TooltipComponent
                                                    iconClass="card-hint"
                                                    icon="help-fill"
                                                    direction="left"
                                                    id="Event"
                                                    text="Event"
                                                ></TooltipComponent>
                                            </div>
                                        </div>
                                        <div className="card-amount">
                  <span className="amount">
                     {campaings?.summary?.event} <span className="currency currency-usd"></span>
                  </span>
                                        </div>

                                    </PreviewAltCard>
                                </Col>
                                <Col size={2}>
                                    <PreviewAltCard className="card-full">
                                        <div className="card-title-group align-start mb-0">
                                            <div className="card-title">
                                                <h6 className="subtitle">EVR</h6>
                                            </div>
                                            <div className="card-tools">
                                                <TooltipComponent
                                                    iconClass="card-hint"
                                                    icon="help-fill"
                                                    direction="left"
                                                    id="EVR"
                                                    text="EVR"
                                                ></TooltipComponent>
                                            </div>
                                        </div>
                                        <div className="card-amount">
                  <span className="amount">
                    {campaings?.summary?.evr} <span className="currency currency-usd"></span>
                  </span>
                                        </div>

                                    </PreviewAltCard>
                                </Col>
                                <Col size={2}>
                                    <PreviewAltCard className="card-full">
                                        <div className="card-title-group align-start mb-0">
                                            <div className="card-title">
                                                <h6 className="subtitle">CVR</h6>
                                            </div>
                                            <div className="card-tools">
                                                <TooltipComponent
                                                    iconClass="card-hint"
                                                    icon="help-fill"
                                                    direction="left"
                                                    id="CVR"
                                                    text="CVR"
                                                ></TooltipComponent>
                                            </div>
                                        </div>
                                        <div className="card-amount">
                  <span className="amount">
                    {campaings?.summary?.cvr}<span className="currency currency-usd"></span>
                  </span>
                                        </div>

                                    </PreviewAltCard>
                                </Col>

                            </Row>
                            <Row className='mb-3'>
                                <Col size={4}>
                                    <PreviewAltCard className="card-full">
                                        <div className="card-title-group align-start mb-0">
                                            <div className="card-title">
                                                <h6 className="subtitle">RPC</h6>
                                            </div>
                                            <div className="card-tools">
                                                <TooltipComponent
                                                    iconClass="card-hint"
                                                    icon="help-fill"
                                                    direction="left"
                                                    id="RPC"
                                                    text="RPC"
                                                ></TooltipComponent>
                                            </div>
                                        </div>
                                        <div className="card-amount">
                  <span className="amount">
                     { NumberFormatEnUS(campaings?.summary?.rpc)}  <span className="currency currency-usd"></span>
                  </span>
                                        </div>

                                    </PreviewAltCard>
                                </Col>
                                <Col size={4}>
                                    <PreviewAltCard className="card-full">
                                        <div className="card-title-group align-start mb-0">
                                            <div className="card-title">
                                                <h6 className="subtitle">Revenue</h6>
                                            </div>
                                            <div className="card-tools">
                                                <TooltipComponent
                                                    iconClass="card-hint"
                                                    icon="help-fill"
                                                    direction="left"
                                                    id="Revenue"
                                                    text="Revenue"
                                                ></TooltipComponent>
                                            </div>
                                        </div>
                                        <div className="card-amount">
                  <span className="amount">
                    { NumberFormatEnUS(campaings?.summary?.revenue)}   <span className="currency currency-usd"></span>
                  </span>
                                        </div>

                                    </PreviewAltCard>
                                </Col>
                                <Col size={4}>
                                    <PreviewAltCard className="card-full">
                                        <div className="card-title-group align-start mb-0">
                                            <div className="card-title">
                                                <h6 className="subtitle">Redirect Traffic Rev</h6>
                                            </div>
                                            <div className="card-tools">
                                                <TooltipComponent
                                                    iconClass="card-hint"
                                                    icon="help-fill"
                                                    direction="left"
                                                    id="redirect_traffic_revenue"
                                                    text="redirect_traffic_revenue"
                                                ></TooltipComponent>
                                            </div>
                                        </div>
                                        <div className="card-amount">
                  <span className="amount">
                      { NumberFormatEnUS(campaings?.summary?.redirect_traffic_revenue)} <span className="currency currency-usd"></span>
                  </span>
                                        </div>

                                    </PreviewAltCard>
                                </Col>

                            </Row>

                            <DataTable className="card-stretch">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title">Detailed Report</h5>
                                        </div>
                                    </div>
                                </div>
                                <DataTableBody bodyclass="nk-tb-tnx">
                                    <DataTableHead>
                                        <DataTableRow>
                                            <span className="tb-lead " onClick={() => handleSort('of')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='of' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='of' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                 Offer
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                             <span className="tb-lead " onClick={() => handleSort('im')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='im' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='im' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                 Impression
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                              <span className="tb-lead " onClick={() => handleSort('cs')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='cs' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='cs' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                 Clicks
                                            </span>

                                        </DataTableRow>
                                        <DataTableRow >
                                              <span className="tb-lead " onClick={() => handleSort('dcs')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='dcs' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='dcs' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                 Duplicate Clicks
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                            <span className="tb-lead " onClick={() => handleSort('ctr')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='ctr' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='ctr' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                               CTR
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                               <span className="tb-lead " onClick={() => handleSort('ics')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='ics' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='ics' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                               Invalid clicks
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                              <span className="tb-lead " onClick={() => handleSort('tcv')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='tcv' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='tcv' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                  Total CV
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                            <span className="tb-lead " onClick={() => handleSort('cv')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='cv' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='cv' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                 CV
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                             <span className="tb-lead " onClick={() => handleSort('vtcv')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='vtcv' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='vtcv' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                VT CV
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                                 <span className="tb-lead " onClick={() => handleSort('cvr')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='cvr' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='cvr' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                CVR
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                               <span className="tb-lead " onClick={() => handleSort('rpc')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='rpc' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='rpc' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                RPC
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                               <span className="tb-lead " onClick={() => handleSort('rpm')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='rpm' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='rpm' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                RPM
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                              <span className="tb-lead " onClick={() => handleSort('rev')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='rev' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='rev' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                Rev
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow >
                                             <span className="tb-lead " onClick={() => handleSort('rtr')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='rtr' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='rtr' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                Redirect Traffic Rev
                                            </span>
                                        </DataTableRow>

                                    </DataTableHead>
                                    {campaings?.table?.length ?
                                        campaings?.table?.map((item,index)=>{

                                            return(
                                                <DataTableItem key={index} >
                                                    <DataTableRow>
                                                        <span className='text-primary'>{item.columns.map((column)=>` ${column.label} [${column.id}] [${column.column_type}]`)}</span>
                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span  className="tb-lead">{item.reporting.imp}</span>

                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{item.reporting.total_click}</span>

                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{item.reporting.duplicate_click}</span>
                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{item.reporting.ctr}</span>
                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{item.reporting.invalid_click}</span>
                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span  className="tb-lead">{item.reporting.invalid_click}</span>
                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{item.reporting.cv}</span>
                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{item.reporting.view_through_cv}</span>
                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{item.reporting.cvr}</span>

                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{item.reporting.rpc}</span>
                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{NumberFormatEnUS(item.reporting.rpm)}</span>
                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{NumberFormatEnUS(item.reporting.revenue)}</span>
                                                    </DataTableRow>
                                                    <DataTableRow >
                                                        <span className="tb-lead">{NumberFormatEnUS(item.reporting.redirect_traffic_revenue)}</span>
                                                    </DataTableRow>
                                                </DataTableItem>)

                                        }):null}

                                </DataTableBody>
                                <div className="card-inner">
                                    { campaings?.table?.length ? null:<><LoaderTable/></>}
                                </div>
                            </DataTable>
                        </Block></>: (account?.type ==='Cx3ads' )?  <>
                            <Block>
                                <DataTable className="card-stretch">
                                    <div className="card-inner">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h5 className="title">Campaigns Report</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTableBody bodyclass="nk-tb-tnx">
                                        <DataTableHead >
                                            <DataTableRow>
                                                <span className="tb-lead"> Id </span>

                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-lead"> Campaign ID </span>

                                            </DataTableRow>

                                            <DataTableRow>
                                                <span className="tb-lead"> Offer Name </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-lead"> Click </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-lead">Conversion Rate</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-lead">EPC</span>
                                            </DataTableRow>

                                            <DataTableRow>
                                                <span className="tb-lead">Impressions</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-lead">Price </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-lead">Revenue Converted</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-lead">Revenue</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-lead">Vertical Name</span>
                                            </DataTableRow>

                                        </DataTableHead>
                                        {campaings?.length ? campaings?.map ((item, index) => {
                                            return (<>
                                                    <DataTableItem key={index}>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{item?.offer_id}</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>

                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{item?.campaign_id} </span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text text-primary">
                                                                    <span className="tb-lead">{item?.offer_name} </span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead ">{item?.clicks}</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead"> {item?.conversion_rate} % </span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead"> {item?.epc} % </span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>

                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead ">{item?.impressions}  %</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead ">{NumberFormatEnUS (item?.price)} / {item?.price_format} </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead ">{NumberFormatEnUS (item?.revenue_converted)}</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead ">{NumberFormatEnUS (item?.revenue)}</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead ">{item?.vertical_name}</span>
                                                        </DataTableRow>

                                                    </DataTableItem>
                                                </>
                                            );

                                        }) : null}
                                        {campaings?.length ? <>
                                            <DataTableItem className="bg-gray-200">
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">TOTAL </span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"> </span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>

                                                <DataTableRow size="xl">
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size="xl">
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size="xl">
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size="xl">
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                            <span
                                className="tb-lead">{NumberFormatEnUS (campaings?.reduce ((accumulator, item) => accumulator + item.revenue, 0))} </span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>


                                            </DataTableItem>
                                        </> : null}
                                    </DataTableBody>

                                    <div className="card-inner">

                                        {campaings?.length ?
                                            null : (
                                                <div className="text-center">
                                                    <span className="text-silent">No data found</span>
                                                </div>
                                            )}
                                    </div>
                                </DataTable>

                            </Block>
                        </>:<>
                            <Welcome/>
                        </>}

                </>: <Loader/> }

            </Content>
        </React.Fragment>
    );
};

export default Campaign;
