import Select from "react-select";
import React, { useState } from "react";
import { Col } from "../grid/Grid";
import { RSelect } from "../Component";
import SelectAsyncPaginate from "./SelectAsyncPaginate";
import { Input } from "reactstrap";
import { useForm } from "react-hook-form";

const RDnsSelect = ({ ...props }) => {
    const { errors, register, handleSubmit } = useForm();
    const [accounts, setAccounts] = useState([]);
    const [dns, setDns] = useState(null);

    const onchangeAccounts = (item) => {
        props.item.accounts=item
        setAccounts(item)
        props.onMappingChange({
            accounts:accounts,
            dns:dns
        })
    };
    const onchangeDns = (item) => {
        props.item.dns=item
        props.onMappingChange(
            {
                accounts:accounts,
                dns:dns
            }
        )
    };
    const handleChangeips = (e) => {

        let value =  JSON.parse(e.target.value); //object;

        props.item.ips.push(value);
    }

    const [val, setVal] = useState([]);

    const handleChangeNormalSelect = e => {
        props.item.ips =[]
        const updatedOptions = [...e.target.options]
            .filter(option => option.selected)
            .map(x => JSON.parse(x.value) );
        setVal(updatedOptions );

        props.item.ips=updatedOptions ;
        console.log("ips", props.item.ips);

    };

    return (
        <>

            <Col md="12">
                <div className="form-group">
                    <label className="form-label">Accounts</label>
                    <RSelect
                        value={accounts}
                        name='accounts'
                        onChange={onchangeAccounts}
                        options={props.options}
                        getOptionValue={(option) => option.apiKey}
                        getOptionLabel={(option) => option.value}

                    />
                </div>
            </Col>
            <Col md="12">
                <div className="form-group">
                    <label className="form-label"> Domain Name  </label>
                    <SelectAsyncPaginate
                        isLoading={true}
                        isClearable={true}
                        regionName={accounts.value}
                        apiKey={accounts.apiKey}
                        value={dns}
                        onChange={(dns) => { setDns(dns);onchangeDns(dns)}}
                    />
                </div>
            </Col>
            {
                (props.ipsList.length > 0)?  <Col mb="12">
                    <Input type="select" name="select" id="list-ip-0" multiple onChange={handleChangeNormalSelect}>
                        {(props.ipsList.length > 0)? props.ipsList.map((item, idx) => {
                            return (
                                <option  value={JSON.stringify(item)} key={idx}>
                                    {item.ip}
                                </option>)}):null}
                    </Input>
                </Col> :null
            }
        </>
    );
};

export default RDnsSelect;
