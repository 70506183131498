import React, {useEffect, useRef, useState} from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useForm } from "react-hook-form";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../../components/block/Block";
import {
    Button, Col,

    Icon, PaginationComponent, RSelect, TooltipComponent
} from "../../../components/Component";
import {
    Card,

} from "reactstrap";
import Console from "../../../layout/console/Console";
const Terminal = () => {

    const [sm, updateSm] = useState(false);


    return (
        <React.Fragment>
            <Head title="Terminal Micro Service"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Terminal Micro Service
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p></p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                    <ul className="nk-block-tools g-3">
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon" >
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Console/>

            </Content>
        </React.Fragment>
    );
};

export default Terminal;
