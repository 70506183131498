export const menu = [
    {
        icon: "dashboard",
        text: "Tableaux de bord",
        link: "/",
    },

    {
        heading: "Service Now Servers",
    },
    {
        icon: "linux-server",
        text: "Server",
        link: "/azure/server",
    },
];
export const sup_admin = [
    {
        icon: "dashboard",
        text: "Tableaux de bord",
        link: "/",
    },
    {
        heading: "Service Now Sponsors",
    },
    {
        icon: "card-view",
        text: "Manage Offers",
        link: "/offers",
    },
    {
        icon: "crosshair",
        text: "Campaigns",
        link: "/campaigns",
    },
    {
        icon: "bar-c",
        text: "Reports Sub ID",
        link: "/reports",
    },
    {
        icon: "users",
        text: "Accounts",
        link: "/account",
    },
    {
        heading: "Service Now Servers",
    },
    {
        icon: "linux-server",
        text: "Server",
        link: "/azure/server",
    },
    {
        icon: "sign-kobo",
        text: "Namecheap ",
        link: "/dns",
    },
    {
        heading: "Service Now Storage",
    },
    {
        icon: "hard-drive",
        text: "Azure",
        link: "/azure/storage",
    },
    {
        icon: "hard-drive",
        text: "Amazon",
        link: "/amazon/storage",
    },
    {

        heading: "Paramètres avancés",
    },
    {
        icon: "users",
        text: "Manage Users",
        link: "/user",
    },
    {
        icon: "network",
        text: "Manage Group",
        link: "/group",
    },
    {
        heading: "Activities",
    },
    {
        icon: "align-left",
        text: "User Activities",
        link: "/activity",
    },

];

export const mailerMenu = [
    {
        icon: "dashboard",
        text: "Tableaux de bord",
        link: "/",
    },
    {
        heading: "Service Now Sponsors",
    },
    {
        icon: "card-view",
        text: "Manage Offers",
        link: "/offers",
    },
    {
        icon: "crosshair",
        text: "Campaigns",
        link: "/campaigns",
    },
    {
        icon: "bar-c",
        text: "Reports Sub ID",
        link: "/reports",
    },
    {
        icon: "hard-drive",
        text: "Azure",
        link: "/azure/storage",
    },
    {
        icon: "hard-drive",
        text: "Amazon",
        link: "/amazon/storage",
    },

];
export const itMenu = [
    {
        icon: "dashboard",
        text: "Tableaux de bord",
        link: "/",
    },
    {
        heading: "Service Now Servers",
    },
    {
        icon: "linux-server",
        text: "Server",
        link: "/azure/server",
    },
    {
        icon: "sign-kobo",
        text: "Namecheap ",
        link: "/dns",
    },


];
export const userMenu = [
    {
        icon: "dashboard",
        text: "Tableaux de bord",
        link: "/",
    },
    {
        heading: "Service Now Sponsors",
    },
    {
        icon: "card-view",
        text: "Manage Offers",
        link: "/offers",
    },
    {
        icon: "crosshair",
        text: "Campaigns",
        link: "/campaigns",
    },
    {
        icon: "bar-c",
        text: "Reports Sub ID",
        link: "/reports",
    },

    {
        heading: "Service Now Servers",
    },
    {
        icon: "linux-server",
        text: "Server",
        link: "/azure/server",
    },
    {
        icon: "sign-kobo",
        text: "Namecheap ",
        link: "/dns",
    },
    {
        heading: "Service Now Storage",
    },
    {
        icon: "hard-drive",
        text: "Azure",
        link: "/azure/storage",
    },
    {
        icon: "hard-drive",
        text: "Amazon",
        link: "/amazon/storage",
    },


];

