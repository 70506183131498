import useAxiosPrivate from './../axiosClient';
import {notification, urlBackend, ValidationMessageError} from "../../utils/Utils";


const uri =`${urlBackend}/api/account`

const MESSAG_RESPONSE ={
  loading: 'Chargement...',
  create: 'account créé avec succès.',
  update: 'account mis à jour avec succès.',
  delete: 'account supprimé avec succès.',
  error: 'Une erreur est survenue. Veuillez réessayer.'
}


const useAccountApi = () => {
  const axiosPrivate = useAxiosPrivate();

  const list = async (itemPerPage='',current_page='') => {
    return await axiosPrivate.get(`${uri}?page=${current_page}&per_page=${itemPerPage}`).then((require) => {
      return require.data

    }).catch((error) => {
      return  error.response

    })
  };

  const create = async () => {
    try {
      const response = await axiosPrivate.get(`${uri}/create`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };

  const store = async (data) => {
    return await axiosPrivate.post(`${uri}`,data).then((response) => {
      if (response.data.success)
          notification('success', MESSAG_RESPONSE.create)
      else
          ValidationMessageError(response.data)
      return response.data

    }).catch((error) => {
      ValidationMessageError(error.data)
      return  error.response
    })
  };

  const edit = async (id) => {
    return await axiosPrivate.get(`${uri}/${id}`).then((response) => {
      return response.data

    }).catch((error) => {
      notification('error', MESSAG_RESPONSE.error)
      return  error.response
    })
  };

  const update = async (data, id) => {
    const toast_id=notification('info',MESSAG_RESPONSE.loading)
    return await axiosPrivate.patch(`${uri}/${id}`,data).then((response) => {
      if (response.data.success)
          notification('success', MESSAG_RESPONSE.update,toast_id)
      else notification('error', MESSAG_RESPONSE.error,toast_id)
      return response.data

    }).catch((error) => {
      notification('error', MESSAG_RESPONSE.error)
      return  error.response
    })
  };

  const remove = async (id) => {
    const toast_id=notification('info',MESSAG_RESPONSE.loading)
    return await axiosPrivate.delete(`${uri}/${id}`).then((response) => {
      if (response.data.success)
          notification('success', MESSAG_RESPONSE.delete,toast_id)
      else notification('error', MESSAG_RESPONSE.error,toast_id)
      return response.data;

    }).catch((error) => {
      notification('error', MESSAG_RESPONSE.error)
      return  error.response
    })
  };

  const filter = async (filters) => {
      const axiosConfig = axiosPrivate.defaults;
      axiosConfig.params = filters;

      return await axiosPrivate.get(`${uri}/handle/request/`, axiosConfig).then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error);

        })
    };
 const filteremail = async (filters) => {
      const axiosConfig = axiosPrivate.defaults;
      axiosConfig.params = filters;

      return await axiosPrivate.get(`${uri}/handle/request/`, filters).then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error);

        })
    };

    return {
    list,
    create,
    store,
    edit,
    update,
    remove,
    filter,
        filteremail,
  };
};

export default useAccountApi;


