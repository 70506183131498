import React, {useState} from "react";
import { Link } from "react-router-dom";
import {Button} from "../../components/Component";
import useAccountApi from "../../api/account/accountApi";
import Loader from "../loader/Loader";
import {Spinner} from "reactstrap";
import axios from 'axios';
import {urlBackend} from "../../utils/Utils";
const Download = ({account,id,creative=-1,all=false}) => {
    const {

        filter,
    } = useAccountApi(); //  use  custom hook

    const [loading, setLoading] = useState(false);

    const download = async () => {
        if(account?.type ==='ClixFlow'){
            setLoading(true);
          const  result = await filter({
                account:account,
                id:id,
                creative:creative,
                action:'download' ,
                type: 'ClixFlow',
            });
            if (result.success) {
                setLoading(false);
                window.open(`${urlBackend}${result.data}`, 'Download Window');

                handleDownload(`${urlBackend}${result.data}`)
            }

        }
        if(account?.type ==='B2direct'){


        }
        if (account?.type ==='Cx3ads'){

        }
    };
    const preview = async () => {
        if(account?.type ==='ClixFlow'){
            setLoading(true);
          const  result = await filter({
                account:account,
                id:id,
                creative:creative,
                action:'preview' ,
                type: 'ClixFlow',
            });
            if (result.success) {
                setLoading(false);
                window.open(`${urlBackend}${result.data}`, '_blank');
            }

        }
        if(account?.type ==='B2direct'){


        }
        if (account?.type ==='Cx3ads'){

        }
    };

  const  handleDownload = (uri) => {
        const url = uri;

      axios({
          url,
          method: 'GET',
          responseType: 'blob', // Set the response type to 'blob' to handle binary data
      })
          .then((response) => {
              const blob = new Blob([response.data], { type: 'application/zip' }); // Specify the correct content type for a zip file
              const blobUrl = URL.createObjectURL(blob);

              const a = document.createElement('a');
              a.href = blobUrl;
              a.download = 'file-to-download.zip'; // Set the desired filename with a .zip extension
              a.style.display = 'none';

              document.body.appendChild(a);
              a.click();

              document.body.removeChild(a);
              URL.revokeObjectURL(blobUrl);
          })
          .catch((error) => {
              console.error('Error downloading zip file:', error);
          });
    };

    return (<>
            {!loading?
                <>
                    <Button className="btn btn-primary btn-sm justify-center " onClick={download}>Download</Button>
                    {
                        all ? null  : <Button className="btn btn-primary btn-sm  justify-center " onClick={preview}>Open</Button>
                    }

                </>
                :<>
                <div className="text-center justify-content-center my-1">
                    <Spinner style={{ width: "1rem", height: "1rem" }}  color="primary" />
                    <div className='text-uppercase fw-bold text-secondary mt-4 '>chargement des données</div>
                </div>
            </>}

        </>

    );
};
export default Download;
