import {notification, urlBackend, ValidationMessageError} from "../../utils/Utils";
import useAxiosPrivate from "../axiosClient";

const uri =`${urlBackend}/api/auth`

const MESSAG_RESPONSE ={
  loading: 'Chargement...',

  error: 'Une erreur est survenue. Veuillez réessayer.'
}



const useLoginApi = () => {
  const axiosPrivate = useAxiosPrivate();

  const login = async (email, password) => {
    return await axiosPrivate.post(`${uri}/login`,JSON.stringify({ email, password }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      })
      .then((response) => {
        return response.data
      })
      .catch((error)=>{
        return  error.response.data
      })
  };
  const logout = async () => {
    return await axiosPrivate.post(`${uri}/logout`, {
      withCredentials: true
    })
      .then((response) => {
        return response.data
      })
      .catch((error)=>{
        return  error.response.data
      })
  };

  const register = async (name,email, password,password_confirmed)=>{
    return await axiosPrivate.post(`${uri}/register`,JSON.stringify({name, email, password ,password_confirmed}),
    {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    })
    .then((response) => {
      return response.data
    })
    .catch((error)=>{
      return  error.response.data
    })
  }




  const forgotPassword = async (email)=>{
    return await axiosPrivate.post(`${uri}/forgot-password`,JSON.stringify({email}),
    {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: false
    })
    .then((response) => {
      return response.data
    })
    .catch((error)=>{
      return  error.response.data
    })


  }
  const updatePassword = async (email)=>{
    return await axiosPrivate.post(`${uri}/update-password`,JSON.stringify({email}),
    {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    })
    .then((response) => {
      return response.data
    })
    .catch((error)=>{
      return  error.response.data
    })


  }




  return {
    login,
    logout,
    register
  };
};

export default useLoginApi;
