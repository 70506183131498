import React, {useEffect, useMemo, useState} from "react";
import useAzureApi from "../../api/server/azureApi";
import Loader from "../loader/Loader";
import {Icon, OverlineTitle, RSelect, UserAvatar} from "../../components/Component";
import Console from "../console/Console";
import {Badge, Button, Card, Col, Input, Row, Spinner} from "reactstrap";
import Iframe from "../Iframe/Iframe";

const Server = ({isOpen,users=[]}) => {
    const {
        getServerTeam,
        handlePostAction,
    } = useAzureApi(); //  use ActivityApi custom hook

    const [data, setData] = useState([])
    const [server, setServers] = useState([])
    const [slectedServer, setSlectedServer] = useState([])
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openPMTA, setOpenPMTA] = useState(false);
    const [openTerminal, setOpenTerminal] = useState(false);
    const [openSpamIps, setOpenSpamIps] = useState(false);
    const [onSearchText, setSearchText] = useState("");

    const [listIps, setListIps] = useState(['127.0.0.1', '127.0.0.2']);

    useMemo(async () => {
        setLoading(true)
        setSlectedServer([])
        const result = await getServerTeam()
        if (result.success) {

            setData([...result.data])
            setServers([...result.data])
            setLoading(false)

        }
    }, [open])


    async function handleAction(action, data, option = 'normal') {
        await handlePostAction(action, {
            option: option,
            id: data.id,
            name: data.name,
        })
    }

    async function handleMultiAction(action, data, option = 'normal') {

        data.map(async (item) => {
            await handlePostAction(action, {
                option: option,
                id: item.id,
                name: item.name,
                main: item?.main,
            })
        })

    }

    const onFilterChange = async (value) => {
        setSearchText(value);
    };


    // Assuming you have state variables for selectedServers and possibly other data
    const [selectedServers, setSelectedServers] = useState([]);

    const onSelectChange = (e, server, selectedServers, setSelectedServers) => {
        const isChecked = e.target.checked;

        // Check if the server is already in the selectedServers list
        const serverIndex = selectedServers.findIndex((selectedServer) => selectedServer.id === server.id);
        console.log(serverIndex);

        if (isChecked && serverIndex === -1) {
            // Add the server to the selectedServers list if checked and not already present
            setSelectedServers((prevSelectedServers) => [...prevSelectedServers, server]);
        } else if (!isChecked && serverIndex !== -1) {
            // Remove the server from the selectedServers list if unchecked and exists
            const updatedServers = selectedServers.filter((selectedServer) => selectedServer.id !== server.id);
            setSelectedServers(updatedServers);
        }

        console.log(selectedServers);
    };


    const getServerSelected =   (data)=> {
        const Servers = []
        server?.map((user) => {
            return user.servers?.map((server) => {
                if (server.checked) Servers.push(server)
            })})
        return Servers
    }

    useEffect(() => {
        if (onSearchText) {
            const NewData=  data.filter(user => user.id === onSearchText)
            if (NewData.length) {
                setServers(NewData)
            }
        }
    }, [onSearchText]);



    return <>
        {
            (data?.length ? <>
                <ul class="nk-sticky-toolbar">
                    <li className="demo-layout ">
                        <a className="tipinfo" href="#" id="s-tool0" onClick={() => {
                            setOpenTerminal(!openTerminal)
                        }}>

                            <Icon name="terminal"/>
                        </a>
                    </li>

                    <li className="demo-thumb">
                        <a className="tipinfo" href="#" id="s-tool1" onClick={() => {
                            setOpen(!open)
                        }}>
                            <Icon name="users-fill"/>
                        </a>
                    </li>


                    <li className="demo-purchase">
                        <a className="tipinfo" href="#" id="s-tool-purchase" onClick={() => {
                            setOpenSpamIps(!openSpamIps)
                        }}>
                            <Icon name="policy-fill"/>
                        </a>
                    </li>

                </ul>
                <div className={`toggle-slide  toggle-slide-right nk-demo-panel  ${open ? "content-active" : ''}`}
                     style={{width: "25%"}}>
                    <div className="nk-demo-head">
                        <h6 className="mb-0">Servers Azure For Team</h6>

                        <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse me-n2" onClick={() => {
                            setOpen(false)
                        }}>
                            <em className="icon ni ni-cross"></em>
                        </a>
                    </div>
                    <div className="nk-demo-head d-block">
                        <Col md="12">
                            <div className="custom-control custom-control-sm custom-checkbox custom-control-pro">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={ `server-all`}
                                    onChange={(e) =>{

                                    }}
                                    name="user-selection"
                                />
                            </div>
                            <div className="form-group">

                                <RSelect
                                    name='accounts'
                                    options={data?.map((item)=>{ return {label:item.name,value:item.id}})}
                                    defaultValue={data?.map((item)=>{ return {label:item.label,value:item.value}})}

                                    onChange={async (e) => {
                                        await onFilterChange(e.value)
                                    }}
                                />
                            </div>
                        </Col>

                    </div>
                    <div data-simplebar="init" className="nk-demo-list">
                        {
                            !loading ? <>
                                <div   className="nk-opt-set simplebar-content">
                                    {server.length && server[0]?.servers?.length ? <>
                                        <div className="nk-opt-list col-4x  my-1">
                                            <div className="nk-opt-item only-text  " onClick={async () => {


                                                await handleMultiAction('powerOn', getServerSelected(data), 'spam');

                                            }}>
                                                        <span className="nk-opt-item-bg bg-success-">
                                                              ON
                                                        </span>
                                            </div>
                                            <div className="nk-opt-item only-text ">
                                                        <span className="nk-opt-item-bg" onClick={async () => {
                                                            await handleMultiAction('powerOff', getServerSelected(data), 'deallocate');
                                                        }}>
                                                            OFF
                                                        </span>
                                            </div>
                                            <div className="nk-opt-item only-text ">
                                                        <span className="nk-opt-item-bg" onClick={async () => {
                                                            await handleMultiAction('setup', getServerSelected(data));
                                                        }}>
                                                            FIREWALL
                                                        </span>
                                            </div>
                                            <div className="nk-opt-item only-text ">
                                                        <span className="nk-opt-item-bg" onClick={async () => {
                                                            await handleMultiAction('delete', getServerSelected(data));
                                                        }}>
                                                            DELETE
                                                        </span>
                                            </div>

                                        </div>
                                        <div className="nk-opt-list col-2x  my-1">

                                            <div className="nk-opt-item only-text  " onClick={async () => {
                                                await handleMultiAction('rotation',  getServerSelected(data));

                                            }}>
                                                        <span className="nk-opt-item-bg bg-success-">
                                                              ROTATION
                                                        </span>
                                            </div>
                                            <div className="nk-opt-item only-text  " onClick={async () => {
                                                await handleMultiAction('rotation',  getServerSelected(data), 'spam');


                                            }}>
                                                        <span className="nk-opt-item-bg bg-success-">
                                                             SPAM FILLTER
                                                        </span>
                                            </div>
                                        </div>
                                        <ul className="custom-control-group custom-control-vertical w-100 ">
                                            {
                                                server?.map((user) => {
                                                    return user.servers?.map((server) => {
                                                        return (<li  key={Math.random()}  className='p-0 my-1'>
                                                            <div className="custom-control custom-control-sm custom-checkbox custom-control-pro">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={ `server-${server.id}`}
                                                                    defaultChecked={server.checked}

                                                                    onChange={(e) =>{
                                                                        server.checked = e.currentTarget.checked;
                                                                    }}
                                                                    name="user-selection"
                                                                />
                                                                <label className="custom-control-label" htmlFor={ `server-${server.id}`}>
                                                                    <span className="user-card">
                                                                        <UserAvatar theme={(server.status_Vm === "VM running" || server.status_Vm === "Started" || server.status_Vm === 'Running'|| server.status_Vm === 'Succeeded') ? 'success-dim' :
                                                                            (server.status_Vm === "VM deallocated" || server.status_Vm === "Stopped" || server.status_Vm === "Stopped Deallocate") ? 'danger-dim' :
                                                                                (server.status_Vm === "In progress") ? 'primary-dim' : 'warning-dim'} icon={'monitor'}
                                                                                    className={
                                                                                        `sm text-${(server.status_Vm === "VM running" || server.status_Vm === "Started" || server.status_Vm === 'Running'|| server.status_Vm === 'Succeeded') ? 'success' :
                                                                                            (server.status_Vm === "VM deallocated" || server.status_Vm === "Stopped" || server.status_Vm === "Stopped Deallocate") ? 'danger' :
                                                                                                (server.status_Vm === "In progress") ? 'primary' : 'warning'}`}
                                                                        ></UserAvatar>
                                                                        <span className="user-info">
                                                                            <span className="lead-text">{user.name} ({server.main_ip}) {" "} </span>
                                                                            <span className="sub-text">  {server.name} </span>
                                                                        </span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </li>)
                                                    })
                                                })
                                            }
                                        </ul>


                                    </>:<>
                                        <Card  style={{ width: "100%", height: "80vh" }} className="justify-content-around" color="white">
                                            <div className="card-inner  text-center justify-content-center my-4">

                                                <div className='text-uppercase fw-bold text-secondary mt-4 '>No data found</div>
                                                <div className='text-sm text-gray'>Merci de patienter</div>
                                            </div>
                                        </Card>
                                    </>}

                                </div>
                            </>: <Loader/>
                        }

                    </div>
                </div>

                <div
                    className={`toggle-slide  toggle-slide-right nk-demo-panel  ${openSpamIps ? "content-active" : ''}`}
                    style={{width: "23%"}}>
                    <div className="nk-demo-head">
                        <h6 className="mb-0">Servers Azure Filter Spam List Ips Error (708)</h6>

                        <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse me-n2" onClick={() => {
                            setOpenSpamIps(false)
                        }}>
                            <em className="icon ni ni-cross"></em>
                        </a>
                    </div>
                    <div data-simplebar="init" className="nk-demo-list">
                        <div className="nk-opt-set simplebar-content">

                            <div className="form-group mx-4-1">

                                <div className="form-control-wrap">
                                    <Input className="" id="default-textarea" defaultValue="127.0.0.1" type="textarea"
                                           rows={50} value={listIps.map((ip) => `${ip}\n`)}/>
                                </div>

                            </div>
                            <div className="form-group">
                                <Button color="primary w-100  text-center justify-content-center " size="lg"
                                        onClick={async () => {
                                            await handleAction('back-list-ips', listIps);
                                        }}>
                                    Save List
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>


                <div
                    className={`toggle-slide  toggle-slide-right nk-demo-panel  bg-black  ${openTerminal ? "content-active" : ''}`}
                    style={{width: "75%"}}>
                    <div className="nk-demo-head bg-white">
                        <h6 className="mb-0">Terminal</h6>

                        <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse me-n2" onClick={() => {
                            setOpenTerminal(false)
                        }}>
                            <em className="icon ni ni-cross"></em>
                        </a>
                    </div>
                    <div data-simplebar="init" className="nk-demo-list ">
                        <Console/>
                    </div>

                </div>

            </> : <>
                <ul class="nk-sticky-toolbar">
                    <li className="demo-layout ">
                        <a className="tipinfo" href="#" id="s-tool0" onClick={() => {
                            setOpenTerminal(!openTerminal)
                        }}>

                            <Icon name="terminal"/>
                        </a>
                    </li>


                </ul>
                <div
                    className={`toggle-slide  toggle-slide-right nk-demo-panel  bg-black  ${openTerminal ? "content-active" : ''}`}
                    style={{width: "75%"}}>
                    <div className="nk-demo-head bg-white">
                        <h6 className="mb-0">Terminal</h6>

                        <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse me-n2" onClick={() => {
                            setOpenTerminal(false)
                        }}>
                            <em className="icon ni ni-cross"></em>
                        </a>
                    </div>
                    <div data-simplebar="init" className="nk-demo-list ">
                        <Console/>
                    </div>

                </div>

            </>)
        }
    </>
};
export default Server;
