import axios from "axios";
import { useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import {urlBackend} from "../utils/Utils"; // Import useHistory from React Router

const useAxiosPrivate = () => {
    const axiosClient = axios.create({
        baseURL: `${urlBackend}`,
        withCredentials: true,

        headers: { Accept: 'application/json' }
    });
    const { refresh } = useRefreshToken();
    const { auth, setAuth } = useAuth();
    const history = useHistory();

    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${auth?.accessToken}`;

    useEffect(() => {
        const requestIntercept = axiosClient.interceptors.request.use(
            (config) => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );


        const responseIntercept = axiosClient.interceptors.response.use(
            (response) => response,
            async (error) => {
                const prevRequest = error?.config;

                if (error?.response?.status === 401 ||  error?.response?.status === 510) {

                    setAuth({  });
                    Cookies.remove('access_token');
                    Cookies.remove('user_info');
                    Cookies.remove('expires_in');
                    Cookies.remove('token_type');
                    history.push('/auth-login');
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosClient.interceptors.request.eject(requestIntercept);
            axiosClient.interceptors.response.eject(responseIntercept);
        };
    }, [auth, refresh, setAuth, history]);

    return axiosClient;
};

export default useAxiosPrivate;
