import React, { useState } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";

import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
    Block, Icon,

} from "../components/Component";

import { useStateContext } from "./context/ContextProvider";
import Welcome from "../layout/welcome/Welcome";

const Homepage = () => {

  const [sm, updateSm] = useState(true);
  const {user,token} = useStateContext()

  return (
    <React.Fragment>
      <Head title="Tableaux de bord" />
      <Content>

       <Welcome/>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;
