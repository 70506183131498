import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { filterRole, filterStatus, userData } from "../../pre-built/user-manage/UserData";
import { useForm } from "react-hook-form";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle
} from "../../../components/block/Block";
import {
  Button,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead, DataTableItem, DataTableRow,
  Icon, PaginationComponent,
  Row,
  RSelect, TooltipComponent, UserAvatar
} from "../../../components/Component";
import { bulkActionOptions } from "../../../utils/Utils";
import {
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Modal,
    ModalBody,
    UncontrolledDropdown
} from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "../../../layout/loader/Loader";
import useUserApi from "../../../api/user/userApi";
import useAccountApi from "../../../api/account/accountApi";


const User = () => {
    const  accountApi = useAccountApi(); //
  const {
    list,
    store,
    edit,
    update,
    remove
  } = useUserApi(); //  use UserApi custom hook



  const [loading, setLoading] = useState(false);
  const [data, setData] =  useState([]);;
  const [accounts, setAccounts] = useState([]);

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
    status: "",
    accounts: [],
      actions: [],

  });
  const [idEdit, setidEdit] = useState();
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentItems, setIcurrentItems] = useState([]);
  const [indexOfLastItem, setindexOfLastItem] = useState(0);
  const [indexOfFirstItem, setindexOfFirstItem] = useState(0);
  const [sort, setSortState] = useState("");
  const roles=[
        { value: "sup_admin", label: "Super Administrate" },
      { value: "admin", label: "Administrate" },
      { value: "mailer", label: "User Offer" },
        { value: "it", label: "User Server" },
        { value: "user", label: "User Server & Offer " },
    ]
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedAccounts, setSelectedAccounts] = useState(null);

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
      setFormData({ ...formData, role: selectedOption.value })
    };

    const handleSelectAccountsChange = (selectedAccounts) => {
        setSelectedAccounts(selectedAccounts);
        setFormData({ ...formData, accounts: selectedAccounts })

    };

    const handleSelectStatusChange = (selectedStatus) => {
        setSelectedStatus(selectedStatus);
        setFormData({ ...formData, status: selectedStatus.value })

    };
  const init = async () => {
      setLoading(true)

      const result = await list(itemPerPage, currentPage)
      if (result.success) {
          setData([...result.data.data])
          setCurrentPage(result.data.current_page)
          setItemPerPage(result.data.per_page)
          setIcurrentItems(result.data.data)
          setindexOfFirstItem(result.from)
          setindexOfLastItem(result.data.last_page)
          setTotalItems(result.data.total)
          setLoading(false)
      }
  }
  //  loads data


  useEffect(async ()=>{
      await init()
  },[currentPage])

    useEffect(async () => {
        const result = await accountApi.list(10000, currentPage)
        if (result.success) {
            setAccounts(result.data.data)
        }


    }, []);


  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // unselects the data on mount
  useEffect(() => {
    let newData;
    newData = userData.map((item) => {
      item.checked = false;
      return item;
    });
    setData([...newData]);
  }, []);

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = userData.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...userData]);
    }
  }, [onSearchText, setData]);

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to change the selected property of an item
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      role: "",
      password: "",
      status: "",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = async (submitData) => {
      const { name, email, password } = submitData;
      let submittedData = {
          name: name,
          role: formData.role,
          email: email,
          password: password,
          status: formData.status,
          actions: formData.actions,
          accounts: formData.accounts,
      };
      const result = await store(submittedData)
      if (result.success) {
          await  init()
          resetForm();
          setModal({ edit: false }, { add: false });
      }
  };

  // submit function to update a new item
  const onEditSubmit = async (submitData) => {

      const { name, email, password } = submitData;
      let submittedData = {
          name: name,
          role: formData.role,
          email: email,
          password: password,
          status: formData.status,
          actions: formData.actions,
          accounts: formData.accounts,
      };

      const result = await update(submittedData, idEdit)
      if (result.success) {
          await init()
          resetForm();
          setModal({ edit: false }, { add: false });
      }

  };

  // function that loads the want to editted data
  const onEditClick = async (id) => {
      const result = await edit(id);
      if (result.success) {
          setidEdit(id)
          const selectedOptionFromApi = roles.find(option => option.value === result.data.role);
          setSelectedOption(selectedOptionFromApi);

          const selectedStatusOptionFromApi = filterStatus.find(option => option.value === result.data.status);
          setSelectedStatus(selectedStatusOptionFromApi);
          const selectedAccountsOptionFromApi =  result.data.accounts.map((item)=>{ return  {label:item.name,value:item.id}});
          setSelectedAccounts(selectedAccountsOptionFromApi);
          setFormData({  name: result.data.name,
              email: result.data.email,
              password:result.data.email,
              role: result.data.role,
              actions: result.data.data,
              status: result.data.status, accounts: selectedAccountsOptionFromApi })


          setModal({ add: false, edit: true });
      }
  };
  // function that loads the want to editted data
  const onDeleteClick = async (id) => {
      const result = await remove(id)
      if (result.success) {
          await  init()

      }
  };

  // function to change to suspend property for an item
  const suspendUser = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Suspend";
    setData([...newData]);
  };

  // function to change the check property of an item
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function which fires on applying selected action
  const onActionClick = (e) => {
    if (actionText === "suspend") {
      let newData = data.map((item) => {
        if (item.checked === true) item.status = "Suspend";
        return item;
      });
      setData([...newData]);
    } else if (actionText === "delete") {
      let newData;
      newData = data.filter((item) => item.checked !== true);
      setData([...newData]);
    }
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);


  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title="Gestion utilisateurs"></Head>
      <Content>
        {!loading?<>

          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Manage Users
                </BlockTitle>
                <BlockDes className="text-soft">
                  <p>vous avez un total de {currentItems.length} utilisateurs.</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                    onClick={() => updateSm(!sm)}
                  >
                    <Icon name="menu-alt-r"></Icon>
                  </Button>
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <DataTable className="card-stretch">
              <div className="card-inner position-relative card-tools-toggle d-none">
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-3">
                      <div className="form-wrap">
                        <RSelect
                          options={bulkActionOptions}
                          className="w-130px"
                          placeholder="Bulk Action"
                          onChange={(e) => onActionText(e)}
                        />
                      </div>
                      <div className="btn-wrap">
                      <span className="d-none d-md-block">
                        <Button
                          disabled={actionText !== "" ? false : true}
                          color="light"
                          outline
                          className="btn-dim"
                          onClick={(e) => onActionClick(e)}
                        >
                          Appliquer
                        </Button>
                      </span>
                        <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText !== "" ? false : true}
                          className="btn-dim btn-icon"
                          onClick={(e) => onActionClick(e)}
                        >
                          <Icon name="arrow-right"></Icon>
                        </Button>
                      </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-tools me-n1 ">
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <a
                          href="#search"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle();
                          }}
                          className="btn btn-icon search-toggle toggle-search"
                        >
                          <Icon name="search"></Icon>
                        </a>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <div className="toggle-wrap">
                          <Button
                            className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                            onClick={() => updateTableSm(true)}
                          >
                            <Icon name="menu-right"></Icon>
                          </Button>
                          <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                            <ul className="btn-toolbar gx-1">
                              <li className="toggle-close">
                                <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                  <Icon name="arrow-left"></Icon>
                                </Button>
                              </li>
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                    <div className="dot dot-primary"></div>
                                    <Icon name="filter-alt"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    end
                                    className="filter-wg dropdown-menu-xl"
                                    style={{ overflow: "visible" }}
                                  >
                                    <div className="dropdown-head">
                                      <span className="sub-title dropdown-title">Filtrer les utilisateurs</span>
                                      <div className="dropdown">
                                        <a
                                          href="#more"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                          className="btn btn-sm btn-icon"
                                        >
                                          <Icon name="more-h"></Icon>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="dropdown-body dropdown-body-rg">
                                      <Row className="gx-6 gy-3">
                                        <Col size="6">
                                          <div className="custom-control custom-control-sm custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="hasBalance"
                                            />
                                            <label className="custom-control-label" htmlFor="hasBalance">
                                              {" "}
                                              Avoir un équilibre
                                            </label>
                                          </div>
                                        </Col>
                                        <Col size="6">
                                          <div className="custom-control custom-control-sm custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="hasKYC"
                                            />
                                            <label className="custom-control-label" htmlFor="hasKYC">
                                              {" "}
                                              KYC vérifié
                                            </label>
                                          </div>
                                        </Col>
                                        <Col size="6">
                                          <div className="form-group">
                                            <label className="overline-title overline-title-alt">RÔLE</label>
                                            <RSelect options={filterRole} placeholder="Any Role" />
                                          </div>
                                        </Col>
                                        <Col size="6">
                                          <div className="form-group">
                                            <label className="overline-title overline-title-alt">Status</label>
                                            <RSelect options={filterStatus} placeholder="Any Status" />
                                          </div>
                                        </Col>
                                        <Col size="12">
                                          <div className="form-group">
                                            <button type="button" className="btn btn-secondary">
                                              Filter
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="dropdown-foot between">
                                      <a
                                        href="#reset"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="clickable"
                                      >
                                        Réinitialiser le filtre
                                      </a>
                                      <a
                                        href="#save"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        Réinitialiser le filtre
                                      </a>
                                    </div>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                    <Icon name="setting"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end className="dropdown-menu-xs">
                                    <ul className="link-check">
                                      <li>
                                        <span>Montrer</span>
                                      </li>
                                      <li className={itemPerPage === 10 ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setItemPerPage(10);
                                          }}
                                        >
                                          10
                                        </DropdownItem>
                                      </li>
                                      <li className={itemPerPage === 15 ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setItemPerPage(15);
                                          }}
                                        >
                                          15
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                    <ul className="link-check">
                                      <li>
                                        <span>Order</span>
                                      </li>
                                      <li className={sort === "dsc" ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setSortState("dsc");
                                            sortFunc("dsc");
                                          }}
                                        >
                                          DESC
                                        </DropdownItem>
                                      </li>
                                      <li className={sort === "asc" ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setSortState("asc");
                                            sortFunc("asc");
                                          }}
                                        >
                                          ASC
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                  <div className="card-body">
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search active"
                        onClick={() => {
                          setSearchText("");
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Recherche par utilisateur ou email"
                        value={onSearchText}
                        onChange={(e) => onFilterChange(e)}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableBody>
                <DataTableHead>

                  <DataTableRow>
                    <span className="sub-text">Utilisateur</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">Role</span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">Verified at </span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">Dernière connexion</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">Statut</span>
                  </DataTableRow>
                  <DataTableRow className="nk-tb-col-tools text-end">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="tranparent"
                        className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                      >
                        <Icon name="plus"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end className="dropdown-menu-xs">
                        <ul className="link-tidy sm no-bdr">
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id="bl" />
                              <label className="custom-control-label" htmlFor="bl">
                                Balance
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id="ph" />
                              <label className="custom-control-label" htmlFor="ph">
                                Téléphone
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id="vri" />
                              <label className="custom-control-label" htmlFor="vri">
                                Action
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id="st" />
                              <label className="custom-control-label" htmlFor="st">
                                Statut
                              </label>
                            </div>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}
                {currentItems.length > 0
                  ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow>
                          <div className="">
                            <div className="user-card">
                                <UserAvatar icon="user-alt" className="sm" />

                                <div className="user-info">
                                <span className="tb-lead">
                                  {item.name}{" "}
                                  <span
                                    className={`dot dot-${
                                      item.status === "Active"
                                        ? "success"
                                        : item.status === "Pending"
                                          ? "warning"
                                          : "danger"
                                    } d-md-none ms-1`}
                                  ></span>
                                </span>
                                <span>{item.email}</span>
                              </div>
                            </div>
                          </div>
                        </DataTableRow>

                        <DataTableRow size="md">
                          <span className='text-uppercase text-primary'>{item.role}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                            {item?.data?.map((action)=>{
                                return (<Badge className='mx-1 badge-sm badge-dim '>{action.label}</Badge>)
                            })}
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.updated_at}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span
                            className={`tb-status text-${
                              item.status === "Active" ? "success" : item.status === "Pending" ? "warning" : "danger"
                            }`}
                          >
                            {item.status}
                          </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                              />
                            </li>
                            {item.status !== "Suspend" && (
                              <React.Fragment>
                                <li className="nk-tb-action-hidden" onClick={async () => await onDeleteClick(item.id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"Delete" + item.id}
                                    icon="trash"
                                    direction="top"
                                    text="Delete"
                                  />
                                </li>
                              </React.Fragment>
                            )}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                    {item.status !== "Suspend" && (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => onDeleteClick(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#Delete"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Delete</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    )}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                  : null}
              </DataTableBody>
              <div className="card-inner">
                {currentItems.length > 0 ? (
                  <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={totalItems}
                      paginate={paginate}
                      currentPage={currentPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </DataTable>
          </Block>
          <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
            <ModalBody>
              <a
                href="#close"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="p-2">
                <h5 className="title">Add New User</h5>
                <div className="mt-4">
                  <Form className="row gy-4" noValidate onSubmit={handleSubmit(onFormSubmit)}  autoComplete="off">
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Nom</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          defaultValue={formData.name}
                          placeholder="Enter name"
                          ref={register({ required: "This field is required" })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Email  </label>
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          defaultValue={formData.email}
                          placeholder="Enter email"
                          ref={register({
                            required: "This field is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            },
                          })}
                        />
                        {errors.email && <span className="invalid">{errors.email.message}</span>}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Password</label>
                        <input
                          className="form-control"
                          name="password"
                          defaultValue={formData.password}
                          placeholder="Enter Mot Pass"
                          ref={register({ required: "This field is required" })}
                        />
                          {errors.password && <span className="invalid">{errors.password.message}</span>}
                      </div>
                    </Col>
                    <Col md="6">
                        <div className="form-group">
                            <label className="form-label">Role</label>
                            <div className="form-control-wrap">
                                <RSelect
                                    name="role"
                                    options={roles}
                                    defaultValue={[]}
                                    ref={register({ required: "This field is required" })}

                                    onChange={(e) => {
                                        setFormData({ ...formData, role: e.value })
                                    }}
                                />
                                {errors.role && <span className="invalid">{errors.role.message}</span>}

                            </div>
                        </div>




                    </Col>
                      <Col md="12">
                          <div className="form-group">
                              <label className="form-label">Actions</label>
                              <RSelect
                                  isMulti
                                  name='actions'
                                  options={[
                                      {label:'Create',value:'create'},
                                      {label:'Delete',value:'delete'},
                                      {label:'Rotation',value:'rotation'},
                                      {label:'Filter Spam',value:'rotation_spam'},
                                      {label:'Rotation Auto',value:'rotation_auto'},
                                      {label:'users ',value:'users'},
                                      {label:'Detach ',value:'detach'},
                                      {label:'Attach',value:'attach'},
                                  ]}
                                  onChange={(e)=>{
                                      setFormData({...formData,actions:e})
                                  }}
                              />
                          </div>
                      </Col>
                    <Col md="12">
                      <div className="form-group">
                        <label className="form-label">Statut</label>
                        <div className="form-control-wrap">
                          <RSelect
                            options={filterStatus}
                            onChange={(e) => setFormData({ ...formData, status: e.value })}
                          />
                        </div>
                      </div>
                    </Col>
                      <Col md="12">
                          <div className="form-group">
                              <label className="form-label">Sponsor Account</label>
                              <RSelect  isMulti
                                        name='accounts'
                                        options={accounts.map((item)=>{ return {label:item.name,value:item.id}})}
                                        onChange={(e)=>{
                                            setFormData({...formData,accounts:e})

                                        }}
                              />
                          </div>
                      </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button color="primary" size="md" type="submit">
                              Add a user
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={(ev) => {
                              ev.preventDefault();
                              onFormCancel();
                            }}
                            className="link link-light"
                          >
                            Annuler
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Form>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="p-2">
                <h5 className="title">Update User </h5>
                <div className="mt-4">
                  <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}  autoComplete="off">

                      <Col md="6">
                          <div className="form-group">
                              <label className="form-label">Nom</label>
                              <input
                                  className="form-control"
                                  type="text"
                                  name="name"
                                  defaultValue={formData.name}
                                  placeholder="Enter name"
                                  ref={register({ required: "This field is required" })}
                              />
                              {errors.name && <span className="invalid">{errors.name.message}</span>}
                          </div>
                      </Col>
                      <Col md="6">
                          <div className="form-group">
                              <label className="form-label">Email  </label>
                              <input
                                  className="form-control"
                                  type="text"
                                  name="email"
                                  defaultValue={formData.email}
                                  placeholder="Enter email"
                                  ref={register({
                                      required: "This field is required",
                                      pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "invalid email address",
                                      },
                                  })}
                              />
                              {errors.email && <span className="invalid">{errors.email.message}</span>}
                          </div>
                      </Col>
                      <Col md="6">
                          <div className="form-group">
                              <label className="form-label">Password</label>
                              <input
                                  className="form-control"
                                  type="password"
                                  name="password"
                                  placeholder="Enter Mot Pass"
                                  ref={register()}
                              />
                              {errors.password && <span className="invalid">{errors.password.message}</span>}
                          </div>
                      </Col>
                      <Col md="6">
                          <div className="form-group">
                              <label className="form-label">Role</label>
                              <div className="form-control-wrap">
                                  <RSelect
                                      name="role"
                                      options={roles}
                                      defaultValue={[]}
                                      ref={register({ required: "This field is required" })}
                                      value={selectedOption}
                                      onChange={handleSelectChange}
                                  />
                                  {errors.role && <span className="invalid">{errors.role.message}</span>}

                              </div>
                          </div>


                      </Col>

                      <Col md="12">
                          <div className="form-group">
                              <label className="form-label">Statut</label>
                              <div className="form-control-wrap">
                                  <RSelect
                                      options={filterStatus}
                                      value={selectedStatus}

                                      onChange={handleSelectStatusChange}
                                  />
                              </div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="form-group">
                              <label className="form-label">Actions</label>
                              <RSelect
                                  isMulti
                                  name='actions'
                                  options={[
                                      {label:'Create',value:'create'},
                                      {label:'Delete',value:'delete'},
                                      {label:'Rotation',value:'rotation'},
                                      {label:'Filter Spam',value:'rotation_spam'},
                                      {label:'Rotation Auto',value:'rotation_auto'},
                                      {label:'users ',value:'users'},
                                      {label:'Detach ',value:'detach'},
                                      {label:'Attach',value:'attach'},
                                  ]}
                                  onChange={(e)=>{
                                      setFormData({...formData,actions:e})
                                  }}
                                  value={formData.actions}

                              />
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="form-group">
                              <label className="form-label">Sponsor Account</label>
                              <RSelect  isMulti
                                        name='accounts'
                                        options={accounts.map((item)=> {
                                            return  { value:item.id, label:item.name }
                                        })}
                                        value={selectedAccounts}

                                        onChange={handleSelectAccountsChange}
                              />
                          </div>
                      </Col>
                      <Col size="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                  <Button color="primary" size="md" type="submit">
                                      Update a user
                                  </Button>
                              </li>
                              <li>
                                  <a
                                      href="#cancel"
                                      onClick={(ev) => {
                                          ev.preventDefault();
                                          onFormCancel();
                                      }}
                                      className="link link-light"
                                  >
                                      Annuler
                                  </a>
                              </li>
                          </ul>
                      </Col>
                  </Form>
                </div>
              </div>
            </ModalBody>
          </Modal>

        </>:<Loader/>}
      </Content>
    </React.Fragment>
  );
};
export default User;
