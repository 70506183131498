import {createContext, useEffect, useState} from "react";
import Cookies from 'js-cookie';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const [auth, setAuth] = useState(() => {

        if (Cookies.get('user_info') && Cookies.get('access_token')) {
            const user_info = Cookies.get('user_info');
            const user = JSON.parse(user_info);
            const expires_in = Cookies.get('expires_in');
            const token_type = Cookies.get('token_type');
            const accessToken = Cookies.get('access_token');

            return accessToken ? {accessToken,user,token_type,expires_in} : {}
        }


    });

    useEffect(() => {
      //  Cookies.set('auth', JSON.stringify(auth));
    }, [auth]);


    return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;
