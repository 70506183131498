import { Card, Spinner } from "reactstrap";
import React from "react";


const LoaderTable = () =>{

    return (
        <>
            <div className="card-inner">
                <div className="text-center">
                    <span className="text-silent">
                         <Spinner style={{ width: "2rem", height: "2rem" }}  color="primary" />
                        <div className='text-uppercase fw-bold text-secondary mt-4 '>chargement des données</div>
                    </span>
                </div>
            </div>
        </>
    )
}

export default LoaderTable
