import axiosClient from '../api/axiosClient';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axiosClient.get('/refresh', {
      withCredentials: true
    });
      console.log('refresh response',response)


    setAuth(prev => {
     // console.log(JSON.stringify(prev));
     // console.log(response.data);
      return { ...prev, accessToken: response.data.access_token,expires_in:response.data.access_token }
    });
    return response.data.accessToken;
  }
    return {
        refresh
    }
};

export default useRefreshToken;
