import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../../components/block/Block";
import {
    Button,
    Col, Icon,
    Row,
} from "../../../components/Component";
import {
    Badge,
    Card,
    Modal, ModalBody,

} from "reactstrap";

import Loader from "../../../layout/loader/Loader";

import {Link} from "react-router-dom";
import ProtectedImage from "./ProtectedImage";

import DOMPurify from 'dompurify';
import useAccountApi from "../../../api/account/accountApi";



const DetaillesOffer = ({ match }) =>  {
    const {

        filter,
    } = useAccountApi(); //  use  custom hook
    let result='';
    const id = match.params.id;
    const type = match.params.type;
    const account = match.params.account;

    const [loading, setLoading] = useState(false);

    const [modal, setModal] = useState({
        html: false,
    });
    const [html_code, setHtmlCode] = useState();
    const [images, setImages] = useState([]);

    const [data,setData] = useState([]);
    const [emailData,setEmailData] = useState();
    const [subjectLines,setSubjectLines] = useState();
    const [emailfrom,setEmailfrom] = useState();
    const [user, setUser] = useState();
    const [apikey, setApikey] = useState();
    const [typeAccount,setTypeAccount] =useState(null)
    const onFormCancel = () => {
        setModal({
            html: false,
        });
        setEmailData(null)
        setSubjectLines(null)
        setEmailfrom(null)
    };

    useEffect(async () => {


        setTypeAccount(account)

        if(type ==='OfferA'){
            result = await filter({
                account:account,
                id:id,
                action:'detailles' ,
                type: 'ClixFlow',
            });
            if (result.success) {
                setData(result.data.data)

            }

            result = await filter({
                account:account,
                id:id,
                action:'detaillesEmail' ,
                type: 'ClixFlow',
            });
            if (result.success) {
                setApikey(result.data.apiKey)
                setEmailData(result.data.data)

            }

        }
        if(type ==='OfferB'){
            result = await filter({
                account:account,
                id:id,
                type: 'B2direct',
                action:'detailles' ,
            });
            if (result.success) {
                setData(result.data)


            }
        }
        if (type ==='Cx3ads'){

            result = await filter({
                account:account,
                type: 'Cx3ads',
                action:'detailles' ,

            });
            if (result.success) {

            }
        }


        setLoading(true)

    }, [match.params.id]);


    const params = {
        first_name: '{{first_name}}',
        last_name: '{{last_name}}',
        email: '{{email}}',
        street1: '{{street1}}',
        street2: '{{street2}}',
        city: '{{city}}',
        state_initials: '{{state_initials}}',
        postal: '{{postal}}',
        country: '{{country}}',
        gender: '{{gender}}',
        phone: '{{phone}}',
        date_of_birth: '{{date_of_birth}}',
    };


    async function copyToClipboard(textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport, so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    }
    return (
        <React.Fragment>
            <Head title="Manage Offers"></Head>

                {loading ? <>
                    { (type !=="OfferA")? //ClixFlow
                        (<Content>
                            <BlockHead size="sm">
                                <BlockBetween className="g-3">
                                    <BlockHeadContent>
                                        <BlockTitle page>
                                            Offer <strong className="text-primary small">{data.name}</strong>
                                        </BlockTitle>
                                        <BlockDes className="text-soft">
                                            <ul className="list-inline">
                                                <li>
                                                    Offer ID: <span className="text-base">{data?.network_offer_id}</span>
                                                </li>
                                                <li>
                                                    Network ID: <span className="text-base">{data?.network_id}</span>
                                                </li>
                                            </ul>
                                        </BlockDes>
                                    </BlockHeadContent>
                                    <BlockHeadContent>
                                        <Link to={`${process.env.PUBLIC_URL}/offers`}>
                                            <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                                                <Icon name="arrow-left"></Icon>
                                                <span>Back</span>
                                            </Button>
                                            <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                        </Link>
                                    </BlockHeadContent>
                                </BlockBetween>
                            </BlockHead>
                            <Block>
                                <Row className="gy-5">
                                    <Col lg="5">
                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5"> General </BlockTitle>
                                            </BlockHeadContent>
                                        </BlockHead>
                                        <Card className="card-bordered">
                                            <ul className="data-list is-compact">
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Offer ID</div>
                                                        <div className="data-value">{data?.network_offer_id}</div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Name</div>
                                                        <div className="data-value"> {data?.name} </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Category</div>
                                                        <div className="data-value"> {data?.relationship?.category?.name} </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Currency</div>
                                                        <div className="data-value">  {data?.payout} {data?.currency_id}  </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label"> Blocked Variables</div>
                                                        <div className="data-value"> N/A   </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label"> Tracking Link(s) </div>
                                                        <div className="data-value text-xs"><small className='text-primary'>{data?.tracking_url} </small>   </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-list-s2">
                                                        <div className="data-label">Description</div>
                                                        <div className="data-value mt-4">
                                                            <textarea cols='100'  rows="40" className="no-resize form-control" value={data.html_description}/>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Status</div>
                                                        <div className="data-value">
                                                            <Badge
                                                                size="sm"
                                                                color={
                                                                    data?.offer_status === "active"
                                                                        ? "outline-success"
                                                                        : data.offer_status === "Pending"
                                                                            ? "outline-info"
                                                                            : "outline-danger"
                                                                }
                                                                className="badge-dim"
                                                            >
                                                                {data?.offer_status }
                                                            </Badge>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Last Checked At</div>
                                                        <div className="data-value">{data?.time_created}</div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Created</div>
                                                        <div className="data-value">{data?.time_saved}</div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </Card>
                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5"> Email Instructions /  Optizmo Integration </BlockTitle>

                                            </BlockHeadContent>
                                        </BlockHead>

                                        <Card className="card-bordered">
                                            <ul className="data-list is-compact">
                                                <li className="data-item">
                                                    <div className="ata-list-s2">
                                                        <div className="data-label"> From Lines  </div>
                                                        <div className="data-value">
                                                            <textarea cols='100'  rows="10" className="no-resize form-control" value={data?.relationship?.email?.from_lines}/>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-list-s2">
                                                        <div className="data-label">Subject Lines </div>
                                                        <div className="data-value">
                                                            <div className="data-value mt-4">
                                                                <textarea cols='100'  rows="40" className="no-resize form-control" value={data?.relationship?.email?.subject_lines}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label"> Mailer Access Key </div>
                                                        <div className="data-value url">
                                                            {data?.relationship?.integrations?.optizmo?.mailer_access_key}

                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label"> Optout Link </div>
                                                        <div className="data-value url">
                                                            {data?.relationship?.integrations?.optizmo?.optout_link}

                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Card>
                                    </Col>

                                    <Col lg="7">
                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5"> Stats </BlockTitle>
                                            </BlockHeadContent>
                                        </BlockHead>
                                        <Card className="card-bordered">
                                            <table className="table table-ulogs">
                                                <thead className="bg-lighter">
                                                <tr>
                                                    <th className="tb-col-os">
                                                        <span className="overline-title">Revenue</span>
                                                    </th>
                                                    <th className="tb-col-ip">
                                                        <span className="overline-title"> Clicks </span>
                                                    </th>
                                                    <th className="tb-col-time">
                                                        <span className="overline-title"> CV </span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="overline-title"> CVR </span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="overline-title">  RPC  </span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="overline-title">  Invalid Click  </span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr key="">
                                                    <td className="tb-col-os">{data?.relationship?.reporting?.revenue}</td>
                                                    <td className="tb-col-ip">
                                                        <span className="sub-text">{data?.relationship?.reporting?.total_click}</span>
                                                    </td>
                                                    <td className="tb-col-time">
                                                        <span className="sub-text">{data?.relationship?.reporting?.cv}</span>
                                                    </td>
                                                    <td className="tb-col-action">
                                                        <span className="sub-text">{data?.relationship?.reporting?.cv}</span>
                                                    </td>
                                                    <td className="tb-col-action">
                                                        <span className="sub-text">{data?.relationship?.reporting?.cvr}</span>
                                                    </td>
                                                    <td className="tb-col-action">
                                                        <span className="sub-text">{data?.relationship?.reporting?.invalid_click}</span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>

                                        </Card>
                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5"> Payout </BlockTitle>
                                            </BlockHeadContent>
                                        </BlockHead>
                                        <Card className="card-bordered">
                                            <table className="table table-ulogs">
                                                <thead className="bg-lighter">
                                                <tr>
                                                    <th className="tb-col-os">
                                                        <span className="overline-title">Name</span>
                                                    </th>
                                                    <th className="tb-col-ip">
                                                        <span className="overline-title"> Payout </span>
                                                    </th>
                                                    <th className="tb-col-time">
                                                        <span className="overline-title">  Valid Between  </span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="overline-title">  Variables  </span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="overline-title">   Rules   </span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="overline-title">    Caps    </span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="overline-title">    Description    </span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(data?.relationship?.payouts?.entries.length)? data?.relationship?.payouts?.entries.map((item)=> {
                                                    return (<tr key={item.network_offer_payout_revenue_id}>
                                                        <td className="tb-col-os">Base</td>
                                                        <td className="tb-col-ip">
                                                            <span className="sub-text text-uppercase">{item.payout_type} {item.payout_amount} {data.currency_id}</span>
                                                        </td>
                                                        <td className="tb-col-time">
                                                            <span className="sub-text">---</span>
                                                        </td>
                                                        <td className="tb-col-time">
                                                            <span className="sub-text">N/A</span>
                                                        </td>
                                                        <td className="tb-col-time">
                                                            <span className="sub-text">None</span>
                                                        </td>
                                                        <td className="tb-col-time">
                                                            <span className="sub-text">---</span>
                                                        </td>
                                                    </tr>)
                                                }): <tr>
                                                    <td className='' colSpan={0}>Data not Found</td>
                                                </tr>}


                                                </tbody>
                                            </table>
                                        </Card>
                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5"> Targeting </BlockTitle>
                                            </BlockHeadContent>
                                        </BlockHead>
                                        <Card className="card-bordered">
                                            <ul className="data-list is-compact">
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Inclusions (Country) </div>
                                                        <div className="data-value">
                                                            United States
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Exclusions </div>
                                                        <div className="data-value">KID000844</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Card>
                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5"> Creatives </BlockTitle>
                                            </BlockHeadContent>
                                        </BlockHead>
                                        <Card className="card-bordered">
                                            <table className="table table-ulogs">
                                                <thead className="bg-lighter">
                                                <tr>
                                                    <th className="tb-col-os">
                                                        <span className="overline-title">ID</span>
                                                    </th>
                                                    <th className="tb-col-ip">
                                                        <span className="overline-title"> Name </span>
                                                    </th>
                                                    <th className="tb-col-time">
                                                        <span className="overline-title">  Type  </span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="overline-title">   Preview   </span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(data.relationship?.creatives?.entries.length)? data?.relationship?.creatives?.entries.map((item)=>{
                                                    return (
                                                        <tr key={item.network_offer_creative_id}>
                                                            <td className="tb-col-os">{item.network_offer_creative_id}</td>
                                                            <td className="tb-col-ip">
                                                                <span className="sub-text">{item.name}</span>
                                                            </td>
                                                            <td className="tb-col-time">
                                                                <span className="sub-text">{item.creative_type}</span>
                                                            </td>
                                                            <td className="tb-col-action">
                                                                {(item?.resource_url)? (<img src={item.resource_url}  alt={item.name}/>):(<Button className="btn btn-primary btn-sm " onClick={()=>{
                                                                    setHtmlCode(item.html_code)
                                                                    setModal({ html: true })
                                                                }}>PREVIEW</Button>) }
                                                            </td>
                                                        </tr>
                                                    )
                                                }): (<tr className='text-center'><td colSpan={4} className='tb-col-os'>Data not Found</td></tr>)}
                                                </tbody>
                                            </table>
                                        </Card>
                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5"> URLs </BlockTitle>
                                            </BlockHeadContent>
                                        </BlockHead>
                                        <Card className="card-bordered">
                                            <table className="table table-ulogs">
                                                <thead className="bg-lighter">
                                                <tr>
                                                    <th className="tb-col-os">
                                                        <span className="overline-title">ID</span>
                                                    </th>
                                                    <th className="tb-col-ip">
                                                        <span className="overline-title"> Name </span>
                                                    </th>
                                                    <th className="tb-col-time">
                                                        <span className="overline-title">   Preview URL   </span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(data?.relationship?.urls?.entries.length)?(data.relationship.urls.entries.map((item)=>{
                                                    return (<tr key={item.network_offer_url_id}>
                                                        <td className="tb-col-os">{item.network_offer_url_id}</td>
                                                        <td className="tb-col-ip">
                                                            <span className="sub-text">{(item.name ==="null")?'N/A':item.name}</span>
                                                        </td>
                                                        <td className="tb-col-time">
                                                            <span className="sub-text">{(item.preview_url!=="")?item.preview_url:"---"}</span>
                                                        </td>

                                                    </tr>)
                                                })):(<tr className='text-center'><td colSpan={3}>Data Not Found</td></tr>)}
                                                </tbody>
                                            </table>
                                        </Card>
                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5"> Caps </BlockTitle>
                                            </BlockHeadContent>
                                        </BlockHead>
                                        <Card className="card-bordered">
                                            <table className="table table-ulogs">
                                                <thead className="bg-lighter">
                                                <tr>
                                                    <th className="tb-col-os">
                                                        <span className="overline-title">Type</span>
                                                    </th>
                                                    <th className="tb-col-ip">
                                                        <span className="overline-title">  Daily  </span>
                                                    </th>
                                                    <th className="tb-col-time">
                                                        <span className="overline-title">    Weekly    </span>
                                                    </th>
                                                    <th className="tb-col-time">
                                                        <span className="overline-title">     Monthly     </span>
                                                    </th>
                                                    <th className="tb-col-time">
                                                        <span className="overline-title">      Global      </span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr key="">
                                                    <td className="tb-col-os">Click</td>
                                                    <td className="tb-col-ip">
                                                        <span className="sub-text">{data?.daily_click_cap}</span>
                                                    </td>
                                                    <td className="tb-col-time">
                                                        <span className="sub-text">{data?.weekly_click_cap}</span>
                                                    </td>
                                                    <td className="tb-col-time">
                                                        <span className="sub-text">{data?.monthly_click_cap}</span>
                                                    </td>
                                                    <td className="tb-col-time">
                                                        <span className="sub-text">{data?.global_click_cap}</span>
                                                    </td>
                                                </tr>
                                                <tr key="">
                                                    <td className="tb-col-os">Conversions</td>
                                                    <td className="tb-col-ip">
                                                        <span className="sub-text">{data?.weekly_conversion_cap}</span>
                                                    </td>
                                                    <td className="tb-col-time">
                                                        <span className="sub-text">{data?.weekly_conversion_cap}</span>
                                                    </td>
                                                    <td className="tb-col-time">
                                                        <span className="sub-text">{data?.monthly_conversion_cap}</span>
                                                    </td>
                                                    <td className="tb-col-time">
                                                        <span className="sub-text">{data?.global_conversion_cap}</span>
                                                    </td>
                                                </tr>
                                                <tr key="">
                                                    <td className="tb-col-os">Revenue</td>
                                                    <td className="tb-col-ip">
                                                        <span className="sub-text">{data?.daily_payout_cap}</span>
                                                    </td>
                                                    <td className="tb-col-time">
                                                        <span className="sub-text">{data?.weekly_payout_cap}</span>
                                                    </td>
                                                    <td className="tb-col-time">
                                                        <span className="sub-text">{data?.monthly_payout_cap}</span>
                                                    </td>
                                                    <td className="tb-col-time">
                                                        <span className="sub-text">{data?.global_payout_cap}</span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Card>
                                    </Col>
                                </Row>
                            </Block>
                        </Content>):
                        <Content>
                            <BlockHead size="sm">
                                <BlockBetween className="g-3">
                                    <BlockHeadContent>
                                        <BlockTitle page>
                                            Offer <strong className="text-primary small">{data.name}</strong>
                                        </BlockTitle>
                                        <BlockDes className="text-soft">
                                            <ul className="list-inline">
                                                <li>
                                                    Offer ID: <span className="text-base">{data.id}</span>
                                                </li>
                                                <li>
                                                    Stats: <span className="text-base">{data.stats}</span>
                                                </li>
                                            </ul>
                                        </BlockDes>
                                    </BlockHeadContent>
                                    <BlockHeadContent>
                                        <Link to={`${process.env.PUBLIC_URL}/`}>
                                            <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                                                <Icon name="arrow-left"></Icon>
                                                <span>Back</span>
                                            </Button>
                                            <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                        </Link>
                                    </BlockHeadContent>
                                </BlockBetween>
                            </BlockHead>
                            <Block>
                                <Row className="gy-5">
                                    <Col lg="5">
                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5">
                                                    General Info
                                                </BlockTitle>
                                            </BlockHeadContent>
                                        </BlockHead>
                                        <Card className="card-bordered">
                                            <ul className="data-list is-compact">
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Offer ID</div>
                                                        <div className="data-value">{data.id}</div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Name</div>
                                                        <div className="data-value">{data.name} </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Marketing Channels</div>
                                                        <div className="data-value"> {data?.marketing_channels?.map((item)=>{ return (<div>{item}</div>)})} </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Currency</div>
                                                        <div className="data-value">  {data?.currency?.name} ({data?.currency?.symbol})  </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Stats</div>
                                                        <div className="data-value">  {data?.stats}  </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Payout</div>
                                                        <div className="data-value">  {data?.payout} ({data?.currency?.symbol})/ {data?.unit}  </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label"> Availability</div>
                                                        <div className="data-value"> {data?.availability}   </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label"> Creatives <small>(Email/Texts/Banners)</small> </div>
                                                        <div className="data-value"> {data?.email_count} / {data?.text_count} /  {data?.banner_count}   </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label"> Type </div>
                                                        <div className="data-value text-xs"><small className='text-primary'>{data?.type} </small>   </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-list-s2">
                                                        <div className="data-label">Description</div>
                                                        <div className="data-value mt-4">
                                                            <textarea cols='100'  rows="10" className="no-resize form-control" value={data.description}/>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">Status</div>
                                                        <div className="data-value">
                                                            <Badge
                                                                size="sm"
                                                                color={
                                                                    data?.status ==="Active"
                                                                        ? "outline-success"
                                                                        : "outline-info"
                                                                }
                                                                className="badge-dim"
                                                            >
                                                                {data?.status }
                                                            </Badge>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">
                                                            Geo Targeting
                                                        </div>
                                                        <div className="data-value text-xs"><small className='text-primary'>Traffic is allowed from {data?.geo?.from} </small>   </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">
                                                            Optout Link
                                                        </div>
                                                        <div className="data-value text-xs"><small className='text-primary text-center text-sm'>{data?.optout} </small>   </div>
                                                    </div>
                                                </li>
                                                <li className="data-item">
                                                    <div className="data-col">
                                                        <div className="data-label">
                                                            Preview
                                                        </div>
                                                        <div className="data-value text-xs"><a href={data?.preview} target={"_blank"} className='text-primary'>Click here </a>   </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Card>
                                    </Col>
                                    <Col lg="7">
                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5"> Links  </BlockTitle>

                                            </BlockHeadContent>
                                        </BlockHead>

                                        <Card className="card-bordered">
                                            <table className="table table-ulogs">
                                                <thead className="bg-lighter">
                                                <tr>
                                                    <th className="tb-col-os">
                                                        <span className="overline-title">Name</span>
                                                    </th>
                                                    <th className="tb-col-os">
                                                        <span className="overline-title">Link</span>
                                                    </th>
                                                    <th className="tb-col-ip">
                                                        <span className="overline-title"> Copy </span>
                                                    </th>
                                                    <th className="tb-col-time">
                                                        <span className="overline-title"> Test </span>
                                                    </th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data?.redirect?.length ? data?.redirect.map((item,index)=>{
                                                    return ( <tr key={`url-${index}`}>
                                                        <td className="tb-col-os">{item.name}</td>
                                                        <td className="tb-col-os">{item.url}</td>

                                                        <td className="tb-col-time">
                        <span className="sub-text">
                          <Button className='btn btn-primary btn-xs'>Copy</Button>
                        </span>
                                                        </td>
                                                        <td className="tb-col-time">
                                                            <a href={item.url} target={"_blank"} className='btn btn-primary btn-xs'>Test</a>

                                                        </td>


                                                    </tr>)
                                                }):null}

                                                </tbody>
                                            </table>

                                        </Card>

                                        <BlockHead>
                                            <BlockHeadContent>
                                                <BlockTitle tag="h5"> Creatives </BlockTitle>
                                            </BlockHeadContent>
                                        </BlockHead>
                                        <Card className="card-bordered">
                                            <table className="table table-ulogs">
                                                <thead className="bg-lighter">
                                                <tr>
                                                    <th className="tb-col-os">
                                                        <span className="overline-title">ID</span>
                                                    </th>
                                                    <th className="tb-col-ip">
                                                        <span className="overline-title"> Name </span>
                                                    </th>
                                                    <th className="tb-col-time">
                                                        <span className="overline-title">  Type  </span>
                                                    </th>
                                                    <th className="tb-col-action">
                                                        <span className="overline-title">   Preview   </span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(emailData?.length)? emailData?.map((item)=>{
                                                    return (
                                                        <tr key={item.id}>
                                                            <td className="tb-col-os">{item.id}</td>
                                                            <td className="tb-col-ip">
                                                                <span className="sub-text">{item.description}</span>
                                                            </td>
                                                            <td className="tb-col-time">
                                                                <span className="sub-text">{item.type}</span>
                                                            </td>
                                                            <td className="tb-col-action">
                                                                <Button className="btn btn-primary btn-sm " onClick={()=>{
                                                                    setHtmlCode(item.body)
                                                                    setImages(item.images)
                                                                    setEmailfrom(item.from_lines)
                                                                    setSubjectLines(item.subjects)
                                                                    setModal({ html: true })
                                                                }}>PREVIEW</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }): (<tr className='text-center'><td colSpan={4} className='tb-col-os'>Data not Found</td></tr>)}
                                                </tbody>
                                            </table>
                                        </Card>
                                    </Col>
                                </Row>

                            </Block>
                        </Content>}

                    <Modal isOpen={modal.html} toggle={() => setModal({ html: false })} className="modal-dialog-centered" size="xl">
                        <ModalBody>
                            <a
                                href="#close"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    onFormCancel();
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">Offer Details </h5>
                                <div className='h-50 text-center'>
                                    <Button className='btn btn-primary btn-sm' onClick={()=>{
                                        copyToClipboard(html_code)
                                    }}>Copy Html</Button>
                                </div>
                            </div>
                            <hr/>
                            <div className="mt-4"  >
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html_code) }} />
                                <div className='my-2'>
                                    {(images.length > 0) ? images.map((item)=>{

                                        return (<div key={item.name}><ProtectedImage  imageUrl={item.url} imageName={item.name} bearerToken={apikey} /></div>
                                        )
                                    }): null}
                                </div>
                                <hr className='my-2'/>
                                {subjectLines ? ( <div>
                                    <label htmlFor="Subject_Lines">Subject Lines</label>
                                    <textarea cols='100' id="subjectLines" rows="10" className="no-resize form-control"  value={subjectLines?.map((item)=>{ return item.replace(/,/g, '')+'\n'})}/>
                                </div>):null}
                                {emailfrom ? (<div>
                                    <label htmlFor="">From</label>
                                    <textarea cols='100' id="from" rows="10" className="no-resize form-control"  value={emailfrom?.map((item)=>{ return (item+'\n').replace(/,/g, '')})} />
                                </div>):null}
                            </div>

                        </ModalBody>
                    </Modal>
                </>: <Loader/> }


        </React.Fragment>
    );
};

export default DetaillesOffer;
