import React, {  useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useForm } from "react-hook-form";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../../components/block/Block";
import {
    Button, Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon, PaginationComponent, RSelect, TooltipComponent
} from "../../../components/Component";
import {
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Modal,
    ModalBody,
    UncontrolledDropdown
} from "reactstrap";
import Loader from "../../../layout/loader/Loader";
import useActivityApi from "../../../api/activity/activityApi";

const Activity = () => {
    const {
        list,
    } = useActivityApi();


    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [users, setusers] = useState();
    const [sm, updateSm] = useState(false);

    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");

    const [sort, setSortState] = useState("desc");
    const [sortCol, setSorCol] = useState("id");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(100);
    const [totalItems , setTotalItems] = useState(100);
    const [currentItems, setIcurrentItems] = useState([]);
    const [indexOfLastItem, setindexOfLastItem] = useState(0);
    const [indexOfFirstItem, setindexOfFirstItem] = useState(0);

    const init =async () => {
        setLoading(true)

        const result = await list(itemPerPage, currentPage,onSearchText)
        if (result.success) {
            setData([...result.data.data])
            setCurrentPage(result.data.current_page)
            setItemPerPage(result.data.per_page)
            setIcurrentItems(result.data.data)
            setindexOfFirstItem(result.from)
            setindexOfLastItem(result.data.last_page)
            setTotalItems(result.data.total)
            setLoading(false)
        }
    }
    //  loads data
    useEffect(async () => {
        await init()
    }, [currentPage]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const { errors, register, handleSubmit } = useForm();
    const toggle = () => {
        setonSearch(!onSearch);
    };

    const fillterhandel = async () => {

        if (onSearchText !== "") {
            await init()
        }
    }
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };

    return (
        <React.Fragment>
            <Head title="Catégories des dépenses"></Head>
            <Content>
                {!loading? <>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle tag="h3" page>
                                    User Activities
                                </BlockTitle>
                                <BlockDes className="text-soft">
                                    <p></p>
                                </BlockDes>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>

                    <Block>
                        <DataTable className="card-stretch">
                            <div className="m-2 ">
                                <div className="card-title-group">
                                    <div className="card-title">
                                        <h5 className="title"></h5>
                                    </div>
                                    <div className="card-tools border-0 ">
                                        <ul className="btn-toolbar gx-1">
                                            <li>
                                                <Button
                                                    href="#search"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        toggle();
                                                    }}
                                                    className="btn-icon search-toggle toggle-search"
                                                >
                                                    <Icon name="search"></Icon>
                                                </Button>
                                            </li>
                                            <li className="btn-toolbar-sep"></li>

                                            <li>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle">
                                                        <Icon name="setting"></Icon>
                                                    </DropdownToggle>
                                                    <DropdownMenu end className="dropdown-menu-xs">
                                                        <ul className="link-check">
                                                            <li>
                                                                <span>Affichage</span>
                                                            </li>
                                                            <li className={itemPerPage === 10 ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setItemPerPage(10);
                                                                    }}
                                                                >
                                                                    10
                                                                </DropdownItem>
                                                            </li>
                                                            <li className={itemPerPage === 30 ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setItemPerPage(30);
                                                                    }}
                                                                >
                                                                    30
                                                                </DropdownItem>

                                                            </li>
                                                            <li className={itemPerPage === 50 ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setItemPerPage(50);
                                                                    }}
                                                                >
                                                                    50
                                                                </DropdownItem>

                                                            </li>
                                                            <li className={itemPerPage === 100 ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setItemPerPage(100);
                                                                    }}
                                                                >
                                                                    100
                                                                </DropdownItem>

                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>


                                        </ul>
                                    </div>


                                    <div className={`card-search search-wrap  ${!onSearch && "active"}`}>
                                        <div className="search-content">
                                            <Button
                                                onClick={() => {
                                                    setSearchText("");
                                                    toggle();
                                                }}
                                                className="search-back btn-icon toggle-search"
                                            >
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                            <input
                                                type="text"
                                                className="border-transparent form-focus-none form-control"
                                                placeholder="Recherche par Object ou User"
                                                value={onSearchText}
                                                onChange={(e) => onFilterChange(e)}
                                            />
                                            <Button className="search-submit btn-icon text-primary " outline="true"
                                                    onClick={async () => {
                                                        await fillterhandel()
                                                    }}>
                                                <Icon name="search"></Icon>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DataTableBody className="border-top" bodyclass="nk-tb-orders">
                                <DataTableHead>
                                    <DataTableRow>
                                        <span className="sub-text">ID</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Object</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Action</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">User</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Model</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Method</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Ip</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="">DATE</span>
                                    </DataTableRow>


                                    <DataTableRow className="">
                                        <span className="sub-text">Country / Region </span>

                                    </DataTableRow>
                                </DataTableHead>
                                {/*Head*/}
                                {currentItems.length > 0
                                    ? currentItems.map((item) => {
                                        return (
                                            <DataTableItem key={item.id}>
                                                <DataTableRow>
                                                    <span className="tb-lead text-primary">
                                                        #{item.id}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow>{item.subject}</DataTableRow>
                                                <DataTableRow>
                                                    <Badge className={`bg-${item.color}`}>
                                                        {item.status}
                                                    </Badge>
                                                </DataTableRow>
                                                <DataTableRow>{item?.user?.name}</DataTableRow>
                                                <DataTableRow>{item.model}</DataTableRow>

                                                <DataTableRow>{item.method}</DataTableRow>
                                                <DataTableRow>
                                                    <span className='icon-text'>
                                                          <em class="text-primary icon ni ni-globe"></em>
                                                    <span className='text-primary bold'>
                                                    {item.ip}
                                                    </span>
                                                    </span>

                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className='tb-sub tb-amount'>
                                                         {item.created_at}
                                                    </span>

                                                </DataTableRow>
                                                <DataTableRow>{item.countryName}/{item.regionName}</DataTableRow>

                                            </DataTableItem>
                                        );
                                    })
                                    : null}
                            </DataTableBody>
                            <div className="card-inner">
                                {currentItems.length > 0 ? (
                                    <PaginationComponent
                                        itemPerPage={itemPerPage}
                                        totalItems={totalItems}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <span className="text-silent">No data found</span>
                                    </div>
                                )}
                            </div>
                        </DataTable>
                    </Block>

                </>:<Loader/>}

            </Content>
        </React.Fragment>
    );
};

export default Activity;
