import React, {useEffect, useState} from "react";
import Head from "../../../layout/head/Head";
import {Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle} from "../../../components/block/Block";
import {
    Button, Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon, PaginationComponent, RSelect, TooltipComponent
} from "../../../components/Component";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Modal,
    ModalBody, Row,
    Tooltip,
    UncontrolledDropdown
} from "reactstrap";
import Loader from "../../../layout/loader/Loader";
import Content from "../../../layout/content/Content";
import {doesValueExist, location} from "../../../utils/Utils";
import RDnsSelect from "../../../components/select/ReverseDnsSelect";
import {useForm} from "react-hook-form";
import useLinodeApi from "../../../api/server/linodeApi";


const Linode = ()=>{
    const {
        list,
        store,
        update,
    } = useLinodeApi();

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);

    const [modal, setModal] = useState({
        edit: false,
        add: false,
        users: false,
    });
    const [formData, setFormData] = useState({
        id: "",
        team: "",
        location: "",
        ips: "",
        size: "",
    });

    const [loading, setLoading] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");

    const [data, setData] = useState();
    const [users, setusers] = useState();

    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [currentItems, setIcurrentItems] = useState([]);
    const [indexOfLastItem, setindexOfLastItem] = useState(0);
    const [indexOfFirstItem, setindexOfFirstItem] = useState(0);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const init = async () => {
        setLoading(false)
        const result = await list(itemPerPage, currentPage ,onSearchText)
        if (result.success) {
            setData([...result.data.data])
            setCurrentPage(result.data.current_page)
            setItemPerPage(result.data.per_page)
            setIcurrentItems(result.data.data)
            setindexOfFirstItem(result.from)
            setindexOfLastItem(result.data.last_page)
            setTotalItems(result.data.total)
            setLoading(true)

        }
    }

    useEffect(async () => {
        await init()
    }, [currentPage, itemPerPage]);

    const selectorCheck = (e) => {
       const  newData = currentItems.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setIcurrentItems([...newData]);
    };

    const onSelectChange = (e, id) => {
        let newData = currentItems;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setIcurrentItems([...newData]);
    };

    const toggle = () => {
        setonSearch(!onSearch);
    };

    const resetForm = () => {
        setFormData({

        });
        setModal({edit: false}, {add: false});

    };

    const onFormCancel = () => {
        setModal({
            edit: false,
            clock: false,
            add: false,
            ips: false,
            users: false,
        });

        resetForm();
    };


    const {errors, register, handleSubmit} = useForm();


    const onFormSubmit = async (submitData) => {
        console.log(submitData)
        let submittedData = {

            location: formData.location,
            ips: submitData.ips,
            team: submitData.team,
            type: 'linode',
            vmSize: formData.size,
        };

        const result = await store(submittedData)
        if (result.success) {
            await init()

        }
    };


    return (<>
        <Head title="Linode Azure"></Head>

        <Content>
            {loading ? <>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Linode
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>
                                    vous avez un total de {0 } Servers.
                                </p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content" style={{display: sm ? "block" : "none"}}>
                                    <ul className="nk-block-tools  g-1">

                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon btn-sm"
                                                    onClick={() => setModal({add: true})}>
                                                <Icon name="plus"></Icon>
                                            </Button>

                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block className='w-full'>
                    <DataTable className="card-stretch text-nowrap">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-tools">
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                                                    <ul className="btn-toolbar gx-1">

                                                        <li className="btn-toolbar-sep"></li>
                                                        <li>
                                                            <Button
                                                                href="#search"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    toggle();
                                                                }}
                                                                className="btn-icon search-toggle toggle-search"
                                                            >
                                                                <Icon name="search"></Icon>
                                                            </Button>
                                                        </li>

                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="a"
                                                                                className="btn btn-trigger btn-icon dropdown-toggle">
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        <li className={itemPerPage === 10 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 20 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)
                                                                                    setItemPerPage(20);
                                                                                }}
                                                                            >
                                                                                20
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 30 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)
                                                                                    setItemPerPage(30);
                                                                                }}
                                                                            >
                                                                                30
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 20 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)

                                                                                    setItemPerPage(50);
                                                                                }}
                                                                            >
                                                                                50
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 100 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)

                                                                                    setItemPerPage(100);
                                                                                }}
                                                                            >
                                                                                100
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 150 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)

                                                                                    setItemPerPage(150);
                                                                                }}
                                                                            >
                                                                                150
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 150 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)

                                                                                    setItemPerPage(500);
                                                                                }}
                                                                            >
                                                                                500
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 1000 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage(1)

                                                                                    setItemPerPage(1000);
                                                                                }}
                                                                            >
                                                                                1000
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"

                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Search by server "


                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody>
                            <DataTableHead>
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text">Name</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text">Status</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text">Status Vm</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text">Last Action </span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">Next Start</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">Next Stop</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text" size="sm">User</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">Location</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">Main Ip</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">IPs </span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">OS </span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">Size Vm </span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">Created At </span>
                                </DataTableRow>


                                <DataTableRow size="lg" className="nk-tb-col-tools text-end">

                                </DataTableRow>
                            </DataTableHead>
                            {/*Head*/}
                            {currentItems.length > 0
                                ? currentItems.map((item) => {
                                    return (
                                        <DataTableItem key={item.id}>
                                            <DataTableRow className="nk-tb-col-check">
                                                <div
                                                    className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={item.checked}
                                                        id={item.id + "uid1"}
                                                        key={Math.random()}
                                                        onChange={(e) => onSelectChange(e, item.id)}
                                                    />
                                                    <label className="custom-control-label"
                                                           htmlFor={item.id + "uid1"}></label>
                                                </div>
                                            </DataTableRow>
                                            <DataTableRow>
                                                  <span className="tb-amount">
                                                    {item?.name}
                                                  </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                  <span className={
                                                      item.status === "Power Off Deallocate" ||   item.status ===  'VM deallocated' ? 'tb-amount  text-danger text-uppercase' :
                                                          (item.status === "On power Off " || item.status === "Power Off ") ? 'tb-amount  text-danger text-uppercase' :
                                                              item.status === "In progress changing IPS" ? 'tb-amount  text-warning text-uppercase' :
                                                                  item.status === "Succeeded" ? 'tb-amount  text-success text-uppercase' :
                                                                      item?.status === 'Deleted' ? 'tb-amount  text-danger text-uppercase' :
                                                                          item?.status === 'being created' ? 'tb-amount  text-info text-uppercase' :
                                                                              'tb-amount  text-primary text-uppercase'}>
                                                    {item?.status}
                                                  </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                  <span className={
                                                      item.status_Vm === "Stopped Deallocate" ||   item.status_Vm ===  'VM deallocated' ? 'tb-amount  text-danger text-uppercase' :
                                                          (item.status_Vm === "On power Off " || item.status_Vm === "Power Off ") ? 'tb-amount  text-danger text-uppercase' :
                                                              item.status_Vm === "In progress changing IPS" ? 'tb-amount  text-warning text-uppercase' :
                                                                  item.status_Vm === "Succeeded"||   item.status_Vm === "VM running"||   item.status_Vm === "Running"? 'tb-amount  text-success text-uppercase' :
                                                                      item?.status_Vm === 'On delete' ? 'tb-amount  text-danger text-uppercase' :
                                                                          item?.status_Vm === 'being created' ? 'tb-amount  text-info text-uppercase' :
                                                                              'tb-amount  text-primary text-uppercase'}>
                                                    {item?.status_Vm}
                                                  </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                  <span className="tb-amount  text-primary text-uppercase">
                                                    {item?.action}
                                                  </span>
                                            </DataTableRow>
                                            <DataTableRow size="lg">
                                                <ul className="list-status">
                                                    <li>
                                                        <Icon
                                                            className={`text-success`}
                                                            name="alarm-alt"
                                                        ></Icon>{" "}
                                                        <span> {item?.start_at ?? ' -- -- -- --:--'}</span>
                                                    </li>

                                                </ul>
                                            </DataTableRow>
                                            <DataTableRow size="lg">
                                                <ul className="list-status">
                                                    <li>
                                                        <Icon
                                                            className={`text-warning`}
                                                            name="alarm-alt"
                                                        ></Icon>{" "}
                                                        <span> {item?.stop_at ?? ' -- -- -- --:--'}</span>
                                                    </li>

                                                </ul>
                                            </DataTableRow>
                                            <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.responsible?.name}
                                                  </span>
                                            </DataTableRow>
                                            <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.location}
                                                  </span>
                                            </DataTableRow>
                                            <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.main_ip}
                                                  </span>
                                            </DataTableRow>
                                            <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                      {item?.ips}
                                                  </span>
                                            </DataTableRow>
                                            <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.system}
                                                  </span>
                                            </DataTableRow>
                                            <DataTableRow size="lg">
                                                  <span className="tb-amount">
                                                    {item?.vmSize}
                                                  </span>
                                            </DataTableRow>
                                            <DataTableRow size="lg">
                                                <ul className="list-status">
                                                    <li>
                                                        <Icon
                                                            className={`text-success`}
                                                            name="alarm-alt"
                                                        ></Icon>{" "}
                                                        <span> {item?.created_at}</span>
                                                    </li>

                                                </ul>
                                            </DataTableRow>
                                            <DataTableRow size="lg" className="nk-tb-col-tools">

                                            </DataTableRow>
                                        </DataTableItem>
                                    );
                                })
                                : null}
                        </DataTableBody>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={totalItems}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
                <Modal isOpen={modal.add} toggle={() => setModal({add: false})} className="modal-dialog-centered"
                       size="lg">
                    <ModalBody>
                        <a
                            href="#close"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Add New Server Linode</h5>
                            <div className="mt-4">
                                <Form className="mt-4" onSubmit={handleSubmit(onFormSubmit)} noValidate>
                                    <Row className="g-gs mt-2">
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Name Team</label>
                                                <input
                                                    type="text"
                                                    name="team"
                                                    defaultValue={formData.team}
                                                    className="form-control"
                                                    ref={register({required: "This field is required"})}
                                                />
                                                {errors.team &&
                                                    <span className="invalid">{errors.team.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Count of IP Addresses</label>
                                                <input
                                                    type="text"
                                                    name="ips"
                                                    defaultValue={formData.ips}
                                                    className="form-control"
                                                    ref={register({required: "This field is required"})}
                                                />
                                                {errors.ips &&
                                                    <span className="invalid">{errors.ips.message}</span>}
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Location</label>
                                                <div className="form-control-wrap">
                                                    <RSelect
                                                        name='location'
                                                        options={location}
                                                        defaultValue={{
                                                            value: location.value,
                                                            label: location.label
                                                        }}
                                                        ref={register({required: "This field is required"})}

                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            location: e.value
                                                        })}
                                                    />
                                                </div>
                                                {errors.location &&
                                                    <span className="invalid">{errors.location.message}</span>}

                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group">
                                                <label className="form-label">Ram Size</label>
                                                <div className="form-control-wrap">
                                                    <RSelect
                                                        name='size'
                                                        ref={register({required: "This field is required"})}

                                                        defaultValue={[]}
                                                        options={[
                                                            {
                                                                value: 'Standard_B1ls',
                                                                label: 'Standard_B1ls (RAM 0.5 Go)'
                                                            },
                                                            {
                                                                value: 'Standard_B1s',
                                                                label: 'Standard_B1s (RAM 1Go)'
                                                            },
                                                            {
                                                                value: 'Standard_B2s',
                                                                label: 'Standard_B2s (RAM 4Go)'
                                                            },
                                                            {
                                                                value: 'Standard_B1ms',
                                                                label: 'Standard_B1ms  (RAM 2Go) '
                                                            },
                                                            {
                                                                value: 'Standard_DS1_v2',
                                                                label: 'Standard_DS1_v2  (RAM 3,5Go) '
                                                            },
                                                            {
                                                                value: 'Standard_F4s_v2',
                                                                label: 'Standard_F4s_v2  (RAM 8Go) '
                                                            },
                                                            {
                                                                value: 'Standard_B4ms',
                                                                label: 'Standard_B4ms  (RAM 8Go) '
                                                            },
                                                        ]}
                                                        onChange={(e) => setFormData({...formData, size: e.value})}
                                                    />

                                                    {errors.size &&
                                                        <span className="invalid">{errors.size.message}</span>}

                                                </div>
                                            </div>
                                        </Col>


                                    </Row>

                                    <Row className="gy-4 mt-2">

                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button color="primary" size="md" type="submit">
                                                        Add server
                                                    </Button>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#cancel"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            onFormCancel();
                                                        }}
                                                        className="link link-light"
                                                    >
                                                        Annuler
                                                    </a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Form>

                            </div>
                        </div>
                    </ModalBody>
                </Modal>


            </> : <Loader/>}
        </Content>
    </>)
}
export  default Linode
