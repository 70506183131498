import React, {useEffect, useMemo, useState} from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";

import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../../components/block/Block";
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead, DataTableItem, DataTableRow,
    Icon, PaginationComponent,
    Row,
    RSelect
} from "../../../components/Component";
import DatePicker from "react-datepicker";
import {
    Badge,
    Card,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal, ModalBody, Spinner,

    UncontrolledDropdown
} from "reactstrap";

import Loader from "../../../layout/loader/Loader";
import {
    convertDateTimeToMMDDYYHHMM,
    convertUnixTimestampToMMDDYY,
    notification,
    urlBackend
} from "../../../utils/Utils";
import useAccountApi from "../../../api/account/accountApi";
import {Link} from "react-router-dom";
import DOMPurify from "dompurify";
import ProtectedImage from "./ProtectedImage";
import LoaderTable from "../../../layout/loader/LoaderTable";
import Iframe from "../../../layout/Iframe/Iframe";
import Download from "../../../layout/download/download";
import Welcome from "../../../layout/welcome/Welcome";


const Offer = () => {
    const {
        list,
        filter,
        filteremail,
    } = useAccountApi(); //  use  custom hook
    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingOffer, setLoadingOffer] = useState(false);
    const [data, setData] = useState([]);
    const [meta, setMeta] = useState({
        categories:[],
        channels:[],
        countries:[],
        platforms:[],
        payout:[],
    });
    const [campaings, setCampaings] = useState([]);
    const [emailData, setEmailData] = useState();
    const [apikey, setApikey] = useState();
    const [html_code, setHtmlCode] = useState();
    const [iframeUrl, setiframeUrl] = useState();
    const [images, setImages] = useState([]);
    const [subjectLines, setSubjectLines] = useState();
    const [emailfrom, setEmailfrom] = useState();
    const [account, setAccount] = useState({});
    const [country, setCountry] = useState([]);
    const [categories, setCategories] = useState([]);
    const [channels, setChannels] = useState([]);
    const [listCountry, setListCountry] = useState([]);
    const [rangeStart, setRangeStart] = useState(new Date());
    const [rangeEnd, setRangeEnd] = useState(new Date());
    const [campaing, setCampaing] = useState('');
    const [category, setCategory] = useState('');
    let result = {};

    const [onSearch, setonSearch] = useState(true);
    const [searchOption, setSearchOption] = useState({
        category:null,
        country:null,
        channel:null,
        platefom:null,
        payout:'',
    });
    const [onSearchText, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(10);
    const [sort, setSortState] = useState("desc");
    const [sortCol, setSorCol] = useState("id");

    const [modal, setModal] = useState({
        Cx3ads: false,
        B2direct: false,
        ClixFlow: false,
        html: false,
    });
    const [offer, setOffer] = useState({});

    const clearStatu = () => {
        setCampaings([])

    }



    // unselects the data on mount
    useEffect(async () => {
        let result = await list()
        if (result.success) {
            setData(result.data.data)
        }
        setLoading(true)
    }, []);



    useEffect(async () => {
        if (account?.type === 'ClixFlow') {
            const storedListCountry = localStorage.getItem('ListCountry');

            if (storedListCountry) {
                setListCountry(JSON.parse(storedListCountry));
            } else {
                const result = await filter({
                    account: account,
                    action: 'countries',
                    type: 'ClixFlow',
                });

                if (result.success) {
                    const data = result.data.data;
                    setListCountry(data);

                    localStorage.setItem('ListCountry', JSON.stringify(data));
                }
            }
        }

    }, [account]);


    const ClixFlowInit = async () => {

        result = await filter({
            account: account,
            action: 'offer',
            type: 'ClixFlow',
            order: sortCol,
            direction: sort,
            search: onSearchText,
            country: country?.id ?? '',
            start_at_row: currentPage,
            row_limit: itemPerPage,
        });
        if (result.success) {
            clearStatu()
            setCampaings(result.data.data)
            setCurrentPage(result.data.meta.current_page)
            setItemPerPage(result.data.meta.per_page)
            setTotalItems(result.data.meta.total)

        }
    }
    const B2directInit = async () => {
        result = await filter({
            account: account,
            type: 'B2direct',
            action: 'offer',
            order: 'id',
            direction: sort,
            start_at_row: currentPage,
            row_limit: itemPerPage,
        });
        if (result.success) {
            clearStatu()
            setCampaings(result.data.offers)
            setCurrentPage(result.data.paging.page)
            setItemPerPage(result.data.paging.page_size)
            setTotalItems(result.data.paging.total_count)

        }
    }
    const Cx3adsInit = async () => {
        result = await filter({
            account: account,
            type: 'Cx3ads',
            action: 'offer',
            direction: 'asc',
            start_at_row: currentPage,
            row_limit: itemPerPage,
        });
        if (result.success) {
            clearStatu()
            setCurrentPage(currentPage)
            setItemPerPage(itemPerPage)
            setTotalItems(result.data.row_count)
            setCampaings(result.data.data)
        }
    }

    useEffect(async () => {
        setCampaings([])
        setLoadingTable(true)
        if (account?.type === 'ClixFlow') {
            await ClixFlowInit()
        }
        if (account?.type === 'B2direct') {
            await B2directInit()
        }
        if (account?.type === 'Cx3ads') {
            await Cx3adsInit()
        }
        setLoadingTable(false)
    }, [account, sort, country, currentPage, itemPerPage, sort])


    const NumberFormatEnUS = (number = 0) => {

        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(number);

    }

    const toggle = () => {
        setonSearch(!onSearch);
    };
    useEffect(async () => {

    }, []);

    const fillterhandel = async () => {
        await setCampaings([])
        if (onSearchText !== "") {

            if (account?.type === 'ClixFlow') {
                result = await filter({
                    account: account,
                    action: 'offer',
                    type: 'ClixFlow',
                    order: 'id',
                    direction: 'desc',
                    search: onSearchText,
                    start_at_row: currentPage,
                    row_limit: itemPerPage,
                });
                if (result.success) {
                    clearStatu()
                    setCampaings(result.data.data)
                    setCurrentPage(result.data.meta.current_page)
                    setItemPerPage(result.data.meta.per_page)
                    setTotalItems(result.data.meta.total)

                }
            }
            if (account?.type === 'B2direct') {
                result = await filter({
                    account: account,
                    type: 'B2direct',
                    action: 'offer',
                    order: 'id',
                    direction: 'desc',
                    search: onSearchText,
                    start_at_row: currentPage,
                    row_limit: itemPerPage,
                });
                if (result.success) {
                    clearStatu()
                    setCampaings(result.data.offers)
                    setCurrentPage(result.data.paging.page)
                    setItemPerPage(result.data.paging.page_size)
                    setTotalItems(result.data.paging.total_count)

                }
            }

            if (account?.type === 'Cx3ads') {
                result = await filter({
                    account: account,
                    type: 'Cx3ads',
                    action: 'offer',
                    search: onSearchText,
                    direction: 'asc',
                    start_at_row: currentPage,
                    row_limit: itemPerPage,
                });
                if (result.success) {
                    clearStatu()
                    setCurrentPage(currentPage)
                    setItemPerPage(itemPerPage)
                    setTotalItems(result.data.row_count)
                    setCampaings(result.data.data)
                }
            }


        } else {
            setCampaing([])

            if (account?.type === 'ClixFlow') {
                await ClixFlowInit()
            }
            if (account?.type === 'B2direct') {
                await B2directInit()
            }
            if (account?.type === 'Cx3ads') {
                await Cx3adsInit()
            }

        }

    }


    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };

    const paginate = (currentPage) => setCurrentPage(currentPage);
    const sortFunc = (params) => {

    };

    const GetUsers = async () => {


    }

    const selectorCheck = (e) => {
        let newData;
        newData = campaings?.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setCampaing([...newData]);
    };

    const onSelectChange = (e, id) => {

    };
    const offerDetailles = async (offer) => {
        setLoadingOffer(true)
        if (account?.type === 'ClixFlow') {
            setModal({ClixFlow: true})

            result = await filter({
                account: account,
                id: offer,
                action: 'detailles',
                type: 'ClixFlow',
            });
            if (result.success) {
                setOffer(result.data.data)

            }

            result = await filteremail({
                account: account,
                id: offer,
                action: 'detaillesEmail',
                type: 'ClixFlow',
            });
            if (result.success) {
                setApikey(result.data.apiKey)
                setEmailData(result.data.data)

            }


            setLoadingOffer(false)

        }
        if (account?.type === 'B2direct') {
            setModal({B2direct: true})
            result = await filter({
                account: account,
                id: offer,
                type: 'B2direct',
                action: 'detailles',
            });
            if (result.success) {
                setOffer(result.data)
                setLoadingOffer(false)

            }

        }
        if (account?.type === 'Cx3ads') {
            setModal({Cx3ads: true})
            result = await filter({
                account: account,
                type: 'Cx3ads',
                action: 'detailles',
                campaign_id: offer,

            });
            if (result.success) {
                if (result.data.success) {
                    setOffer(result.data.data[0])
                } else {
                    setModal({Cx3ads: false})
                    notification('error', 'Invalid Campaign')
                }
                setLoadingOffer(false)

            }
        }
    };


    async function copyToClipboard(textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport, so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    }


    const [subIdClixFlow, setSubIdClixFlow] = useState({
        c1: '',
        c2: '',
        c3: '',
    })
    const [subIdB2direct, setSubIdB2direct] = useState({
        sub1: '',
        sub2: '',
        sub3: '',
        sub4: '',
        sub5: '',
        source_id: '',
    })

    const [subIdCx3ads, setSubIdCx3ads] = useState({
        s1: '',
        s2: '',
        s3: '',
        s4: '',
        s5: '',
    })


    function generateURL(params, urlBase = '') {
        // Filter out empty values from the params object
        const filteredParams = Object.keys(params)
            .filter((key) => params[key] !== '' && params[key] !== undefined)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

        // Construct the URL with the filtered parameters
        const url = `${urlBase}?${filteredParams}`;

        return url;
    }

    function generateURLAddparam(params, urlBase = '') {
        const filteredParams = Object.keys(params)
            .filter((key) => params[key] !== '' && params[key] !== undefined)
            .map((key) => {
                if (key === "s1")
                    return `${encodeURIComponent(params[key])}`
                else
                    return `${key}=${encodeURIComponent(params[key])}`
            })
            .join('&');

        // Construct the URL with the filtered parameters
        const url = `${urlBase}${filteredParams}`;

        return url;
    }

    const downloadWithBearerToken = (item) => {


        const downloadUrl = `https://api.eflow.team/v1/affiliates/creatives/${item.network_offer_creative_id}/downloadhosted?format=package&offerId=${offer?.network_offer_id}`;

        fetch(downloadUrl, {
            method: 'GET',
            headers: {
                'x-eflow-api-key': `${account.apiKey}`
            }
        })
            .then(response => response.json())
            .then(response => {

                window.open(response.url, '_blank');

                // Create an anchor element and trigger the download

            })
            .catch(error => {
                // Handle fetch error (e.g., network issue)
                console.error('Network error:', error);
                // You may want to show an error message to the user here
            });
    }

    const handleSort = (column) => {
        if (sort === 'desc') setSortState('asc');
        else setSortState('desc')
        setSorCol(column)
    };

    const getSettings = async () => {
        //B2direct
        const storedMeta = JSON.parse(localStorage.getItem('meta')) ;
        if (storedMeta?.categories?.length) {
            setMeta(storedMeta);
        } else {


        if (account?.type === 'B2direct') {
            result = await filter({
                account: account,
                type: 'B2direct',
                action: 'categories',
            });
            if (result.success) {
                setMeta({...meta, categories: result.data.categories})
                setMeta((prevMeta) => ({...prevMeta, categories: result.data.categories || []}));
            }
            result = await filter({
                account: account,
                type: 'B2direct',
                action: 'channels',
            });
            if (result.success) {
                setMeta((prevMeta) => ({...prevMeta, channels: result.data.channels || []}));

            }
            result = await filter({
                account: account,
                type: 'B2direct',
                action: 'platforms',
            });
            if (result.success) {
                setMeta((prevMeta) => ({...prevMeta, platforms: result.data.platforms || []}));

            }
            result = await filter({
                account: account,
                type: 'B2direct',
                action: 'countries',
            });
            if (result.success) {
              await  setMeta((prevMeta) => ({...prevMeta, countries: result.data.countries || []}));

            }


        }


        }




    }






    useEffect(async ()=>{
        console.log(searchOption)
    },[searchOption])

    const      onClickFilter = async () => {
        setCampaings([])

        setLoadingTable(true)
        if (account?.type === 'B2direct') {
            result = await filter({
                account: account,
                type: 'B2direct',
                action: 'offer',
                order: 'id',
                direction: sort,
                start_at_row: currentPage,
                row_limit: itemPerPage,
                filter:searchOption
            });
            if (result.success) {
                clearStatu()
                setCampaings(result.data.offers)
                setCurrentPage(result.data.paging.page)
                setItemPerPage(result.data.paging.page_size)
                setTotalItems(result.data.paging.total_count)

            }
            setLoadingTable(false)

        }
    }

    return (
        <React.Fragment>
            <Head title="Manage Offers"></Head>
            <Content>
                {loading ? <>
                    <Modal isOpen={modal.html} toggle={() => setModal({...modal, html: false})}
                           className="modal-dialog-centered" size="xl">
                        <ModalBody>
                            <a
                                href="#close"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal({...modal, html: false})
                                }}
                                className="close"
                            >
                                <Icon name="cross-sm"></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">Offer Details </h5>

                                <div className='h-50 text-center'>

                                    <Button className='btn btn-primary btn-sm' onClick={() => {
                                        copyToClipboard(html_code)
                                    }}>Copy Html</Button>
                                </div>


                            </div>

                            <div className="my-4">
                                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(html_code)}}/>
                                <div className='my-2'>
                                    {(images.length > 0) ? images.map((item) => {

                                        return (<div key={item.name}><ProtectedImage imageUrl={item.url}
                                                                                     imageName={item.name}
                                                                                     bearerToken={apikey}/></div>
                                        )
                                    }) : null}
                                </div>

                                {subjectLines ? (<div>
                                    <label htmlFor="Subject_Lines">Subject Lines</label>
                                    <textarea cols='100' id="subjectLines" rows="10" className="no-resize form-control"
                                              value={subjectLines?.map((item) => {
                                                  return item.replace(/,/g, '') + '\n'
                                              })}/>
                                </div>) : null}
                                {emailfrom ? (<div>
                                    <label htmlFor="">From</label>
                                    <textarea cols='100' id="from" rows="10" className="no-resize form-control"
                                              value={emailfrom?.map((item) => {
                                                  return (item + '\n').replace(/,/g, '')
                                              })}/>
                                </div>) : null}
                            </div>

                            {account?.type === 'Cx3ads' ? <Iframe iframeUrl={iframeUrl}/> : null}

                        </ModalBody>
                    </Modal>

                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle page>Manage
                                    Offers {account?.name ? ` ( ${account?.name} ) ` : ''}</BlockTitle>
                                <BlockDes className="text-soft">
                                    <p>Manage Offers.</p>
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <ul className="nk-block-tools g-3">
                                    <li className="nk-block-tools-opt ">
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="transparent"
                                                            className="btn btn-white btn-outline-light dropdown-toggle">
                                                <Icon name="building"/>
                                                <span>{account?.name ? account?.name : "Accounts"}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <ul className="link-list-opt no-bdr">
                                                    {data?.map((item) => {
                                                        return (<li key={Math.random()}
                                                        >
                                                            <DropdownItem
                                                                tag="a"
                                                                href={`#${item.name}`}
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setAccount(item);
                                                                    clearStatu()

                                                                }}
                                                            >
                                                                <Icon name="building"></Icon>
                                                                <span>{item.name}</span>
                                                            </DropdownItem>
                                                        </li>);
                                                    })}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                    {
                                        (account?.type === 'ClixFlow') ? <>
                                            <li className="nk-block-tools-opt ">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle color="transparent"
                                                                    className="btn btn-white btn-outline-light dropdown-toggle">
                                                        <Icon name="list-thumb-alt"/>
                                                        <span>{country?.name ? country?.name : "Country "}</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <div className="scrollable-menu">
                                                            <ul className="link-list-opt no-bdr">
                                                                {listCountry?.map((item) => (
                                                                    <li key={item.name}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href={`#${item.name}`}
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                if (item?.id === 228)
                                                                                    setCountry({});
                                                                                else setCountry(item);

                                                                            }}
                                                                        >
                                                                            <Icon name="list-thumb-alt"/>
                                                                            <span>{item.name}</span>
                                                                        </DropdownItem>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>

                                            </li>

                                        </> : null
                                    }


                                    <li className="d-none">
                                        <div className="form-group">
                                            <div className="form-control-wrap">
                                                <div className="input-daterange date-picker-range input-group">
                                                    <DatePicker
                                                        selected={rangeStart}
                                                        onChange={setRangeStart}
                                                        selectsStart
                                                        startDate={rangeStart}
                                                        endDate={rangeEnd}
                                                        wrapperClassName="start-m"
                                                        className="form-control"
                                                    />{" "}
                                                    <div className="input-group-addon">TO</div>
                                                    <DatePicker
                                                        selected={rangeEnd}
                                                        onChange={setRangeEnd}
                                                        startDate={rangeStart}
                                                        endDate={rangeEnd}
                                                        selectsEnd
                                                        minDate={rangeStart}
                                                        wrapperClassName="end-m"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    <li className="d-none">

                                        <input className="form-control form-control-sm"
                                               onChange={(e) => setCampaing(e.target.value)}
                                               placeholder='Search Campaigns...'/>
                                    </li>
                                    <li className="d-none">

                                        <input className="form-control form-control-sm"
                                               onChange={(e) => setCategory(e.target.value)}
                                               placeholder='Filter by Category...'/>
                                    </li>

                                    <li className="d-none">

                                        <a
                                            id='id'
                                            href="#filter"
                                            onClick={() => {

                                            }}
                                            className="btn btn-sm btn-white btn-outline-light"
                                        >
                                            <Icon name="reload-alt"/>
                                            <span>Run</span>
                                        </a>
                                    </li>
                                </ul>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                    {(account?.type === 'ClixFlow') ? <>
                            <Block>
                                <DataTable>
                                    <div className="m-2 ">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h5 className="title"></h5>
                                            </div>
                                            <div className="card-tools border-0 ">
                                                <ul className="btn-toolbar gx-1">
                                                    <li>
                                                        <Button
                                                            href="#search"
                                                            onClick={(ev) => {
                                                                ev.preventDefault();
                                                                toggle();
                                                            }}
                                                            className="btn-icon search-toggle toggle-search"
                                                        >
                                                            <Icon name="search"></Icon>
                                                        </Button>
                                                    </li>
                                                    <li className="btn-toolbar-sep"></li>

                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle tag="a"
                                                                            className="btn btn-trigger btn-icon dropdown-toggle">
                                                                <Icon name="setting"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu end className="dropdown-menu-xs">
                                                                <ul className="link-check">
                                                                    <li>
                                                                        <span>Affichage</span>
                                                                    </li>
                                                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setItemPerPage(10);
                                                                            }}
                                                                        >
                                                                            10
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li className={itemPerPage === 30 ? "active" : ""}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setItemPerPage(30);
                                                                            }}
                                                                        >
                                                                            30
                                                                        </DropdownItem>

                                                                    </li>
                                                                    <li className={itemPerPage === 50 ? "active" : ""}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setItemPerPage(50);
                                                                            }}
                                                                        >
                                                                            50
                                                                        </DropdownItem>

                                                                    </li>
                                                                    <li className={itemPerPage === 100 ? "active" : ""}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setItemPerPage(100);
                                                                            }}
                                                                        >
                                                                            100
                                                                        </DropdownItem>

                                                                    </li>
                                                                </ul>
                                                                <ul className="link-check">
                                                                    <li>
                                                                        <span>Order</span>
                                                                    </li>
                                                                    <li className={"sort" === "dsc" ? "active" : ""}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setSortState("dsc");
                                                                                sortFunc("dsc");
                                                                            }}
                                                                        >
                                                                            DESC
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li className={"sort" === "asc" ? "active" : ""}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setSortState("asc");
                                                                                sortFunc("asc");
                                                                            }}
                                                                        >
                                                                            ASC
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>


                                                </ul>
                                            </div>


                                            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                                <div className="search-content">
                                                    <Button
                                                        onClick={() => {
                                                            setSearchText("");
                                                            toggle();
                                                        }}
                                                        className="search-back btn-icon toggle-search"
                                                    >
                                                        <Icon name="arrow-left"></Icon>
                                                    </Button>
                                                    <input
                                                        type="text"
                                                        className="border-transparent form-focus-none form-control"
                                                        placeholder="Recherche par Nom ou Description"
                                                        value={onSearchText}
                                                        onChange={(e) => onFilterChange(e)}
                                                    />
                                                    <Button className="search-submit btn-icon text-primary " outline="true"
                                                            onClick={async () => {
                                                                await fillterhandel()
                                                            }}>
                                                        <Icon name="search"></Icon>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <DataTableBody className="border-top">
                                        <DataTableHead>

                                            <DataTableRow className="nk-tb-col-check">
                                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={(e) => selectorCheck(e)}
                                                        id="uid"
                                                    />
                                                    <label className="custom-control-label" htmlFor="uid"></label>
                                                </div>
                                            </DataTableRow>
                                            <DataTableRow className="nk-tb-orders-type">
                                                <span className="tb-lead " onClick={() => handleSort('id')}
                                                      role="button">
                                                    <span className='px-1'>
                                                        <span
                                                            className={sortCol === 'id' && sort === 'desc' ? 'text-primary fs-6 fw-bold' : ''}>&darr;</span>
                                                        <span
                                                            className={sortCol === 'id' && sort === 'asc' ? 'text-primary fs-6 fw-bold' : ''}>&uarr;</span>
                                                    </span>

                                                    Id
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                     <span className="tb-lead " onClick={() => handleSort('name')}
                                                           role="button">
                                                    <span className='px-1'>
                                                        <span
                                                            className={sortCol === 'name' && sort === 'desc' ? 'text-primary fs-6 fw-bold' : ''}>&darr;</span>
                                                        <span
                                                            className={sortCol === 'name' && sort === 'asc' ? 'text-primary fs-6 fw-bold' : ''}>&uarr;</span>
                                                    </span>

                                                    Name
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                  <span className="tb-lead " onClick={() => handleSort('payout')}
                                                        role="button">
                                                    <span className='px-1'>
                                                        <span
                                                            className={sortCol === 'payout' && sort === 'desc' ? 'text-primary fs-6 fw-bold' : ''}>&darr;</span>
                                                        <span
                                                            className={sortCol === 'payout' && sort === 'asc' ? 'text-primary fs-6 fw-bold' : ''}>&uarr;</span>
                                                    </span>

                                                    Payout
                                                </span>
                                            </DataTableRow>

                                            <DataTableRow>
                                                 <span className="tb-lead " onClick={() => handleSort('id')}
                                                       role="button">
                                                    <span className='px-1'>
                                                        <span
                                                            className={sortCol === 'id' && sort === 'desc' ? 'text-primary fs-6 fw-bold' : ''}>&darr;</span>
                                                        <span
                                                            className={sortCol === 'id' && sort === 'asc' ? 'text-primary fs-6 fw-bold' : ''}>&uarr;</span>
                                                    </span>

                                                    Suppression
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-lead " onClick={() => handleSort('id')}
                                                      role="button">
                                                    <span className='px-1'>
                                                        <span
                                                            className={sortCol === 'id' && sort === 'desc' ? 'text-primary fs-6 fw-bold' : ''}>&darr;</span>
                                                        <span
                                                            className={sortCol === 'id' && sort === 'asc' ? 'text-primary fs-6 fw-bold' : ''}>&uarr;</span>
                                                    </span>

                                                    Geo
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                 <span className="tb-lead " onClick={() => handleSort('id')}
                                                       role="button">
                                                    <span className='px-1'>
                                                        <span
                                                            className={sortCol === 'id' && sort === 'desc' ? 'text-primary fs-6 fw-bold' : ''}>&darr;</span>
                                                        <span
                                                            className={sortCol === 'id' && sort === 'asc' ? 'text-primary fs-6 fw-bold' : ''}>&uarr;</span>
                                                    </span>

                                                    Marketing Channels
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                 <span className="tb-lead " onClick={() => handleSort('id')}
                                                       role="button">
                                                    <span className='px-1'>
                                                        <span
                                                            className={sortCol === 'id' && sort === 'desc' ? 'text-primary fs-6 fw-bold' : ''}>&darr;</span>
                                                        <span
                                                            className={sortCol === 'id' && sort === 'asc' ? 'text-primary fs-6 fw-bold' : ''}>&uarr;</span>
                                                    </span>

                                                    Status
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                  <span className="tb-lead " onClick={() => handleSort('availability')}
                                                        role="button">
                                                    <span className='px-1'>
                                                        <span
                                                            className={sortCol === 'availability' && sort === 'desc' ? 'text-primary fs-6 fw-bold' : ''}>&darr;</span>
                                                        <span
                                                            className={sortCol === 'availability' && sort === 'asc' ? 'text-primary fs-6 fw-bold' : ''}>&uarr;</span>
                                                    </span>

                                                    Availability
                                                </span>
                                            </DataTableRow>
                                        </DataTableHead>
                                        {campaings?.length ? campaings?.map((item) => {
                                            return (
                                                <DataTableItem key={Math.random()}>
                                                    <DataTableRow className="nk-tb-col-check">
                                                        <div
                                                            className="custom-control custom-control-sm custom-checkbox notext">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                defaultChecked={item?.checked}
                                                                id={item.id + "uid1"}
                                                                key={Math.random()}
                                                                onChange={(e) => onSelectChange(e, item.id)}
                                                            />
                                                            <label className="custom-control-label"
                                                                   htmlFor={item.id + "uid1"}></label>
                                                        </div>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="tb-lead">{item.id}</span>
                                                    </DataTableRow>

                                                    <DataTableRow>
                                                        <a
                                                            href="#offerDetailles"
                                                            onClick={async (ev) => {
                                                                ev.preventDefault();
                                                                await offerDetailles(item?.id);
                                                            }}

                                                        >
                                                            <span className="tb-lead text-primary">{item?.name}</span>

                                                        </a>

                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span
                                                            className="tb-lead">{item.payout} {item?.currency?.symbol} {item.unit}</span>
                                                    </DataTableRow>

                                                    <DataTableRow size='xs'>
                                                        <span
                                                            className="tb-lead">{item?.suppression?.suppression_type}</span>
                                                    </DataTableRow>
                                                    <DataTableRow size='xs'>
                                                        <span className="tb-lead">{item?.geo?.from}</span>
                                                    </DataTableRow>
                                                    <DataTableRow size='xs'>
                                                        <span
                                                            className="tb-lead">{item?.marketing_channels?.map((item) => item)}</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="tb-lead">
                                                            {item?.status === "Active" ? <>
                                                                <span
                                                                    className='badge-dim badge bg-outline-success'>{item.status} </span>
                                                            </> : <>
                                                                <span
                                                                    className='badge-dim badge bg-outline-danger'>{item.status} </span>
                                                            </>}
                                                        </span>
                                                    </DataTableRow>

                                                    <DataTableRow>
                                                          <span className="tb-lead">
                                                            {item?.availability === "Approved" ? <>
                                                                <span
                                                                    className='badge-dim badge bg-outline-success'>{item.availability} </span>
                                                            </> : <>
                                                                <span
                                                                    className='badge-dim badge bg-outline-danger'>{item.availability} </span>
                                                            </>}
                                                        </span>
                                                    </DataTableRow>

                                                </DataTableItem>
                                            );
                                        }) : null}
                                    </DataTableBody>
                                    {campaings?.length <= 0 && !loadingTable ? <>
                                        <div className="card-inner">
                                            <div className="text-center">
                                                <span className="text-silent">
                                                    <div className='text-uppercase fw-bold text-secondary m-1 '>No data found </div>
                                                </span>
                                            </div>
                                        </div>
                                    </> : campaings?.length > 0 && !loadingTable ? null : <LoaderTable/>}
                                    {campaings?.length ? <div className="card-inner">
                                        <PaginationComponent
                                            itemPerPage={itemPerPage}
                                            totalItems={totalItems}
                                            paginate={paginate}
                                            currentPage={currentPage}
                                        />
                                    </div> : null}


                                </DataTable>
                                <Modal isOpen={modal.ClixFlow} toggle={() => setModal({ClixFlow: false})}
                                       className="modal-dialog-centered" size="xl" style={{width: "80%", minWidth: '80%'}}>
                                    <ModalBody>
                                        <a
                                            href="#close"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModal({ClixFlow: false})
                                            }}
                                            className="close"
                                        >
                                            <Icon name="cross-sm"></Icon>
                                        </a>
                                        {
                                            !loadingOffer ? <>
                                                <Row className="gy-5">
                                                    <Col lg="5">
                                                        <BlockHead>
                                                            <BlockHeadContent>
                                                                <BlockTitle tag="h5">
                                                                    General Info
                                                                </BlockTitle>
                                                            </BlockHeadContent>
                                                        </BlockHead>
                                                        <Card className="card-bordered">
                                                            <ul className="data-list is-compact">
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label">Offer ID</div>
                                                                        <div className="data-value">{offer.id}</div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label">Name</div>
                                                                        <div className="data-value">{offer.name} </div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label">Marketing Channels</div>
                                                                        <div
                                                                            className="data-value"> {offer?.marketing_channels?.map((item) => {
                                                                            return (<div>{item}</div>)
                                                                        })} </div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label">Status</div>
                                                                        <div className="data-value">
                                                                            <Badge
                                                                                size="sm"
                                                                                color={
                                                                                    offer?.status === "Active"
                                                                                        ? "outline-success"
                                                                                        : "outline-info"
                                                                                }
                                                                                className="badge-dim"
                                                                            >
                                                                                {offer?.status}
                                                                            </Badge>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label">
                                                                            Geo Targeting
                                                                        </div>
                                                                        <div className="data-value text-xs fw-bolder"><small
                                                                            className='text-primary'>Traffic is allowed
                                                                            from {offer?.geo?.from} </small></div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label">Currency</div>
                                                                        <div
                                                                            className="data-value">  {offer?.currency?.name} ({offer?.currency?.symbol})
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label">Stats</div>
                                                                        <div className="data-value">  {offer?.stats}  </div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label">Payout</div>
                                                                        <div
                                                                            className="data-value">  {offer?.payout} ({offer?.currency?.symbol})/ {offer?.unit}  </div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label"> Availability</div>
                                                                        <div
                                                                            className="data-value"> {offer?.availability}   </div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div
                                                                            className="data-label"> Creatives <small>(Email/Texts/Banners)</small>
                                                                        </div>
                                                                        <div
                                                                            className="data-value"> {offer?.email_count} / {offer?.text_count} / {offer?.banner_count}   </div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label"> Type</div>
                                                                        <div className="data-value text-xs"><small
                                                                            className='text-primary'>{offer?.type} </small>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-list-s2">
                                                                        <div className="data-label">Description</div>
                                                                        <div className="data-value mt-4">
                                                                            <textarea cols='100' rows="10"
                                                                                      className="no-resize form-control"
                                                                                      value={offer.description}/>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label">
                                                                            Optout Link
                                                                            <Button className='btn btn-primary btn-xs mx-2'
                                                                                    onClick={async () => {
                                                                                        await copyToClipboard(
                                                                                            `${offer?.optout}`
                                                                                        )
                                                                                    }}>Copy</Button>
                                                                        </div>

                                                                        <div className="data-value text-xs"><small
                                                                            className='text-primary text-center text-sm'>{offer?.optout} </small>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="data-item">
                                                                    <div className="data-col">
                                                                        <div className="data-label">
                                                                            Preview
                                                                            <Button className='btn btn-primary btn-xs mx-2'
                                                                                    onClick={async () => {
                                                                                        await copyToClipboard(
                                                                                            `${offer?.preview}`
                                                                                        )
                                                                                    }}>Copy</Button>
                                                                        </div>
                                                                        <div className="data-value text-xs"><a
                                                                            href={offer?.preview} target={"_blank"}
                                                                            className='text-primary  text-sm'>{offer?.preview}  </a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </Card>
                                                    </Col>
                                                    <Col lg="7">
                                                        <BlockHead>
                                                            <BlockHeadContent>
                                                                <BlockTitle tag="h5"> SubID </BlockTitle>

                                                            </BlockHeadContent>
                                                        </BlockHead>
                                                        <Card className="card-bordered">
                                                            <table className="table table-ulogs">
                                                                <thead className="bg-lighter">
                                                                <tr>
                                                                    <th className="tb-col-os">
                                                                        <span className="overline-title">SubID 1 (C1)</span>
                                                                    </th>
                                                                    <th className="tb-col-os">
                                                                        <span className="overline-title">SubID 2 (C2)</span>
                                                                    </th>
                                                                    <th className="tb-col-ip">
                                                                        <span
                                                                            className="overline-title"> SubID 2 (C3) </span>
                                                                    </th>

                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <td className="tb-col-os">
                                                                    <div className="form-group">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="name"
                                                                            defaultValue={subIdClixFlow.c1}
                                                                            onChange={(e) => {
                                                                                setSubIdClixFlow({
                                                                                    ...subIdClixFlow,
                                                                                    c1: e.target.value
                                                                                })
                                                                            }}
                                                                            placeholder="Enter SubID 1 (C1)"
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="tb-col-os">
                                                                    <div className="form-group">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="name"
                                                                            defaultValue={subIdClixFlow.c2}

                                                                            onChange={(e) => {
                                                                                setSubIdClixFlow({
                                                                                    ...subIdClixFlow,
                                                                                    c2: e.target.value
                                                                                })
                                                                            }}

                                                                            placeholder="Enter SubID 2 (C2)"
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="tb-col-os">
                                                                    <div className="form-group">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="name"
                                                                            defaultValue={subIdClixFlow.c3}
                                                                            onChange={(e) => {
                                                                                setSubIdClixFlow({
                                                                                    ...subIdClixFlow,
                                                                                    c3: e.target.value
                                                                                })
                                                                            }}

                                                                            placeholder="Enter SubID 3 (C3)"
                                                                        />
                                                                    </div>
                                                                </td>

                                                                </tbody>
                                                            </table>

                                                        </Card>
                                                        <BlockHead>
                                                            <BlockHeadContent>
                                                                <BlockTitle tag="h5"> Links </BlockTitle>

                                                            </BlockHeadContent>
                                                        </BlockHead>
                                                        <Card className="card-bordered">
                                                            <table className="table table-ulogs">
                                                                <thead className="bg-lighter">
                                                                <tr>
                                                                    <th className="tb-col-os">
                                                                        <span className="overline-title">Name</span>
                                                                    </th>
                                                                    <th className="tb-col-os">
                                                                        <span className="overline-title">Link</span>
                                                                    </th>
                                                                    <th className="tb-col-ip">
                                                                        <span className="overline-title"> Copy </span>
                                                                    </th>
                                                                    <th className="tb-col-time">
                                                                        <span className="overline-title"> Test </span>
                                                                    </th>

                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {offer?.redirect?.length ? offer?.redirect.map((item, index) => {
                                                                    return (<tr key={Math.random()}
                                                                    >
                                                                        <td className="tb-col-os">{item.name}</td>
                                                                        <td className="tb-col-os">{item.url}
                                                                            {`${subIdClixFlow.c1 !== "" ? "/" + subIdClixFlow.c1 : ""}`}
                                                                            {`${subIdClixFlow.c2 !== "" ? "/" + subIdClixFlow.c2 : ""}`}
                                                                            {`${subIdClixFlow.c3 !== "" ? "/" + subIdClixFlow.c3 : ""}`}
                                                                        </td>
                                                                        <td className="tb-col-time">
                                                                        <span className="sub-text">
                                                                          <Button className='btn btn-primary btn-xs'
                                                                                  onClick={() => {
                                                                                      copyToClipboard(
                                                                                          `${item.url}${subIdClixFlow.c1 !== "" ? "/" + subIdClixFlow.c1 : ""}${subIdClixFlow.c2 !== "" ? "/" + subIdClixFlow.c2 : ""}${subIdClixFlow.c3 !== "" ? "/" + subIdClixFlow.c3 : ""}`
                                                                                      )
                                                                                  }}>Copy</Button>
                                                                        </span>
                                                                        </td>
                                                                        <td className="tb-col-time">
                                                                            <a href={item.url} target={"_blank"}
                                                                               className='btn btn-primary btn-xs'>Test</a>

                                                                        </td>


                                                                    </tr>)
                                                                }) : null}

                                                                </tbody>
                                                            </table>

                                                        </Card>

                                                        <BlockHead>
                                                            <BlockHeadContent>
                                                                <BlockTitle tag="h5">
                                                                    Creatives
                                                                </BlockTitle>
                                                            </BlockHeadContent>
                                                        </BlockHead>
                                                        <Card className="card-bordered">
                                                            <table className="table table-ulogs">
                                                                <thead className="bg-lighter">
                                                                <tr>
                                                                    <th colSpan={5}>
                                                                        <Download account={account} id={offer.id} all={true}/>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="tb-col-os">
                                                                        <span className="overline-title">ID</span>
                                                                    </th>
                                                                    <th className="tb-col-ip">
                                                                        <span className="overline-title"> Name </span>
                                                                    </th>
                                                                    <th className="tb-col-time">
                                                                        <span className="overline-title">  Type  </span>
                                                                    </th>
                                                                    <th className="tb-col-action">
                                                                        <span
                                                                            className="overline-title">   Preview   </span>
                                                                    </th>
                                                                    <th className="tb-col-action">
                                                                        <span
                                                                            className="overline-title">   Download   </span>
                                                                    </th>

                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {(emailData?.length) ? emailData?.map((item) => {
                                                                    return (
                                                                        <tr key={Math.random()}
                                                                        >
                                                                            <td className="tb-col-os">{item.id}</td>
                                                                            <td className="tb-col-ip">
                                                                                <span
                                                                                    className="sub-text">{item.description}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{item.type}</span>
                                                                            </td>


                                                                            <td className="tb-col-action">
                                                                                <Button className="btn btn-primary btn-sm  "
                                                                                        onClick={() => {
                                                                                            setHtmlCode(item.body)
                                                                                            setImages(item.images)
                                                                                            setEmailfrom(item.from_lines)
                                                                                            setSubjectLines(item.subjects)
                                                                                            setModal({...modal, html: true})

                                                                                        }}>PREVIEW</Button>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <Download account={account} id={offer.id} all={false}
                                                                                          creative={item?.id}/>

                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) : (<tr className='text-center'>
                                                                    <td colSpan={4} className='tb-col-os'>Data not Found
                                                                    </td>
                                                                </tr>)}
                                                                </tbody>
                                                            </table>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </> : <Loader/>
                                        }
                                    </ModalBody>
                                </Modal>

                            </Block>
                        </> :
                        (account?.type === 'B2direct') ? <>
                                <Block>
                                    <DataTable>
                                        <div className="m-2 ">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <h5 className="title"></h5>
                                                </div>
                                                <div className="card-tools border-0 ">
                                                    <ul className="btn-toolbar gx-1">
                                                        <li>
                                                            <Button
                                                                href="#search"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    toggle();
                                                                }}
                                                                className="btn-icon search-toggle toggle-search"
                                                            >
                                                                <Icon name="search"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li className="btn-toolbar-sep"></li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="a"
                                                                                className="btn btn-trigger btn-icon dropdown-toggle"
                                                                                onClick={async () => {
                                                                                    getSettings().then(()=> {
                                                                                        localStorage.setItem('meta', JSON.stringify(meta));
                                                                                    })


                                                                                }}>
                                                                    <div className="dot dot-primary"></div>
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="filter-wg dropdown-menu-xl">
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">Table Filters</span>
                                                                        <div className="dropdown">
                                                                            <Button size="sm" className="btn-icon">
                                                                                <Icon name="more-h"></Icon>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg">
                                                                        <Row className="gx-6 gy-4">
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label
                                                                                        className="overline-title overline-title-alt">Category</label>
                                                                                    <RSelect
                                                                                        options={
                                                                                            meta.categories?.map((item) => {
                                                                                                return {
                                                                                                    value: item?.network_category_id,
                                                                                                    label: item?.name
                                                                                                };
                                                                                            })}

                                                                                        placeholder="Any Country"
                                                                                        onChange={value => setSearchOption({ ...searchOption,category:{value:value.value,label:value.label}})}
                                                                                        defaultValue={{ value: searchOption?.category?.value ,label:searchOption?.category?.label }}

                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label
                                                                                        className="overline-title overline-title-alt">Channel</label>
                                                                                    <RSelect
                                                                                        options={
                                                                                            meta.channels?.map((item) => {
                                                                                                return {
                                                                                                    value: item?.network_channel_id,
                                                                                                    label: item?.name
                                                                                                };
                                                                                            })}
                                                                                        placeholder="Any Channel"

                                                                                        onChange={value => setSearchOption({ ...searchOption,channel:{value:value.value,label:value.label}})}
                                                                                        defaultValue={{ value: searchOption?.channel?.value ,label:searchOption?.channel?.label }}


                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label
                                                                                        className="overline-title overline-title-alt">Country</label>
                                                                                    <RSelect   options={
                                                                                        meta.countries?.map((item) => {
                                                                                            return {
                                                                                                value: item?.country_id,
                                                                                                label: item?.country_name
                                                                                            };
                                                                                        })}
                                                                                             placeholder="Any Country"
                                                                                             onChange={value => setSearchOption({ ...searchOption,country:{value:value.value,label:value.label}})}
                                                                                               defaultValue={{ value: searchOption?.country?.value ,label:searchOption?.country?.label }}


                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label
                                                                                        className="overline-title overline-title-alt">Payout
                                                                                        Type</label>
                                                                                    <RSelect options={[
                                                                                        {value: 'cpa', label: 'CPA'},
                                                                                        {
                                                                                            value: 'cpa_cps',
                                                                                            label: 'CPA/CPS'
                                                                                        },
                                                                                        {value: 'cpc', label: 'CPC'},
                                                                                        {value: 'cpm', label: 'CPM'},
                                                                                        {value: 'cps', label: 'CPS'},
                                                                                        {value: 'prv', label: 'PRV'}
                                                                                    ]}
                                                                                             placeholder="Any Payout Type"
                                                                                             onChange={value => setSearchOption({...searchOption, payout:value.value,label:value.label})}
                                                                                             defaultValue={{ value: searchOption?.payout?.value ,label:searchOption?.payout?.label }}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label
                                                                                        className="overline-title overline-title-alt">Platform</label>
                                                                                    <RSelect
                                                                                        options={
                                                                                            meta.platforms?.map((item) => {
                                                                                                return {
                                                                                                    value: item?.platform_id,
                                                                                                    label: item?.name
                                                                                                };
                                                                                            })}

                                                                                             placeholder="Any Platform"
                                                                                        onChange={value => setSearchOption({ ...searchOption,platefom:{value:value.value,label:value.label}})}
                                                                                        defaultValue={{ value: searchOption?.platefom?.value ,label:searchOption?.platefom?.label }}

                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <div className="form-group">
                                                                                    <Button onClick={() => {
                                                                                        onClickFilter()
                                                                                    }} type="button"
                                                                                            className="btn btn-secondary">
                                                                                        Filter
                                                                                    </Button>
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6" className='text-center '>
                                                                                <div className="form-group">
                                                                                    <Button onClick={() => {
                                                                                        setSearchOption({
                                                                                                category:null,
                                                                                                country:null,
                                                                                                channel:null,
                                                                                                platefom:null,
                                                                                                payout:'',
                                                                                            })
                                                                                    }} type="button"
                                                                                            className="btn btn-secondary">
                                                                                        Clear Filter
                                                                                    </Button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>

                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="a"
                                                                                className="btn btn-trigger btn-icon dropdown-toggle">
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">

                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Affichage</span>
                                                                        </li>
                                                                        <li className={itemPerPage === 10 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 30 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(30);
                                                                                }}
                                                                            >
                                                                                30
                                                                            </DropdownItem>

                                                                        </li>
                                                                        <li className={itemPerPage === 50 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(50);
                                                                                }}
                                                                            >
                                                                                50
                                                                            </DropdownItem>

                                                                        </li>
                                                                        <li className={itemPerPage === 100 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(100);
                                                                                }}
                                                                            >
                                                                                100
                                                                            </DropdownItem>

                                                                        </li>
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={"sort" === "desc" ? "active" : ""}>
                                                                            <DropdownItem

                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState("desc");
                                                                                    sortFunc("desc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={"sort" === "asc" ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState("asc");
                                                                                    sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                                    <div className="search-content">
                                                        <Button
                                                            onClick={() => {
                                                                setSearchText("");
                                                                toggle();
                                                            }}
                                                            className="search-back btn-icon toggle-search"
                                                        >
                                                            <Icon name="arrow-left"></Icon>
                                                        </Button>
                                                        <input
                                                            type="text"
                                                            className="border-transparent form-focus-none form-control"
                                                            placeholder="Recherche par Nom ou Description"
                                                            value={onSearchText}
                                                            onChange={(e) => onFilterChange(e)}
                                                        />
                                                        <Button className="search-submit btn-icon text-primary "
                                                                outline="true" onClick={async () => {
                                                            await fillterhandel()
                                                        }}>
                                                            <Icon name="search"></Icon>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <DataTableBody className="border-top" bodyclass="nk-tb-orders">
                                            <DataTableHead>
                                                <DataTableRow className="nk-tb-col-check">
                                                    <div
                                                        className="custom-control custom-control-sm custom-checkbox notext">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            onChange={(e) => selectorCheck(e)}
                                                            id="B2direct"
                                                        />
                                                        <label className="custom-control-label" htmlFor="B2direct"></label>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow className="nk-tb-orders-type">
                                                    <span>ID</span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span>Name</span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span>Category</span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span>Payout</span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span>Status</span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span>Channels</span>
                                                </DataTableRow>


                                                <DataTableRow>
                                                    <span>Visibility</span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span>Create At</span>
                                                </DataTableRow>
                                            </DataTableHead>
                                            {campaings?.length ? campaings?.map((item) => {
                                                return (
                                                    <DataTableItem key={Math.random()}
                                                    >
                                                        <DataTableRow className="nk-tb-col-check">
                                                            <div
                                                                className="custom-control custom-control-sm custom-checkbox notext">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    defaultChecked={item?.checked}
                                                                    id={item.network_offer_id + "uid1"}
                                                                    key={Math.random()}
                                                                    onChange={(e) => onSelectChange(e, item.network_offer_id)}
                                                                />
                                                                <label className="custom-control-label"
                                                                       htmlFor={item.network_offer_id + "uid1"}></label>
                                                            </div>
                                                        </DataTableRow>


                                                        <DataTableRow>
                                                            <span className="tb-lead">{item.network_offer_id}</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <a
                                                                href="#offerDetailles"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    offerDetailles(item?.network_offer_id);
                                                                }}

                                                            >
                                                                <span className="tb-lead text-primary">{item?.name}</span>

                                                            </a>
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/offers/${item.network_offer_id}/${account.id}/OfferB`}>
                                                                <span role="button"
                                                                      className="tb-lead  l text-primary  cursor-pointer d-none">{item.name}</span>
                                                            </Link>
                                                        </DataTableRow>

                                                        <DataTableRow>
                                                            <span className="tb-lead">{item?.category}</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span
                                                                className="tb-lead">{item?.payout} {item?.currency_id} </span>
                                                        </DataTableRow>

                                                        <DataTableRow>
                                                            <span className="tb-lead">{item?.offer_status}</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                <span className="tb-lead">{item?.channels?.map((item) => {
                    return (<span key={Math.random()}
                    >{item}<br/></span>)
                })}</span>
                                                        </DataTableRow>

                                                        <DataTableRow>
                                                            <span
                                                                className="tb-sub tb-lead text-uppercase text-success">{(item?.visibility === "require_approval") ? (
                                                                    <span className="badge-dim badge bg-outline-success">Require Approval</span>) :
                                                                <span
                                                                    className="badge-dim badge bg-outline-success">{item.visibility}</span>}</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <Badge className='badge-sm badge-dim'>

                                                                <span
                                                                    className="tb-lead">{convertUnixTimestampToMMDDYY(item?.time_created)}</span>
                                                            </Badge>
                                                        </DataTableRow>
                                                    </DataTableItem>
                                                );
                                            }) : null}
                                        </DataTableBody>
                                        {campaings?.length <= 0 && !loadingTable ? <>
                                            <div className="card-inner">
                                                <div className="text-center">
                                                <span className="text-silent">
                                                    <div className='text-uppercase fw-bold text-secondary m-1 '>No data found </div>
                                                </span>
                                                </div>
                                            </div>
                                        </> : campaings?.length > 0 && !loadingTable ? null : <LoaderTable/>}

                                        {campaings?.length ? <div className="card-inner">
                                            <PaginationComponent
                                                itemPerPage={itemPerPage}
                                                totalItems={totalItems}
                                                paginate={paginate}
                                                currentPage={currentPage}
                                            />
                                        </div> : null}
                                    </DataTable>
                                    <Modal isOpen={modal.B2direct} toggle={() => setModal({B2direct: false})}
                                           className="modal-dialog-centered" size="xl"
                                           style={{width: "80%", minWidth: '80%'}}>
                                        <ModalBody>
                                            <a
                                                href="#close"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setModal({B2direct: false})
                                                }}
                                                className="close"
                                            >
                                                <Icon name="cross-sm"></Icon>
                                            </a>
                                            {
                                                !loadingOffer ? <>
                                                    <Block>
                                                        <Row className="gy-5">
                                                            <Col lg="5">
                                                                <BlockHead>
                                                                    <BlockHeadContent>
                                                                        <BlockTitle tag="h5"> General </BlockTitle>
                                                                    </BlockHeadContent>
                                                                </BlockHead>
                                                                <Card className="card-bordered">
                                                                    <ul className="data-list is-compact">
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">Offer ID</div>
                                                                                <div
                                                                                    className="data-value">{offer?.network_offer_id}</div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">Name</div>
                                                                                <div
                                                                                    className="data-value"> {offer?.name} </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">Category</div>
                                                                                <div
                                                                                    className="data-value"> {offer?.relationship?.category?.name} </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">Currency</div>
                                                                                <div
                                                                                    className="data-value">  {offer?.payout} {data?.currency_id}  </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label"> Blocked
                                                                                    Variables
                                                                                </div>
                                                                                <div className="data-value"> N/A</div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label"> Tracking
                                                                                    Link(s)
                                                                                </div>
                                                                                <div className="data-value text-xs"><small
                                                                                    className='text-primary'>{offer?.tracking_url} </small>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-list-s2">
                                                                                <div className="data-label">Description
                                                                                </div>
                                                                                <div className="data-value mt-4">
                                                                                    <textarea cols='100' rows="40"
                                                                                              className="no-resize form-control"
                                                                                              value={offer.html_description}/>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">Status</div>
                                                                                <div className="data-value">
                                                                                    <Badge
                                                                                        size="sm"
                                                                                        color={
                                                                                            offer?.offer_status === "active"
                                                                                                ? "outline-success"
                                                                                                : offer.offer_status === "Pending"
                                                                                                    ? "outline-info"
                                                                                                    : "outline-danger"
                                                                                        }
                                                                                        className="badge-dim"
                                                                                    >
                                                                                        {offer?.offer_status}
                                                                                    </Badge>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">Last Checked
                                                                                    At
                                                                                </div>
                                                                                <div
                                                                                    className="data-value">{offer?.time_created}</div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">Created</div>
                                                                                <div
                                                                                    className="data-value">{offer?.time_saved}</div>
                                                                            </div>
                                                                        </li>

                                                                    </ul>
                                                                </Card>
                                                                <BlockHead>
                                                                    <BlockHeadContent>
                                                                        <BlockTitle tag="h5"> Email Instructions / Optizmo
                                                                            Integration </BlockTitle>

                                                                    </BlockHeadContent>
                                                                </BlockHead>

                                                                <Card className="card-bordered">
                                                                    <ul className="data-list is-compact">
                                                                        <li className="data-item">
                                                                            <div className="ata-list-s2">
                                                                                <div className="data-label"> From Lines
                                                                                </div>
                                                                                <div className="data-value">
                                                                                    <textarea cols='100' rows="10"
                                                                                              className="no-resize form-control"
                                                                                              value={offer?.relationship?.email?.from_lines}/>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-list-s2">
                                                                                <div className="data-label">Subject Lines
                                                                                </div>
                                                                                <div className="data-value">
                                                                                    <div className="data-value mt-4">
                                                                                        <textarea cols='100' rows="40"
                                                                                                  className="no-resize form-control"
                                                                                                  value={offer?.relationship?.email?.subject_lines}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">
                                                                                    Mailer Access
                                                                                    Key

                                                                                    <Button
                                                                                        className='btn btn-primary btn-xs mx-2'
                                                                                        onClick={async () => {
                                                                                            await copyToClipboard(
                                                                                                `${offer?.relationship?.integrations?.optizmo?.mailer_access_key}`
                                                                                            )
                                                                                        }}>Copy</Button>
                                                                                </div>
                                                                                <div className="data-value url">
                                                                                    {offer?.relationship?.integrations?.optizmo?.mailer_access_key}

                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">
                                                                                    Optout Link
                                                                                    <Button
                                                                                        className='btn btn-primary btn-xs mx-2'
                                                                                        onClick={async () => {
                                                                                            await copyToClipboard(
                                                                                                `${offer?.relationship?.integrations?.optizmo?.optout_link}`
                                                                                            )
                                                                                        }}>Copy</Button>
                                                                                </div>
                                                                                <div className="data-value url">
                                                                                    {offer?.relationship?.integrations?.optizmo?.optout_link}

                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </Card>
                                                            </Col>


                                                            <Col lg="7">
                                                                <Card className="card-bordered">
                                                                    <table className="table table-ulogs">
                                                                        <thead className="bg-lighter">
                                                                        <tr>
                                                                            <th className="tb-col-ip">
                                                                                <span
                                                                                    className="overline-title"> Source ID </span>
                                                                            </th>
                                                                            <th className="tb-col-os">
                                                                                <span
                                                                                    className="overline-title">SubID 1 </span>
                                                                            </th>
                                                                            <th className="tb-col-os">
                                                                                <span
                                                                                    className="overline-title">SubID 2 </span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span
                                                                                    className="overline-title"> SubID 3 </span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span
                                                                                    className="overline-title"> SubID 4 </span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span
                                                                                    className="overline-title"> SubID 5 </span>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>


                                                                            <td className="tb-col-os">
                                                                                <div className="form-group">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        name="name"
                                                                                        defaultValue={subIdB2direct.source_id}
                                                                                        onChange={(e) => {
                                                                                            setSubIdB2direct({
                                                                                                ...subIdB2direct,
                                                                                                source_id: e.target.value
                                                                                            })
                                                                                        }}
                                                                                        placeholder="Enter Suorce ID"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            <td className="tb-col-os">
                                                                                <div className="form-group">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        name="name"
                                                                                        defaultValue={subIdB2direct.sub1}
                                                                                        onChange={(e) => {
                                                                                            setSubIdB2direct({
                                                                                                ...subIdB2direct,
                                                                                                sub1: e.target.value
                                                                                            })
                                                                                        }}
                                                                                        placeholder="Enter Sub ID"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            <td className="tb-col-os">
                                                                                <div className="form-group">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        name="name"
                                                                                        defaultValue={subIdB2direct.sub2}
                                                                                        onChange={(e) => {
                                                                                            setSubIdB2direct({
                                                                                                ...subIdB2direct,
                                                                                                sub2: e.target.value
                                                                                            })
                                                                                        }}
                                                                                        placeholder="Enter Sub ID"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            <td className="tb-col-os">
                                                                                <div className="form-group">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        name="name"
                                                                                        defaultValue={subIdB2direct.sub3}
                                                                                        onChange={(e) => {
                                                                                            setSubIdB2direct({
                                                                                                ...subIdB2direct,
                                                                                                sub3: e.target.value
                                                                                            })
                                                                                        }}
                                                                                        placeholder="Enter Sub ID"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            <td className="tb-col-os">
                                                                                <div className="form-group">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        name="name"
                                                                                        defaultValue={subIdB2direct.sub4}
                                                                                        onChange={(e) => {
                                                                                            setSubIdB2direct({
                                                                                                ...subIdB2direct,
                                                                                                sub4: e.target.value
                                                                                            })
                                                                                        }}
                                                                                        placeholder="Enter Sub ID"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            <td className="tb-col-os">
                                                                                <div className="form-group">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        name="name"
                                                                                        defaultValue={subIdB2direct.sub5}
                                                                                        onChange={(e) => {
                                                                                            setSubIdB2direct({
                                                                                                ...subIdB2direct,
                                                                                                sub5: e.target.value
                                                                                            })
                                                                                        }}
                                                                                        placeholder="Enter Sub ID"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Tracking Link(s)</td>
                                                                            <td colSpan={4}>
                                                                                <div className="data-value text-xs"><small
                                                                                    className='text-primary'>
                                                                                    {generateURL(subIdB2direct, offer?.tracking_url)}



                                                                                </small>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Button className='btn btn-primary btn-xs'
                                                                                        onClick={() => {
                                                                                            copyToClipboard(generateURL(subIdB2direct, offer?.tracking_url))
                                                                                        }}>Copy</Button>
                                                                            </td>
                                                                        </tr>


                                                                        </tbody>
                                                                    </table>

                                                                </Card>

                                                                <BlockHead>
                                                                    <BlockHeadContent>
                                                                        <BlockTitle tag="h5"> Stats </BlockTitle>
                                                                    </BlockHeadContent>
                                                                </BlockHead>
                                                                <Card className="card-bordered">
                                                                    <table className="table table-ulogs">
                                                                        <thead className="bg-lighter">
                                                                        <tr>
                                                                            <th className="tb-col-os">
                                                                                <span
                                                                                    className="overline-title">Revenue</span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span
                                                                                    className="overline-title"> Clicks </span>
                                                                            </th>
                                                                            <th className="tb-col-time">
                                                                                <span className="overline-title"> CV </span>
                                                                            </th>
                                                                            <th className="tb-col-action">
                                                                                <span
                                                                                    className="overline-title"> CVR </span>
                                                                            </th>
                                                                            <th className="tb-col-action">
                                                                                <span
                                                                                    className="overline-title">  RPC  </span>
                                                                            </th>
                                                                            <th className="tb-col-action">
                                                                                <span className="overline-title">  Invalid Click  </span>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td className="tb-col-os">{offer?.relationship?.reporting?.revenue}</td>
                                                                            <td className="tb-col-ip">
                                                                                <span
                                                                                    className="sub-text">{offer?.relationship?.reporting?.total_click}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{offer?.relationship?.reporting?.cv}</span>
                                                                            </td>
                                                                            <td className="tb-col-action">
                                                                                <span
                                                                                    className="sub-text">{offer?.relationship?.reporting?.cv}</span>
                                                                            </td>
                                                                            <td className="tb-col-action">
                                                                                <span
                                                                                    className="sub-text">{offer?.relationship?.reporting?.cvr}</span>
                                                                            </td>
                                                                            <td className="tb-col-action">
                                                                                <span
                                                                                    className="sub-text">{offer?.relationship?.reporting?.invalid_click}</span>
                                                                            </td>
                                                                        </tr>

                                                                        </tbody>
                                                                    </table>

                                                                </Card>
                                                                <BlockHead>
                                                                    <BlockHeadContent>
                                                                        <BlockTitle tag="h5"> Payout </BlockTitle>
                                                                    </BlockHeadContent>
                                                                </BlockHead>
                                                                <Card className="card-bordered">
                                                                    <table className="table table-ulogs">
                                                                        <thead className="bg-lighter">
                                                                        <tr>
                                                                            <th className="tb-col-os">
                                                                                <span className="overline-title">Name</span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span
                                                                                    className="overline-title"> Payout </span>
                                                                            </th>
                                                                            <th className="tb-col-time">
                                                                                <span className="overline-title">  Valid Between  </span>
                                                                            </th>
                                                                            <th className="tb-col-action">
                                                                                <span
                                                                                    className="overline-title">  Variables  </span>
                                                                            </th>
                                                                            <th className="tb-col-action">
                                                                                <span
                                                                                    className="overline-title">   Rules   </span>
                                                                            </th>
                                                                            <th className="tb-col-action">
                                                                                <span
                                                                                    className="overline-title">    Caps    </span>
                                                                            </th>
                                                                            <th className="tb-col-action">
                                                                                <span
                                                                                    className="overline-title">    Description    </span>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {(offer?.relationship?.payouts?.entries.length) ? offer?.relationship?.payouts?.entries.map((item) => {
                                                                            return (<tr key={Math.random()}
                                                                            >
                                                                                <td className="tb-col-os">Base</td>
                                                                                <td className="tb-col-ip">
                                                                                    <span
                                                                                        className="sub-text text-uppercase">{item.payout_type} {item.payout_amount} {offer.currency_id}</span>
                                                                                </td>
                                                                                <td className="tb-col-time">
                                                                                    <span className="sub-text">---</span>
                                                                                </td>
                                                                                <td className="tb-col-time">
                                                                                    <span className="sub-text">N/A</span>
                                                                                </td>
                                                                                <td className="tb-col-time">
                                                                                    <span className="sub-text">None</span>
                                                                                </td>
                                                                                <td className="tb-col-time">
                                                                                    <span className="sub-text">---</span>
                                                                                </td>
                                                                            </tr>)
                                                                        }) : <tr>
                                                                            <td className='' colSpan={0}>Data not Found</td>
                                                                        </tr>}


                                                                        </tbody>
                                                                    </table>
                                                                </Card>
                                                                <BlockHead>
                                                                    <BlockHeadContent>
                                                                        <BlockTitle tag="h5"> Geo Targeting </BlockTitle>
                                                                    </BlockHeadContent>
                                                                </BlockHead>
                                                                <Card className="card-bordered">
                                                                    <ul className="data-list is-compact">
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">Inclusions
                                                                                    (Country)
                                                                                </div>
                                                                                <div className="data-value">
                                                                                    {offer.relationship?.ruleset?.countries?.map((item) => {
                                                                                        if (item?.targeting_type === "include")
                                                                                            return (<div
                                                                                                className='text-primary fw-bolder'
                                                                                                key={Math.random()}
                                                                                            >{item.label} {item.country_code} </div>)
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="data-col">
                                                                                <div className="data-label">Exclusions
                                                                                    (Country)
                                                                                </div>
                                                                                <div className="data-value">
                                                                                    {offer?.relationship?.ruleset?.countries?.map((item) => {
                                                                                        if (item?.targeting_type !== "include")
                                                                                            return (<div
                                                                                                className='text-primary fw-bolder'
                                                                                                key={Math.random()}>{item.label} {item.country_code} </div>)
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </Card>
                                                                <BlockHead>
                                                                    <BlockHeadContent>
                                                                        <BlockTitle tag="h5"> Creatives </BlockTitle>
                                                                    </BlockHeadContent>
                                                                </BlockHead>
                                                                <Card className="card-bordered">
                                                                    <table className="table table-ulogs">
                                                                        <thead className="bg-lighter">
                                                                        <tr>
                                                                            <th className="tb-col-os">
                                                                                <span className="overline-title">ID</span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span
                                                                                    className="overline-title"> Name </span>
                                                                            </th>
                                                                            <th className="tb-col-time">
                                                                                <span
                                                                                    className="overline-title">  Type  </span>
                                                                            </th>
                                                                            <th className="tb-col-action">
                                                                                <span
                                                                                    className="overline-title">   Preview   </span>
                                                                            </th>
                                                                            <th className="tb-col-action">
                                                                                <span
                                                                                    className="overline-title">   Download   </span>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {(offer.relationship?.creatives?.entries.length) ? offer?.relationship?.creatives?.entries.map((item) => {
                                                                            return (
                                                                                <tr key={Math.random()}>
                                                                                    <td className="tb-col-os">{item.network_offer_creative_id}</td>
                                                                                    <td className="tb-col-ip">
                                                                                        <span
                                                                                            className="sub-text">{item.name}</span>
                                                                                    </td>
                                                                                    <td className="tb-col-time">
                                                                                        <span
                                                                                            className="sub-text">{item.creative_type}</span>
                                                                                    </td>
                                                                                    <td className="tb-col-action">

                                                                                        {(item?.creative_type === "image") ? (
                                                                                            <img src={item.resource_url}
                                                                                                 alt={item.name}/>) : (
                                                                                            <Button
                                                                                                className="btn btn-primary btn-sm "
                                                                                                onClick={() => {
                                                                                                    setHtmlCode(item.html_code)
                                                                                                    setModal({
                                                                                                        ...modal,
                                                                                                        html: true
                                                                                                    })
                                                                                                }}>PREVIEW</Button>)}
                                                                                    </td>
                                                                                    <td className="tb-col-action">
                                                                                        {
                                                                                            (item?.creative_type === "image") ? (
                                                                                                <a
                                                                                                    href={`${item.resource_url}?token=${account.apikey}`}
                                                                                                    target={'_blank'}
                                                                                                    className="btn btn-primary btn-sm"
                                                                                                >
                                                                                                    Download
                                                                                                </a>
                                                                                            ) : (
                                                                                                <a
                                                                                                    //     href={`https://api.eflow.team/v1/affiliates/creatives/${item.network_offer_creative_id}/downloadhosted?format=package&offerId=${offer?.network_offer_id}`}
                                                                                                    //     target={'_blank'}
                                                                                                    className="btn btn-primary btn-sm"
                                                                                                    onClick={() => downloadWithBearerToken(item)}
                                                                                                >
                                                                                                    Download
                                                                                                </a>
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }) : (<tr className='text-center'>
                                                                            <td colSpan={4} className='tb-col-os'>Data not
                                                                                Found
                                                                            </td>
                                                                        </tr>)}
                                                                        </tbody>
                                                                    </table>
                                                                </Card>
                                                                <BlockHead>
                                                                    <BlockHeadContent>
                                                                        <BlockTitle tag="h5"> URLs </BlockTitle>
                                                                    </BlockHeadContent>
                                                                </BlockHead>
                                                                <Card className="card-bordered">
                                                                    <table className="table table-ulogs">
                                                                        <thead className="bg-lighter">
                                                                        <tr>
                                                                            <th className="tb-col-os">
                                                                                <span className="overline-title">ID</span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span
                                                                                    className="overline-title"> Name </span>
                                                                            </th>
                                                                            <th className="tb-col-time">
                                                                                <span className="overline-title">   Preview URL   </span>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {(offer?.relationship?.urls?.entries.length) ? (offer.relationship.urls.entries.map((item) => {
                                                                            return (<tr key={Math.random()}>
                                                                                <td className="tb-col-os">{item.network_offer_url_id}</td>
                                                                                <td className="tb-col-ip">
                                                                                    <span
                                                                                        className="sub-text">{(item.name === "null") ? 'N/A' : item.name}</span>
                                                                                </td>
                                                                                <td className="tb-col-time">
                                                                                    <span className="sub-text">
                                                                                        <a href={item?.preview_url}
                                                                                           target={'_blank'}>
                                                                                             {(item.preview_url !== "") ? item.preview_url : "---"}
                                                                                        </a>
                                                                                    </span>
                                                                                </td>

                                                                            </tr>)
                                                                        })) : (<tr className='text-center'>
                                                                            <td colSpan={3}>Data Not Found</td>
                                                                        </tr>)}
                                                                        </tbody>
                                                                    </table>
                                                                </Card>
                                                                <BlockHead>
                                                                    <BlockHeadContent>
                                                                        <BlockTitle tag="h5"> Caps </BlockTitle>
                                                                    </BlockHeadContent>
                                                                </BlockHead>
                                                                <Card className="card-bordered">
                                                                    <table className="table table-ulogs">
                                                                        <thead className="bg-lighter">
                                                                        <tr>
                                                                            <th className="tb-col-os">
                                                                                <span className="overline-title">Type</span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span
                                                                                    className="overline-title">  Daily  </span>
                                                                            </th>
                                                                            <th className="tb-col-time">
                                                                                <span
                                                                                    className="overline-title">    Weekly    </span>
                                                                            </th>
                                                                            <th className="tb-col-time">
                                                                                <span
                                                                                    className="overline-title">     Monthly     </span>
                                                                            </th>
                                                                            <th className="tb-col-time">
                                                                                <span
                                                                                    className="overline-title">      Global      </span>
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td className="tb-col-os">Click</td>
                                                                            <td className="tb-col-ip">
                                                                                <span
                                                                                    className="sub-text">{offer?.daily_click_cap}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{offer?.weekly_click_cap}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{offer?.monthly_click_cap}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{offer?.global_click_cap}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="tb-col-os">Conversions</td>
                                                                            <td className="tb-col-ip">
                                                                                <span
                                                                                    className="sub-text">{offer?.weekly_conversion_cap}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{offer?.weekly_conversion_cap}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{offer?.monthly_conversion_cap}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{offer?.global_conversion_cap}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="tb-col-os">Revenue</td>
                                                                            <td className="tb-col-ip">
                                                                                <span
                                                                                    className="sub-text">{offer?.daily_payout_cap}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{offer?.weekly_payout_cap}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{offer?.monthly_payout_cap}</span>
                                                                            </td>
                                                                            <td className="tb-col-time">
                                                                                <span
                                                                                    className="sub-text">{offer?.global_payout_cap}</span>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </Block>
                                                </> : <Loader/>
                                            }
                                        </ModalBody>
                                    </Modal>

                                </Block>
                            </> :
                            (account?.type === 'Cx3ads') ? <>
                                    <Block>
                                        <DataTable>
                                            <div className="m-2 ">
                                                <div className="card-title-group">
                                                    <div className="card-title">
                                                        <h5 className="title"></h5>
                                                    </div>
                                                    <div className="card-tools border-0 ">
                                                        <ul className="btn-toolbar gx-1">
                                                            <li>
                                                                <Button
                                                                    href="#search"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        toggle();
                                                                    }}
                                                                    className="btn-icon search-toggle toggle-search"
                                                                >
                                                                    <Icon name="search"></Icon>
                                                                </Button>
                                                            </li>
                                                            <li className="btn-toolbar-sep"></li>
                                                            <li>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="a"
                                                                                    className="btn btn-trigger btn-icon dropdown-toggle">
                                                                        <div className="dot dot-primary"></div>
                                                                        <Icon name="filter-alt"></Icon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu end
                                                                                  className="filter-wg dropdown-menu-xl">
                                                                        <div className="dropdown-head">
                                                                            <span className="sub-title dropdown-title">Filtre avancé</span>
                                                                            <div className="dropdown">
                                                                                <Button size="sm" className="btn-icon">
                                                                                    <Icon name="more-h"></Icon>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="dropdown-body dropdown-body-rg">
                                                                            <Row className="gx-6 gy-4">
                                                                                <Col size="12">
                                                                                    <div className="form-group">
                                                                                        <label
                                                                                            className="overline-title overline-title-alt">Créé
                                                                                            par</label>
                                                                                        <RSelect options={[{
                                                                                            value: "admin",
                                                                                            label: "Administrator"
                                                                                        },
                                                                                            {
                                                                                                value: "user",
                                                                                                label: "Utilisateur"
                                                                                            },]} placeholder="Role"/>
                                                                                    </div>
                                                                                </Col>

                                                                                <Col size="12">
                                                                                    <div className="form-group">
                                                                                        <Button type="button"
                                                                                                className="btn btn-secondary">
                                                                                            Filter
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <div className="dropdown-foot between">
                                                                            <a
                                                                                href="#reset"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="clickable"
                                                                            >
                                                                                Réinitialiser le filtre
                                                                            </a>
                                                                            <a
                                                                                href="#save"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                            >
                                                                                Enregistrer le filtre
                                                                            </a>
                                                                        </div>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </li>
                                                            <li>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="a"
                                                                                    className="btn btn-trigger btn-icon dropdown-toggle">
                                                                        <Icon name="setting"></Icon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu end className="dropdown-menu-xs">
                                                                        <ul className="link-check">
                                                                            <li>
                                                                                <span>Affichage</span>
                                                                            </li>
                                                                            <li className={itemPerPage === 10 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setItemPerPage(10);
                                                                                    }}
                                                                                >
                                                                                    10
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={itemPerPage === 30 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setItemPerPage(30);
                                                                                    }}
                                                                                >
                                                                                    30
                                                                                </DropdownItem>

                                                                            </li>
                                                                            <li className={itemPerPage === 50 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setItemPerPage(50);
                                                                                    }}
                                                                                >
                                                                                    50
                                                                                </DropdownItem>

                                                                            </li>
                                                                            <li className={itemPerPage === 100 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setItemPerPage(100);
                                                                                    }}
                                                                                >
                                                                                    100
                                                                                </DropdownItem>

                                                                            </li>
                                                                        </ul>
                                                                        <ul className="link-check">
                                                                            <li>
                                                                                <span>Order</span>
                                                                            </li>
                                                                            <li className={"sort" === "desc" ? "active" : ""}>
                                                                                <DropdownItem

                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setSortState("desc");
                                                                                        sortFunc("desc");
                                                                                    }}
                                                                                >
                                                                                    DESC
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={"sort" === "asc" ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setSortState("asc");
                                                                                        sortFunc("asc");
                                                                                    }}
                                                                                >
                                                                                    ASC
                                                                                </DropdownItem>
                                                                            </li>
                                                                        </ul>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </li>
                                                            <li className=''>
                                                                <Button

                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();

                                                                    }}
                                                                    className="btn btn-trigger btn-icon dropdown-toggle "
                                                                >
                                                                    <Icon name="users"></Icon>
                                                                </Button>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                    <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                                        <div className="search-content">
                                                            <Button
                                                                onClick={() => {
                                                                    setSearchText("");
                                                                    toggle();
                                                                }}
                                                                className="search-back btn-icon toggle-search"
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                            <input
                                                                type="text"
                                                                className="border-transparent form-focus-none form-control"
                                                                placeholder="Recherche par Nom ou Description"
                                                                value={onSearchText}
                                                                onChange={(e) => onFilterChange(e)}
                                                            />
                                                            <Button className="search-submit btn-icon text-primary "
                                                                    outline="true" onClick={async () => {
                                                                await fillterhandel()
                                                            }}>
                                                                <Icon name="search"></Icon>
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <DataTableBody className="border-top" bodyclass="nk-tb-orders">

                                                <DataTableHead>
                                                    <DataTableRow className="nk-tb-col-check">
                                                        <div
                                                            className="custom-control custom-control-sm custom-checkbox notext">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="B2direct"
                                                            />
                                                            <label className="custom-control-label"
                                                                   htmlFor="B2direct"></label>
                                                        </div>
                                                    </DataTableRow>
                                                    <DataTableRow className="nk-tb-orders-type">
                                                        <span>ID</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span>Name</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span>Category</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span>Payout</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span>Status</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span>Channels</span>
                                                    </DataTableRow>


                                                    <DataTableRow>
                                                        <span>Visibility</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span>Create At</span>
                                                    </DataTableRow>
                                                </DataTableHead>
                                                {campaings?.length ? campaings?.map((item) => {
                                                    return (
                                                        <DataTableItem key={Math.random()}>
                                                            <DataTableRow className="nk-tb-col-check">
                                                                <div
                                                                    className="custom-control custom-control-sm custom-checkbox notext">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        defaultChecked={item?.checked}
                                                                        id={item.offer_id + "uid1"}
                                                                        key={Math.random()}
                                                                        onChange={(e) => onSelectChange(e, item.offer_id)}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                           htmlFor={item.offer_id + "uid1"}></label>
                                                                </div>
                                                            </DataTableRow>


                                                            <DataTableRow>
                                                                <span className="tb-lead">{item?.offer_id}</span>
                                                            </DataTableRow>

                                                            <DataTableRow>
                                                                <a
                                                                    href="#offerDetailles"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        offerDetailles(item?.campaign_id);
                                                                    }}

                                                                >
                                                                    <span
                                                                        className="tb-lead text-primary">{item?.offer_name}</span>

                                                                </a>
                                                            </DataTableRow>


                                                            <DataTableRow>
                                                                <span className="tb-lead">{item?.vertical_name}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span
                                                                    className="tb-lead">{item?.price_converted} {item?.currency_symbol} / {item?.price_format} </span>
                                                            </DataTableRow>

                                                            <DataTableRow>
                                                                <span
                                                                    className="tb-sub tb-lead text-uppercase text-success">{(item?.offer_status?.offer_status_name === 'Active') ? (
                                                                        <span
                                                                            className="badge-dim badge bg-outline-success">{item?.offer_status?.offer_status_name}</span>) :
                                                                    <span
                                                                        className="badge-dim badge bg-outline-warning">{item?.offer_status?.offer_status_name}</span>}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                <span className="tb-lead">{item?.allowed_media_types?.map((item) => {
                    return (<span key={Math.random()}>{item?.media_type_name}<br/></span>)
                })}</span>
                                                            </DataTableRow>

                                                            <DataTableRow>
                                                                <span
                                                                    className="tb-sub tb-lead text-uppercase "> {(item?.hidden) ? (
                                                                        <span
                                                                            className="badge-dim badge bg-outline-success">{item?.hidden}</span>) :
                                                                    <span
                                                                        className="badge-dim badge bg-outline-warning">{`${item?.hidden}`}</span>}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <Badge className='badge-sm badge-dim'>

                                                                    <span
                                                                        className="tb-lead">{convertDateTimeToMMDDYYHHMM(item?.date_created)}</span>
                                                                </Badge>
                                                            </DataTableRow>

                                                        </DataTableItem>
                                                    );
                                                }) : null}
                                            </DataTableBody>
                                            {campaings?.length ? null : <><LoaderTable/></>}
                                            {campaings?.length ? <div className="card-inner">
                                                <PaginationComponent
                                                    itemPerPage={itemPerPage}
                                                    totalItems={totalItems}
                                                    paginate={paginate}
                                                    currentPage={currentPage}
                                                />
                                            </div> : null}
                                        </DataTable>

                                        <Modal isOpen={modal.Cx3ads} toggle={() => setModal({show: false})}
                                               className="modal-dialog-centered" size="xl"
                                               style={{width: "80%", minWidth: '80%'}}>
                                            <ModalBody>
                                                <a
                                                    href="#close"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setModal({Cx3ads: false})
                                                    }}
                                                    className="close"
                                                >
                                                    <Icon name="cross-sm"></Icon>
                                                </a>
                                                {
                                                    !loadingOffer ? <>
                                                        <div className="p-2">
                                                            <h5 className="title">Manage Offer {offer?.offer_name}</h5>

                                                            <Row>
                                                                <Col size={12}>


                                                                </Col>
                                                                <Col size={6}>
                                                                    <Card className="card-bordered">
                                                                        <ul className="data-list is-compact">
                                                                            <li className="data-item">
                                                                                <div className="data-row">
                                                                                    <div
                                                                                        className="data-label">Description
                                                                                    </div>
                                                                                    <li className="data-item">
                                                                                        <div className="data-value url ">
                                                                                            <div
                                                                                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(offer?.description)}}/>

                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label">Offer ID
                                                                                    </div>
                                                                                    <div
                                                                                        className="data-value">{offer?.offer_id}</div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label">Name</div>
                                                                                    <div
                                                                                        className="data-value">{offer?.offer_name}</div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label">Status</div>
                                                                                    <div className="data-value">
                                                                                        {offer?.status_name}
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label">Category
                                                                                    </div>
                                                                                    <div className="data-value">{null}</div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label">Price</div>
                                                                                    <div
                                                                                        className="data-value text-primary">{offer?.price} / {offer?.price_format}</div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label"> Vertical
                                                                                        Name
                                                                                    </div>
                                                                                    <div
                                                                                        className="data-value"> {offer?.vertical_name}    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label"> Preview
                                                                                        Link(s)
                                                                                    </div>
                                                                                    <div className="data-value text-xs"><a
                                                                                        href={offer?.preview_link}
                                                                                        target={'_blank'}
                                                                                        className='text-primary'>{offer?.preview_link} </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>

                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label"> Channels
                                                                                    </div>
                                                                                    <div
                                                                                        className="data-value url text-primary">
                                                                                        {
                                                                                            offer?.allowed_media_types?.map((item, index) => {
                                                                                                return (<div
                                                                                                    key={Math.random()}>{item.media_type_name}</div>)
                                                                                            })
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label"> Creatives
                                                                                        Download Url
                                                                                    </div>
                                                                                    <div className="data-value text-xs"><a
                                                                                        href={offer?.creatives_download_url}
                                                                                        target={'_blank'}
                                                                                        className='text-primary'>{offer?.creatives_download_url} </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label"> Unsubscribe
                                                                                        Link
                                                                                    </div>
                                                                                    <div className="data-value text-xs"><a
                                                                                        href={offer?.unsubscribe_link}
                                                                                        target={'_blank'}
                                                                                        className='text-primary'>{offer?.unsubscribe_link} </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label"> Suppression
                                                                                        Link
                                                                                    </div>
                                                                                    <div className="data-value text-xs"><a
                                                                                        href={offer?.suppression_link}
                                                                                        target={'_blank'}
                                                                                        className='text-primary'>{offer?.suppression_link} </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label"> Test Link
                                                                                    </div>
                                                                                    <div className="data-value text-xs"><a
                                                                                        href={offer?.test_link}
                                                                                        target={'_blank'}
                                                                                        className='text-primary'>{offer?.test_link} </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label"> PostBack
                                                                                        Link
                                                                                    </div>
                                                                                    <div className="data-value text-xs"><a
                                                                                        href={offer?.postback_url}
                                                                                        target={'_blank'}
                                                                                        className='text-primary'>{offer?.postback_url} </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label"> PostBack
                                                                                        Link
                                                                                    </div>
                                                                                    <div className="data-value text-xs">
                                                                                        <a href={offer?.postback_url}
                                                                                           target={'_blank'}
                                                                                           className='text-primary'>{offer?.postback_url} </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-col">
                                                                                    <div className="data-label"> Pixel
                                                                                        Link
                                                                                    </div>
                                                                                    <div className="data-value text-xs"><a
                                                                                        href={offer?.pixel_html}
                                                                                        target={'_blank'}
                                                                                        className='text-primary'>{offer?.pixel_html} </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>


                                                                        </ul>
                                                                    </Card>
                                                                </Col>
                                                                <Col size={6}>
                                                                    <Card className="card-bordered">
                                                                        <ul className="data-list is-compact">
                                                                            <li className="data-item">
                                                                                <div className="data-list-s2">
                                                                                    <div className="data-label">Subject
                                                                                    </div>
                                                                                    <div className="data-value mt-3">
                                                                                        <textarea cols='200' rows="12"
                                                                                                  className="no-resize form-control"
                                                                                                  value={offer?.subject_lines?.map((item) => {
                                                                                                      return (item + '\n').replace(/,/g, '')
                                                                                                  })}/>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="data-item">
                                                                                <div className="data-list-s2">
                                                                                    <div className="data-label">From</div>
                                                                                    <div className="data-value mt-3">
                                                                                        <textarea cols='200' rows="18"
                                                                                                  className="no-resize form-control"
                                                                                                  value={offer?.from_lines?.map((item) => {
                                                                                                      return (item + '\n').replace(/,/g, '')
                                                                                                  })}/>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </Card>

                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col size={12}>
                                                                    <Card className="card-bordered  my-4">
                                                                        <table className="table table-ulogs">
                                                                            <thead className="bg-lighter">
                                                                            <tr>
                                                                                <th className="tb-col-os">
                                                                                    <span
                                                                                        className="overline-title">SubID 1 </span>
                                                                                </th>
                                                                                <th className="tb-col-os">
                                                                                    <span
                                                                                        className="overline-title">SubID 2 </span>
                                                                                </th>
                                                                                <th className="tb-col-ip">
                                                                                    <span className="overline-title"> SubID 3 </span>
                                                                                </th>
                                                                                <th className="tb-col-ip">
                                                                                    <span className="overline-title"> SubID 4 </span>
                                                                                </th>
                                                                                <th className="tb-col-ip">
                                                                                    <span className="overline-title"> SubID 5 </span>
                                                                                </th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            <tr>

                                                                                <td className="tb-col-os">
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            className="form-control"
                                                                                            type="text"
                                                                                            name="name"
                                                                                            defaultValue={subIdCx3ads.s1}
                                                                                            onChange={(e) => {
                                                                                                setSubIdCx3ads({
                                                                                                    ...subIdCx3ads,
                                                                                                    s1: e.target.value
                                                                                                })
                                                                                            }}
                                                                                            placeholder="Enter Sub ID"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col-os">
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            className="form-control"
                                                                                            type="text"
                                                                                            name="name"
                                                                                            defaultValue={subIdCx3ads.s2}
                                                                                            onChange={(e) => {
                                                                                                setSubIdCx3ads({
                                                                                                    ...subIdCx3ads,
                                                                                                    s2: e.target.value
                                                                                                })
                                                                                            }}
                                                                                            placeholder="Enter Sub ID"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col-os">
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            className="form-control"
                                                                                            type="text"
                                                                                            name="name"
                                                                                            defaultValue={subIdCx3ads.s3}
                                                                                            onChange={(e) => {
                                                                                                setSubIdCx3ads({
                                                                                                    ...subIdCx3ads,
                                                                                                    s3: e.target.value
                                                                                                })
                                                                                            }}
                                                                                            placeholder="Enter Sub ID"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col-os">
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            className="form-control"
                                                                                            type="text"
                                                                                            name="name"
                                                                                            defaultValue={subIdCx3ads.s4}
                                                                                            onChange={(e) => {
                                                                                                setSubIdCx3ads({
                                                                                                    ...subIdCx3ads,
                                                                                                    s4: e.target.value
                                                                                                })
                                                                                            }}
                                                                                            placeholder="Enter Sub ID"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col-os">
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            className="form-control"
                                                                                            type="text"
                                                                                            name="name"
                                                                                            defaultValue={subIdCx3ads.s5}
                                                                                            onChange={(e) => {
                                                                                                setSubIdCx3ads({
                                                                                                    ...subIdCx3ads,
                                                                                                    s5: e.target.value
                                                                                                })
                                                                                            }}
                                                                                            placeholder="Enter Sub ID"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>


                                                                            </tbody>
                                                                        </table>

                                                                    </Card>

                                                                    <Card className="card-bordered my-4">
                                                                        <table className="table table-ulogs">
                                                                            <thead className="bg-lighter">
                                                                            <tr>
                                                                                <th className="tb-col-os">
                                                                                    <span
                                                                                        className="overline-title">ID</span>
                                                                                </th>
                                                                                <th className="tb-col-os">
                                                                                    <span
                                                                                        className="overline-title"> Name </span>
                                                                                </th>
                                                                                <th className="tb-col-os">
                                                                                    <span
                                                                                        className="overline-title">  Type  </span>
                                                                                </th>
                                                                                <th className="tb-col-os">
                                                                                    <span className="overline-title">   Unique Link   </span>
                                                                                </th>
                                                                                <th className="tb-col-os">
                                                                                    <span
                                                                                        className="overline-title">   Copy    </span>
                                                                                </th>
                                                                                <th className="tb-col-os">
                                                                                    <span className="overline-title">   Creative download Url   </span>
                                                                                </th>
                                                                                <th className="tb-col-action">
                                                                                    <span
                                                                                        className="overline-title">   Preview   </span>
                                                                                </th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {(offer?.creatives?.length) ? offer?.creatives?.map((item) => {
                                                                                return (
                                                                                    <tr key={Math.random()}>
                                                                                        <td className="tb-col-os">{item?.creative_id}</td>
                                                                                        <td className="tb-col-ip">
                                                                                            <span
                                                                                                className="sub-text">{item?.creative_name}</span>
                                                                                        </td>
                                                                                        <td className="tb-col-ip">
                                                                                            <span
                                                                                                className="sub-text">{item?.creative_type.creative_type_name}</span>
                                                                                        </td>
                                                                                        <td className="tb-col-ip">
                                                                    <span className="sub-text">
                                                                              <a href={item?.unique_link}
                                                                                 target={'_blank'}
                                                                                 className='text-primary'>{generateURLAddparam(subIdCx3ads, item?.unique_link)} </a>
                                                                    </span>


                                                                                        </td>
                                                                                        <td>
                                                                                            <Button
                                                                                                className='btn btn-primary btn-sm'
                                                                                                onClick={() => {
                                                                                                    copyToClipboard(generateURLAddparam(subIdCx3ads, item?.unique_link))
                                                                                                }}>Copy</Button>
                                                                                        </td>
                                                                                        <td className="tb-col-ip">
                                                                      <span className="sub-text">
                                                                          {item?.creative_download_url ? <>
                                                                              <a href={item?.creative_download_url}
                                                                                 target={'_blank'}
                                                                                 className='btn btn-primary btn-sm '>Download </a>

                                                                          </> : null}
                                                                    </span>
                                                                                        </td>

                                                                                        <td className="tb-col-action">
                                                                                            <Button
                                                                                                className="btn btn-primary btn-sm "
                                                                                                onClick={() => {
                                                                                                    setiframeUrl(item?.unique_link)
                                                                                                    setModal({
                                                                                                        ...modal,
                                                                                                        html: true
                                                                                                    })
                                                                                                }}>PREVIEW</Button>
                                                                                        </td>

                                                                                    </tr>
                                                                                )
                                                                            }) : (<tr className='text-center'>
                                                                                <td colSpan={5} className='tb-col-os'>Data
                                                                                    not Found
                                                                                </td>
                                                                            </tr>)}
                                                                            </tbody>
                                                                        </table>
                                                                    </Card>
                                                                </Col>
                                                            </Row>

                                                            <Row>


                                                                <Col size={12}>
                                                                    <ul className="data-list is-compact">
                                                                        <li className="data-item">
                                                                            <div className="ata-list-s2">
                                                                                <div className="data-label"> restrictions
                                                                                </div>
                                                                                <div className="data-value">
                                                                                    {offer?.restrictions}
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="data-item">
                                                                            <div className="ata-list-s2">
                                                                                <div
                                                                                    className="data-label"> advertiser_extended_terms
                                                                                </div>
                                                                                <div className="data-value">
                                                                                    {offer?.advertiser_extended_terms}
                                                                                </div>
                                                                            </div>
                                                                        </li>

                                                                    </ul>


                                                                </Col>


                                                            </Row>

                                                        </div>
                                                    </> : <Loader/>
                                                }
                                            </ModalBody>
                                        </Modal>

                                    </Block></> :
                                <>                            <Welcome/>
                                </>}

                </> : <Loader/>}

            </Content>
        </React.Fragment>
    );
};

export default Offer;
