import React, {useEffect, useMemo, useRef, useState} from "react";
import {io} from "socket.io-client";
import {Block} from "../../components/block/Block";
import {Card} from "reactstrap";


const Console = () => {
    const [logs, setLogs] = useState(['λ MICROSERVICE AZURE >']);
    const serverAddress = 'http://65.109.37.27:2222';
    const terminalRef = useRef(null);

    /*
    useMemo(() => {
        // Connect to the WebSocket server
        const socket = io(serverAddress);
        // Event listener for connection
        socket.on('connect', () => {
            //    console.log('Connected to server');
        });
        // Event listener for log messages
        socket.on('log', (log) => {
            // Process and update log messages in the component's state
            setLogs((prevLogs) => [...prevLogs,'λ MICROSERVICE AZURE > '+ log]);
            //  console.log(log)
        });
        // Event listener for disconnection
        socket.on('disconnect', () => {
            //  console.log('Disconnected from server');
        });
        // Clean up the socket connection when the component unmounts
        return () => {
            socket.disconnect();
        };
    }, [serverAddress]);



    // This effect will scroll to the last line whenever logs change.
    useEffect(() => {
        if (terminalRef.current) {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        }
    }, [logs]);


     */
    return (
        <>
            <Card
                // Reference to the terminal div
                className="card text-center align-center justify-content-center align-baseline  bg-black"
            >
                <div
                    ref={terminalRef}
                    className="terminal px-2">
                    {logs.map((log, index) => (
                        <div

                            key={index}
                            className={log.includes("WARN") ? 'warning' : (log.includes('ERROR')) ? 'error' : (log.includes('DEBUG')) ? 'debug' : 'log'}
                        >
                            {log}
                        </div>
                    ))}
                </div>
            </Card>
        </>
    )
}

export default Console
