import React, {useEffect, useMemo, useState} from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../../components/block/Block";
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead, DataTableItem, DataTableRow,
    Icon, PaginationComponent, PreviewAltCard,
    Row,
    RSelect, TooltipComponent
} from "../../../components/Component";
import DatePicker from "react-datepicker";
import {
    Badge,
    Card,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Modal,
    ModalBody,
    UncontrolledDropdown
} from "reactstrap";
import Loader from "../../../layout/loader/Loader";
import {convertDateFormat, convertDateTimeToMMDDYYHHMM, convertUnixTimestampToMMDDYY} from "../../../utils/Utils";
import useAccountApi from "../../../api/account/accountApi";
import LoaderTable from "../../../layout/loader/LoaderTable";
import Welcome from "../../../layout/welcome/Welcome";


const Report = ({ ...props }) => {

    const {
        list,
        filter,
    } = useAccountApi(); //  use  custom hook

    const [loading, setLoading] = useState (false);
    const [loadingTable, setLoadingTable] = useState (false);
    const [data, setData] = useState ([]);
    const [campaings, setCampaings] = useState ([]);
    const [search, setSearch] = useState ('');

    const [account, setAccount] = useState ({});
    const [action, setAction] = useState ('SubAffiliateSummary');
    const [columnsSelected, setColumnselected] = useState ([
        {
            "column": "offer",
            "checked": true,
        },
        {
            "column": "sub1",
            "checked": true,
        },
        {
            "column": "sub2",
            "checked": true,
        },
        {
            "column": "sub3",
            "checked": true,
        },
        {
            "column": "sub4",
            "checked": true,
        },
        {
            "column": "sub5",
            "checked": true,
        }
    ]);

    const [rangeStart, setRangeStart] = useState (new Date ());
    const [rangeEnd, setRangeEnd] = useState (new Date ());
    const [campaing, setCampaing] = useState ("");
    const [clicks, setClicks] = useState ("");
    const [category, setCategory] = useState ("");
    const [groupBy, setGroupBy] = useState ("");
    const [filtering, setFiltering] = useState (false);
    const [grouping, setGrouping] = useState (false);

    const [timeZone, setTimeZone] = useState ({
        "timezone_id": 80,
        "timezone_name": "America New_York",
        "timezone": "America/New_York",
        "utc_offset": "05:00"
    });
    const [meta, setMeta] = useState ({
        "timezones": [
            {
                "timezone_id": 1,
                "timezone_name": "Line Is. Time (Pacific/Kiritimati)",
                "timezone": "Pacific/Kiritimati",
                "utc_offset": "+14:00"
            },
            {
                "timezone_id": 2,
                "timezone_name": "Phoenix Is.Time (Pacific/Enderbury)",
                "timezone": "Pacific/Enderbury",
                "utc_offset": "+13:00"
            },
            {
                "timezone_id": 3,
                "timezone_name": "Tonga Time (Pacific/Tongatapu)",
                "timezone": "Pacific/Tongatapu",
                "utc_offset": "+13:00"
            },
            {
                "timezone_id": 4,
                "timezone_name": "Chatham Standard Time (Pacific/Chatham)",
                "timezone": "Pacific/Chatham",
                "utc_offset": "+13:45"
            },
            {
                "timezone_id": 5,
                "timezone_name": "New Zealand Standard Time (Pacific/Auckland)",
                "timezone": "Pacific/Auckland",
                "utc_offset": "+13:00"
            },
            {
                "timezone_id": 6,
                "timezone_name": "Fiji Time (Pacific/Fiji)",
                "timezone": "Pacific/Fiji",
                "utc_offset": "+12:00"
            },
            {
                "timezone_id": 7,
                "timezone_name": "Petropavlovsk-Kamchatski Time (Asia/Kamchatka)",
                "timezone": "Asia/Kamchatka",
                "utc_offset": "+12:00"
            },
            {
                "timezone_id": 8,
                "timezone_name": "Norfolk Time (Pacific/Norfolk)",
                "timezone": "Pacific/Norfolk",
                "utc_offset": "+12:00"
            },
            {
                "timezone_id": 9,
                "timezone_name": "Lord Howe Standard Time (Australia/Lord_Howe)",
                "timezone": "Australia/Lord_Howe",
                "utc_offset": "+11:00"
            },
            {
                "timezone_id": 10,
                "timezone_name": "Solomon Is. Time (Pacific/Guadalcanal)",
                "timezone": "Pacific/Guadalcanal",
                "utc_offset": "+11:00"
            },
            {
                "timezone_id": 11,
                "timezone_name": "Australian Central Standard Time (Australia/Adelaide)",
                "timezone": "Australia/Adelaide",
                "utc_offset": "+10:30"
            },
            {
                "timezone_id": 12,
                "timezone_name": "Australian Eastern Standard Time (Australia/Sydney)",
                "timezone": "Australia/Sydney",
                "utc_offset": "+11:00"
            },
            {
                "timezone_id": 13,
                "timezone_name": "Australian Eastern Standard Time (Australia/Brisbane)",
                "timezone": "Australia/Brisbane",
                "utc_offset": "+10:00"
            },
            {
                "timezone_id": 14,
                "timezone_name": "Australian Central Standard Time (Australia/Darwin)",
                "timezone": "Australia/Darwin",
                "utc_offset": "+09:30"
            },
            {
                "timezone_id": 15,
                "timezone_name": "Korea Standard Time (Asia/Seoul)",
                "timezone": "Asia/Seoul",
                "utc_offset": "+09:00"
            },
            {
                "timezone_id": 16,
                "timezone_name": "Japan Standard Time (Asia/Tokyo)",
                "timezone": "Asia/Tokyo",
                "utc_offset": "+09:00"
            },
            {
                "timezone_id": 17,
                "timezone_name": "Hong Kong Time (Asia/Hong_Kong)",
                "timezone": "Asia/Hong_Kong",
                "utc_offset": "+08:00"
            },
            {
                "timezone_id": 18,
                "timezone_name": "Malaysia Time (Asia/Kuala_Lumpur)",
                "timezone": "Asia/Kuala_Lumpur",
                "utc_offset": "+08:00"
            },
            {
                "timezone_id": 19,
                "timezone_name": "Philippines Time (Asia/Manila)",
                "timezone": "Asia/Manila",
                "utc_offset": "+08:00"
            },
            {
                "timezone_id": 20,
                "timezone_name": "China Standard Time (Asia/Shanghai)",
                "timezone": "Asia/Shanghai",
                "utc_offset": "+08:00"
            },
            {
                "timezone_id": 21,
                "timezone_name": "Singapore Time (Asia/Singapore)",
                "timezone": "Asia/Singapore",
                "utc_offset": "+08:00"
            },
            {
                "timezone_id": 22,
                "timezone_name": "China Standard Time (Asia/Taipei)",
                "timezone": "Asia/Taipei",
                "utc_offset": "+08:00"
            },
            {
                "timezone_id": 23,
                "timezone_name": "Australian Western Standard Time (Australia/Perth)",
                "timezone": "Australia/Perth",
                "utc_offset": "+08:00"
            },
            {
                "timezone_id": 24,
                "timezone_name": "Indochina Time (Asia/Bangkok)",
                "timezone": "Asia/Bangkok",
                "utc_offset": "+07:00"
            },
            {
                "timezone_id": 25,
                "timezone_name": "Indochina Time (Asia/Ho_Chi_Minh)",
                "timezone": "Asia/Ho_Chi_Minh",
                "utc_offset": "+07:00"
            },
            {
                "timezone_id": 26,
                "timezone_name": "West Indonesia Time (Asia/Jakarta)",
                "timezone": "Asia/Jakarta",
                "utc_offset": "+07:00"
            },
            {
                "timezone_id": 27,
                "timezone_name": "Myanmar Time (Asia/Rangoon)",
                "timezone": "Asia/Rangoon",
                "utc_offset": "+06:30"
            },
            {
                "timezone_id": 28,
                "timezone_name": "Bangladesh Time (Asia/Dhaka)",
                "timezone": "Asia/Dhaka",
                "utc_offset": "+06:00"
            },
            {
                "timezone_id": 29,
                "timezone_name": "Nepal Time (Asia/Kathmandu)",
                "timezone": "Asia/Kathmandu",
                "utc_offset": "+05:45"
            },
            {
                "timezone_id": 30,
                "timezone_name": "India Standard Time (Asia/Colombo)",
                "timezone": "Asia/Colombo",
                "utc_offset": "+05:30"
            },
            {
                "timezone_id": 31,
                "timezone_name": "India Standard Time (Asia/Kolkata)",
                "timezone": "Asia/Kolkata",
                "utc_offset": "+05:30"
            },
            {
                "timezone_id": 32,
                "timezone_name": "Pakistan Time (Asia/Karachi)",
                "timezone": "Asia/Karachi",
                "utc_offset": "+05:00"
            },
            {
                "timezone_id": 33,
                "timezone_name": "Uzbekistan Time (Asia/Tashkent)",
                "timezone": "Asia/Tashkent",
                "utc_offset": "+05:00"
            },
            {
                "timezone_id": 34,
                "timezone_name": "Yekaterinburg Time (Asia/Yekaterinburg)",
                "timezone": "Asia/Yekaterinburg",
                "utc_offset": "+05:00"
            },
            {
                "timezone_id": 35,
                "timezone_name": "Afghanistan Time (Asia/Kabul)",
                "timezone": "Asia/Kabul",
                "utc_offset": "+04:30"
            },
            {
                "timezone_id": 36,
                "timezone_name": "Azerbaijan Summer Time (Asia/Baku)",
                "timezone": "Asia/Baku",
                "utc_offset": "+04:00"
            },
            {
                "timezone_id": 37,
                "timezone_name": "Gulf Standard Time (Asia/Dubai)",
                "timezone": "Asia/Dubai",
                "utc_offset": "+04:00"
            },
            {
                "timezone_id": 38,
                "timezone_name": "Georgia Time (Asia/Tbilisi)",
                "timezone": "Asia/Tbilisi",
                "utc_offset": "+04:00"
            },
            {
                "timezone_id": 39,
                "timezone_name": "Armenia Time (Asia/Yerevan)",
                "timezone": "Asia/Yerevan",
                "utc_offset": "+04:00"
            },
            {
                "timezone_id": 40,
                "timezone_name": "Iran Daylight Time (Asia/Tehran)",
                "timezone": "Asia/Tehran",
                "utc_offset": "+03:30"
            },
            {
                "timezone_id": 41,
                "timezone_name": "East African Time (Africa/Nairobi)",
                "timezone": "Africa/Nairobi",
                "utc_offset": "+03:00"
            },
            {
                "timezone_id": 42,
                "timezone_name": "Arabia Standard Time (Asia/Baghdad)",
                "timezone": "Asia/Baghdad",
                "utc_offset": "+03:00"
            },
            {
                "timezone_id": 43,
                "timezone_name": "Arabia Standard Time (Asia/Kuwait)",
                "timezone": "Asia/Kuwait",
                "utc_offset": "+03:00"
            },
            {
                "timezone_id": 44,
                "timezone_name": "Arabia Standard Time (Asia/Riyadh)",
                "timezone": "Asia/Riyadh",
                "utc_offset": "+03:00"
            },
            {
                "timezone_id": 45,
                "timezone_name": "Moscow Standard Time (Europe/Minsk)",
                "timezone": "Europe/Minsk",
                "utc_offset": "+03:00"
            },
            {
                "timezone_id": 46,
                "timezone_name": "Moscow Standard Time (Europe/Moscow)",
                "timezone": "Europe/Moscow",
                "utc_offset": "+03:00"
            },
            {
                "timezone_id": 47,
                "timezone_name": "Eastern European Summer Time (Africa/Cairo)",
                "timezone": "Africa/Cairo",
                "utc_offset": "+02:00"
            },
            {
                "timezone_id": 48,
                "timezone_name": "Eastern European Summer Time (Asia/Beirut)",
                "timezone": "Asia/Beirut",
                "utc_offset": "+02:00"
            },
            {
                "timezone_id": 49,
                "timezone_name": "Israel Daylight Time (Asia/Jerusalem)",
                "timezone": "Asia/Jerusalem",
                "utc_offset": "+02:00"
            },
            {
                "timezone_id": 50,
                "timezone_name": "Eastern European Summer Time (Europe/Athens)",
                "timezone": "Europe/Athens",
                "utc_offset": "+02:00"
            },
            {
                "timezone_id": 51,
                "timezone_name": "Eastern European Summer Time (Europe/Bucharest)",
                "timezone": "Europe/Bucharest",
                "utc_offset": "+02:00"
            },
            {
                "timezone_id": 52,
                "timezone_name": "Eastern European Summer Time (Europe/Helsinki)",
                "timezone": "Europe/Helsinki",
                "utc_offset": "+02:00"
            },
            {
                "timezone_id": 53,
                "timezone_name": "Eastern European Summer Time (Europe/Istanbul)",
                "timezone": "Europe/Istanbul",
                "utc_offset": "+03:00"
            },
            {
                "timezone_id": 54,
                "timezone_name": "South Africa Standard Time (Africa/Johannesburg)",
                "timezone": "Africa/Johannesburg",
                "utc_offset": "+02:00"
            },
            {
                "timezone_id": 55,
                "timezone_name": "Central European Summer Time (Europe/Amsterdam)",
                "timezone": "Europe/Amsterdam",
                "utc_offset": "+01:00"
            },
            {
                "timezone_id": 56,
                "timezone_name": "Central European Summer Time (Europe/Berlin)",
                "timezone": "Europe/Berlin",
                "utc_offset": "+01:00"
            },
            {
                "timezone_id": 57,
                "timezone_name": "Central European Summer Time (Europe/Brussels)",
                "timezone": "Europe/Brussels",
                "utc_offset": "+01:00"
            },
            {
                "timezone_id": 58,
                "timezone_name": "Central European Summer Time (Europe/Paris)",
                "timezone": "Europe/Paris",
                "utc_offset": "+01:00"
            },
            {
                "timezone_id": 59,
                "timezone_name": "Central European Summer Time (Europe/Prague)",
                "timezone": "Europe/Prague",
                "utc_offset": "+01:00"
            },
            {
                "timezone_id": 60,
                "timezone_name": "Central European Summer Time (Europe/Rome)",
                "timezone": "Europe/Rome",
                "utc_offset": "+01:00"
            },
            {
                "timezone_id": 61,
                "timezone_name": "Western European Summer Time (Europe/Lisbon)",
                "timezone": "Europe/Lisbon",
                "utc_offset": "+00:00"
            },
            {
                "timezone_id": 62,
                "timezone_name": "Central European Time (Africa/Algiers)",
                "timezone": "Africa/Algiers",
                "utc_offset": "+01:00"
            },
            {
                "timezone_id": 63,
                "timezone_name": "British Summer Time (Europe/London)",
                "timezone": "Europe/London",
                "utc_offset": "+00:00"
            },
            {
                "timezone_id": 64,
                "timezone_name": "Cape Verde Time (Atlantic/Cape_Verde)",
                "timezone": "Atlantic/Cape_Verde",
                "utc_offset": "-01:00"
            },
            {
                "timezone_id": 65,
                "timezone_name": "Western European Time (Africa/Casablanca)",
                "timezone": "Africa/Casablanca",
                "utc_offset": "+01:00"
            },
            {
                "timezone_id": 66,
                "timezone_name": "Irish Summer Time (Europe/Dublin)",
                "timezone": "Europe/Dublin",
                "utc_offset": "+00:00"
            },
            {
                "timezone_id": 67,
                "timezone_name": "Greenwich Mean Time (UTC)",
                "timezone": "UTC",
                "utc_offset": "+00:00"
            },
            {
                "timezone_id": 68,
                "timezone_name": "Eastern Greenland Summer Time (America/Scoresbysund)",
                "timezone": "America/Scoresbysund",
                "utc_offset": "-01:00"
            },
            {
                "timezone_id": 69,
                "timezone_name": "Azores Summer Time (Atlantic/Azores)",
                "timezone": "Atlantic/Azores",
                "utc_offset": "-01:00"
            },
            {
                "timezone_id": 70,
                "timezone_name": "South Georgia Standard Time (Atlantic/South_Georgia)",
                "timezone": "Atlantic/South_Georgia",
                "utc_offset": "-02:00"
            },
            {
                "timezone_id": 71,
                "timezone_name": "Newfoundland Daylight Time (America/St_Johns)",
                "timezone": "America/St_Johns",
                "utc_offset": "-02:30"
            },
            {
                "timezone_id": 72,
                "timezone_name": "Brasilia Summer Time (America/Sao_Paulo)",
                "timezone": "America/Sao_Paulo",
                "utc_offset": "-03:00"
            },
            {
                "timezone_id": 73,
                "timezone_name": "Argentina Time (America/Argentina/Buenos_Aires)",
                "timezone": "America/Argentina/Buenos_Aires",
                "utc_offset": "-03:00"
            },
            {
                "timezone_id": 74,
                "timezone_name": "Chile Summer Time (America/Santiago)",
                "timezone": "America/Santiago",
                "utc_offset": "-03:00"
            },
            {
                "timezone_id": 75,
                "timezone_name": "Atlantic Daylight Time (America/Halifax)",
                "timezone": "America/Halifax",
                "utc_offset": "-03:00"
            },
            {
                "timezone_id": 76,
                "timezone_name": "Atlantic Standard Time (America/Puerto_Rico)",
                "timezone": "America/Puerto_Rico",
                "utc_offset": "-04:00"
            },
            {
                "timezone_id": 77,
                "timezone_name": "Atlantic Daylight Time (Atlantic/Bermuda)",
                "timezone": "Atlantic/Bermuda",
                "utc_offset": "-03:00"
            },
            {
                "timezone_id": 78,
                "timezone_name": "Venezuela Time (America/Caracas)",
                "timezone": "America/Caracas",
                "utc_offset": "-04:00"
            },
            {
                "timezone_id": 79,
                "timezone_name": "Eastern Daylight Time (America/Indiana/Indianapolis)",
                "timezone": "America/Indiana/Indianapolis",
                "utc_offset": "-04:00"
            },
            {
                "timezone_id": 80,
                "timezone_name": "Eastern Daylight Time (America/New_York)",
                "timezone": "America/New_York",
                "utc_offset": "-04:00"
            },
            {
                "timezone_id": 81,
                "timezone_name": "Colombia Time (America/Bogota)",
                "timezone": "America/Bogota",
                "utc_offset": "-05:00"
            },
            {
                "timezone_id": 82,
                "timezone_name": "Peru Time (America/Lima)",
                "timezone": "America/Lima",
                "utc_offset": "-05:00"
            },
            {
                "timezone_id": 83,
                "timezone_name": "Eastern Standard Time (America/Panama)",
                "timezone": "America/Panama",
                "utc_offset": "-05:00"
            },
            {
                "timezone_id": 84,
                "timezone_name": "Central Daylight Time (America/Mexico_City)",
                "timezone": "America/Mexico_City",
                "utc_offset": "-06:00"
            },
            {
                "timezone_id": 85,
                "timezone_name": "Central Daylight Time (America/Chicago)",
                "timezone": "America/Chicago",
                "utc_offset": "-05:00"
            },
            {
                "timezone_id": 86,
                "timezone_name": "Central Standard Time (America/El_Salvador)",
                "timezone": "America/El_Salvador",
                "utc_offset": "-06:00"
            },
            {
                "timezone_id": 87,
                "timezone_name": "Mountain Daylight Time (America/Denver)",
                "timezone": "America/Denver",
                "utc_offset": "-06:00"
            },
            {
                "timezone_id": 88,
                "timezone_name": "Mountain Standard Time (America/Mazatlan)",
                "timezone": "America/Mazatlan",
                "utc_offset": "-07:00"
            },
            {
                "timezone_id": 89,
                "timezone_name": "Mountain Standard Time (America/Phoenix)",
                "timezone": "America/Phoenix",
                "utc_offset": "-07:00"
            },
            {
                "timezone_id": 90,
                "timezone_name": "Pacific Daylight Time (America/Los_Angeles)",
                "timezone": "America/Los_Angeles",
                "utc_offset": "-07:00"
            },
            {
                "timezone_id": 91,
                "timezone_name": "Pacific Daylight Time (America/Tijuana)",
                "timezone": "America/Tijuana",
                "utc_offset": "-07:00"
            },
            {
                "timezone_id": 92,
                "timezone_name": "Pitcairn Standard Time (Pacific/Pitcairn)",
                "timezone": "Pacific/Pitcairn",
                "utc_offset": "-08:00"
            },
            {
                "timezone_id": 93,
                "timezone_name": "Alaska Daylight Time (America/Anchorage)",
                "timezone": "America/Anchorage",
                "utc_offset": "-08:00"
            },
            {
                "timezone_id": 94,
                "timezone_name": "Gambier Time (Pacific/Gambier)",
                "timezone": "Pacific/Gambier",
                "utc_offset": "-09:00"
            },
            {
                "timezone_id": 95,
                "timezone_name": "Hawaii-Aleutian Standard Time (America/Adak)",
                "timezone": "America/Adak",
                "utc_offset": "-09:00"
            },
            {
                "timezone_id": 96,
                "timezone_name": "Marquesas Time (Pacific/Marquesas)",
                "timezone": "Pacific/Marquesas",
                "utc_offset": "-09:30"
            },
            {
                "timezone_id": 97,
                "timezone_name": "Hawaii-Aleutian Standard Time (Pacific/Honolulu)",
                "timezone": "Pacific/Honolulu",
                "utc_offset": "-10:00"
            },
            {
                "timezone_id": 98,
                "timezone_name": "Niue Time (Pacific/Niue)",
                "timezone": "Pacific/Niue",
                "utc_offset": "-11:00"
            },
            {
                "timezone_id": 99,
                "timezone_name": "Samoa Standard Time (Pacific/Pago_Pago)",
                "timezone": "Pacific/Pago_Pago",
                "utc_offset": "-11:00"
            }
        ]
    });

    //
    const [currentPage, setCurrentPage] = useState (1);
    const [itemPerPage, setItemPerPage] = useState (30);
    const [totalItems, setTotalItems] = useState (10);
    const [sort, setSortState] = useState ("desc");
    const [sortCol, setSorCol] = useState ("id");
    const [run, setRun] = useState (true);
    let result;

    // unselects the data on mount
    useMemo(async () => {
        let result = await list()
        if (result.success) {
            setData(result.data.data)
        }


        setLoading(true)


    }, []);



    useEffect (async () => {
        setLoadingTable(true)
        setCampaings([])

        if(account?.type ==='ClixFlow'){
            result = await filter({
                account:account,
                action:'report' ,
                type: 'ClixFlow',
                group_by: groupBy,
                search: search,
                grouping: grouping,
                filtering: filtering,
                start: `${rangeStart.getMonth() + 1}/${rangeStart.getDate()}/${rangeStart.getFullYear()}`,
                end: `${rangeEnd.getMonth() + 1}/${rangeEnd.getDate()}/${rangeEnd.getFullYear()}`,
                order:sortCol,
                direction:sort,
                currentPage: currentPage,
                itemPerPage: itemPerPage,
            });
            if (result.success) {
                setCampaings(result.data.data)
            }
        }
        if(account?.type ==='B2direct'){
            await initialB2direct()

        }
        if (account?.type ==='Cx3ads'){
            result = await filter({
                account:account,
                type: 'Cx3ads',
                action:action,
                search:search ,
                start: `${rangeStart.getMonth() + 1}/${rangeStart.getDate()}/${rangeStart.getFullYear()}`,
                end: `${rangeEnd.getMonth() + 1}/${rangeEnd.getDate() +1}/${rangeEnd.getFullYear()}`,
                start_at_row : 1,
                row_limit : 0,
            });
            if (result.success) {
                setCampaings(result.data.data)
            }

        }

        setLoadingTable(false)

    }, [account,action,timeZone,grouping,sort,filtering,rangeStart, rangeEnd, groupBy, currentPage,run]);

    const initialB2direct= async () => {
        result = await filter({
            account: account,
            type: 'B2direct',
            action: 'report',
            resource_type: '',
            timezone_id: timeZone?.timezone_id,
            filter_id_value: search,
            columns: columnsSelected.filter(item => item.checked),

            start: `${rangeStart.getFullYear()}-${rangeStart.getMonth() + 1}-${rangeStart.getDate()}`,
            end: `${rangeEnd.getFullYear()}-${rangeEnd.getMonth() + 1}-${rangeEnd.getDate()}`,
            order: 'id',
            direction: 'asc',
        });
        if (result.success) {
            setCampaings(result.data)
        }
    }

    const NumberFormatEnUS = (number = 0) => {

        return new Intl.NumberFormat ("en-US", {
            style: "currency",
            currency: "USD"
        }).format (number);

    };


    // Change Page
    const paginate = (currentPage) => setCurrentPage (currentPage);

    //
    const [modal, setModal] = useState({
        B2direct: false,
        ClixFlow: false,

    });
    const [loadingOffer, setLoadingOffer] = useState(false);
    const toggleColumnChecked = (column) => {
        const updatedColumns = columnsSelected.map(item =>
            item.column === column ? { ...item, checked: !item.checked } : item
        );
        setColumnselected(updatedColumns);
    };

    const     offerDetaillesClicks = async (item) => {

        setLoadingOffer(true)
        setClicks([])
        if(account?.type ==='ClixFlow'){
            setModal({ClixFlow: true})

            result = await filter({
                account:account,
                action:'clicks' ,
                type: 'ClixFlow',
                id: item.id,
                group_by: groupBy,
                start: `${rangeStart.getMonth() + 1}/${rangeStart.getDate()}/${rangeStart.getFullYear()}`,
                end: `${rangeEnd.getMonth() + 1}/${rangeEnd.getDate()}/${rangeEnd.getFullYear()}`,
            });
            if (result.success) {
                setClicks(result.data.data)
                setLoadingOffer(false)
            }
        }

        if(account?.type ==='B2direct'){
            setModal({B2direct: true})

            result = await filter({
                account: account,
                type: 'B2direct',
                action: 'click',
                start: `${rangeStart.getFullYear()}-${rangeStart.getMonth() + 1}-${rangeStart.getDate()}`,
                end: `${rangeEnd.getFullYear()}-${rangeEnd.getMonth() + 1}-${rangeEnd.getDate()}`,
                filter_id_value: search,
                columns: 'offer',
                order: 'id',
                direction: 'asc',
            });
            if (result.success) {
                setClicks(result.data.clicks)
                setLoadingOffer(false)

            }
        }

    };
    const     offerDetaillesCV = async (item) => {

        setLoadingOffer(true)
        setClicks([])
        if(account?.type ==='ClixFlow'){
            setModal({ClixFlow: true})

            result = await filter({
                account:account,
                action:'cv' ,
                type: 'ClixFlow',
                id: item.id,
                group_by: groupBy,
                start: `${rangeStart.getMonth() + 1}/${rangeStart.getDate()}/${rangeStart.getFullYear()}`,
                end: `${rangeEnd.getMonth() + 1}/${rangeEnd.getDate()}/${rangeEnd.getFullYear()}`,
            });
            if (result.success) {
                setClicks(result.data.data)
                setLoadingOffer(false)
            }
        }

        if(account?.type ==='B2direct'){
            setModal({B2direct: true})

            result = await filter({
                account: account,
                type: 'B2direct',
                action: 'cv',
                start: `${rangeStart.getFullYear()}-${rangeStart.getMonth() + 1}-${rangeStart.getDate()}`,
                end: `${rangeEnd.getFullYear()}-${rangeEnd.getMonth() + 1}-${rangeEnd.getDate()}`,
                columns: '',
                filter_id: '',
                order: 'id',
                direction: 'asc',
            });
            if (result.success) {
                setClicks(result.data.clicks)
                setLoadingOffer(false)

            }
        }

    };

    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };

    const fillterhandel = async () => {
        if (onSearchText !== "") {
            if(account?.type ==='ClixFlow'){
                 columnsSelected.map(item => {
                    if (item.column !== 'offer' && item.checked === true){
                        return {
                            filter_id_value: onSearchText,
                            resource_type: item.column,
                        }
                    }
                })
            }
            if(account?.type ==='B2direct'){
                result = await filter({
                    account:account,
                    type: 'B2direct',
                    action:'report' ,
                    resource_type: 'sub1',
                    filter_id_value: onSearchText,
                    start: `${rangeStart.getFullYear()}-${rangeStart.getMonth()+ 1}-${rangeStart.getDate()}`,
                    end: `${rangeEnd.getFullYear()}-${rangeEnd.getMonth()+ 1}-${rangeEnd.getDate()}`,
                    columns: columnsSelected.filter(item => item.checked),
                    order: 'id',
                    direction: 'asc',
                });
                if (result.success) {
                    setCampaings(result.data)
                }

            }

            if(account?.type ==='Cx3ads'){

            }
        }
    }

    const toggle = () => {
        setonSearch (!onSearch);
    };


    const sortByKey= (arr, key)=> {
        return arr.sort((a, b) => {
            if (typeof a[key] === 'string') {
                return a[key].localeCompare(b[key]);
            }
            return a[key] - b[key];
        });
    }

    const handleSort = (column) => {
        if (sort ==='desc')setSortState('asc');
        else setSortState('desc')
        setSorCol(column)
    };



    return (
        <React.Fragment>
            <Head title="Campaigns SubID Report"></Head>
            <Content>
                {loading ? <>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle page>SubID Report {account?.name?` ( ${account?.name} ) `:''}</BlockTitle>
                                <BlockDes className="text-soft">
                                    <p>Campaigns Report.</p>
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <ul className="nk-block-tools g-3">
                                    <li className="nk-block-tools-opt ">
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="transparent" className="btn btn-white btn-outline-light dropdown-toggle">
                                                <Icon name="building" />
                                                <span>{account?.name ? account?.name : "Accounts"}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <ul className="link-list-opt no-bdr">
                                                    {data?.map ((item) => {
                                                        return (<li key={`account-${item.id}`}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href={`#${item.name}`}
                                                                onClick={(ev) => {
                                                                    ev.preventDefault ();
                                                                    setAccount (item);
                                                                    setCampaings([])
                                                                }}
                                                            >
                                                                <Icon name="building"></Icon>
                                                                <span>{item.name}</span>
                                                            </DropdownItem>
                                                        </li>);
                                                    })}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>

                                    <li>
                                        <div className="form-group">
                                            <div className="form-control-wrap">
                                                <div className="input-daterange date-picker-range input-group">
                                                    <DatePicker
                                                        selected={rangeStart}
                                                        onChange={setRangeStart}
                                                        selectsStart
                                                        startDate={rangeStart}
                                                        endDate={rangeEnd}
                                                        wrapperClassName="start-m"
                                                        className="form-control"
                                                    />{" "}
                                                    <div className="input-group-addon">TO</div>
                                                    <DatePicker
                                                        selected={rangeEnd}
                                                        onChange={setRangeEnd}
                                                        startDate={rangeStart}
                                                        endDate={rangeEnd}
                                                        selectsEnd
                                                        minDate={rangeStart}
                                                        wrapperClassName="end-m"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    {(account?.type === "ClixFlow") ? <>

                                    </> :
                                        (account?.type === "B2direct") ? <>
                                        {columnsSelected.map ((col, index) => {
                                            return <li key={`col-${index}`}>
                                                <div className="form-group">
                                                    <div className="custom-control custom-radio my-3 checked ">
                                                        <input type="checkbox" className="custom-control-input "
                                                               onChange={() => toggleColumnChecked(col.column)} name={col.column} id={col.column} checked={col.checked} />
                                                        <label className="custom-control-label form-label" htmlFor={col.column}>
                                                            {col.column}
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>;
                                        })}
                                    </> :  (account?.type === "Cx3ads") ? <>
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="transparent" className="btn btn-white btn-outline-light dropdown-toggle">
                                                    <Icon name="crosshair" />
                                                    <span>{action? action : "Reports"}</span>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <ul className="link-list-opt no-bdr">
                                                        <li >
                                                            <DropdownItem
                                                                tag="a"
                                                                href={`#`}
                                                                onClick={(ev) => {
                                                                    ev.preventDefault ();
                                                                    setAction('SubAffiliateSummary')
                                                                }}
                                                            >
                                                                <span>Sub Affiliate Summary </span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li >
                                                            <DropdownItem
                                                                tag="a"
                                                                href={`#`}
                                                                onClick={(ev) => {
                                                                    ev.preventDefault ();
                                                                    setAction('clicks')

                                                                }}
                                                            >
                                                                <span>Clicks</span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li >
                                                            <DropdownItem
                                                                tag="a"
                                                                href={`#`}
                                                                onClick={(ev) => {
                                                                    ev.preventDefault ();
                                                                    setAction('conversions')
                                                                    setCampaing([])
                                                                }}
                                                            >

                                                                <span>Conversions </span>
                                                            </DropdownItem>
                                                        </li>

                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <li>
                                                <input className="form-control form-control-sm"  onChange={(e)=>setSearch(e.target.value)} placeholder='Search Sub ID ...'/>
                                            </li>
                                        </>:null}


                                    <li>
                                        <a
                                            id="id"
                                            href="#run"
                                            onClick={() => {
                                                    setRun(!run)
                                            }}
                                            className="btn btn-sm btn-white btn-outline-light"
                                        >
                                            <Icon name="reload-alt" />
                                            <span>Run</span>
                                        </a>
                                    </li>
                                </ul>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                    {(account?.type === "ClixFlow") ? <>
                            <Block>
                                <DataTable className="card-stretch">
                                    <div className="card-inner">
                                        <Row className='align-items-center'>
                                            <Col size={6}>

                                                <div className="card-title-group">
                                                    <div className="card-title">
                                                        <h5 className="title">Campaigns Report</h5>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col size={2} className='text-end d-flex flex-column '>
                                                <div className="custom-control custom-switch py-1">
                                                    <input type="checkbox" checked={filtering} onChange={()=>{setFiltering(!filtering)}} className="custom-control-input" defaultChecked id="customSwitch1"/>
                                                    <label className="custom-control-label" htmlFor="customSwitch1">Filtering</label>
                                                </div>
                                                <div className="custom-control custom-switch py-1">
                                                    <input type="checkbox" checked={grouping} className="custom-control-input" onChange={()=>{setGrouping(!grouping)}} defaultChecked id="customSwitch2"/>
                                                    <label className="custom-control-label" htmlFor="customSwitch2">Group Campaigns</label>
                                                </div>
                                            </Col>

                                            <Col size={2} className='align-content-center'>

                                                <ul className="nk-block-tools g-1">

                                                    <li>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-radio my-3 checked ">
                                                                <input type="radio" className="custom-control-input " onChange={() => setGroupBy ("c1")}
                                                                       name="cCheckbox" id="c1Checkbox" />
                                                                <label className="custom-control-label form-label" htmlFor="c1Checkbox">
                                                                    C1
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-radio my-3 checked ">
                                                                <input type="radio" className="custom-control-input " onChange={() => setGroupBy ("c2")}
                                                                       name="cCheckbox" id="c2Checkbox" />
                                                                <label className="custom-control-label form-label" htmlFor="c2Checkbox">
                                                                    C2
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-radio my-3 checked ">
                                                                <input type="radio" className="custom-control-input " onChange={() => setGroupBy ("c3")}
                                                                       name="cCheckbox" id="c3Checkbox" />
                                                                <label className="custom-control-label form-label" htmlFor="c3Checkbox">
                                                                    C3
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-radio my-3 checked ">
                                                                <input type="radio" className="custom-control-input " name="cCheckbox"
                                                                       onChange={() => setGroupBy ("c1,c2,c3")} id="AllCheckbox" />
                                                                <label className="custom-control-label form-label" htmlFor="AllCheckbox">
                                                                    All
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>


                                            </Col>

                                            <Col size={2}>
                                                <input className="form-control form-control-sm"  onChange={(e)=>setSearch(e.target.value)} placeholder='Search C1, C2 and C3 '/>
                                            </Col>

                                        </Row>

                                    </div>
                                    <DataTableBody bodyclass="nk-tb-tnx" >
                                        <DataTableHead>
                                            <DataTableRow >
                                                <span className="tb-lead " onClick={() => handleSort('id')} role="button" >
                                                    <span className='px-1'>
                                                        <span className={sortCol ==='id' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='id' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>

                                                    Id
                                                </span>

                                            </DataTableRow>
                                            <DataTableRow>

                                                <span className="tb-lead"  onClick={() => handleSort('name')}  role="button" >
                                                      <span className='px-1'>
                                                        <span className={sortCol ==='name' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='name' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                    Campaign Name
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow >
                                                <span className="tb-lead" onClick={() => handleSort('c1')}  role="button" >
                                                       <span className='px-1'>
                                                        <span className={sortCol ==='c1' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='c1' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                    C1
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow >
                                                <span className="tb-lead" onClick={() => handleSort('c2')}  role="button" >
                                                      <span className='px-1'>
                                                        <span className={sortCol ==='c2' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='c2' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                    C2 </span>
                                            </DataTableRow>
                                            <DataTableRow >
                                                <span className="tb-lead"  onClick={() => handleSort('c3')}  role="button" >
                                                      <span className='px-1'>
                                                        <span className={sortCol ==='c3' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='c3' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                    C3 </span>
                                            </DataTableRow>

                                            <DataTableRow >
                                                <span className="tb-lead" onClick={() => handleSort('clicks')}  role="button" >
                                                     <span className='px-1'>
                                                        <span className={sortCol ==='clicks' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='clicks' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                    Clicks</span>
                                            </DataTableRow>
                                            <DataTableRow >
                                                <span className="tb-lead" onClick={() => handleSort('sales')}  role="button" >
                                                      <span className='px-1'>
                                                        <span className={sortCol ==='sales' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='sales' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                    Sales</span>
                                            </DataTableRow>
                                            <DataTableRow >
                                                <span className="tb-lead" onClick={() => handleSort('conv')}  role="button" >
                                                      <span className='px-1'>
                                                        <span className={sortCol ==='conv' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='conv' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                    Conv</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-lead"  onClick={() => handleSort('epc')}  role="button" >
                                                      <span className='px-1'>
                                                        <span className={sortCol ==='epc' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='epc' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                    EPC</span>
                                            </DataTableRow>
                                            <DataTableRow >
                                                <span className="tb-lead" onClick={() => handleSort('earnings')}  role="button" >
                                                      <span className='px-1'>
                                                        <span className={sortCol ==='earnings' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                        <span className={sortCol ==='earnings' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                    </span>
                                                    Earnings</span>
                                            </DataTableRow>

                                        </DataTableHead>
                                        {campaings?.length ? campaings?.map ((item, index) => {
                                            return (<>
                                                    <DataTableItem key={index}>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{item?.id}</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead text-primary">{item?.name}</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type-text">
                                                                <span className="tb-lead text-primary">{item?.c1}</span>
                                                            </div>

                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type-text">
                                                                <span className="tb-lead text-primary">{item?.c2}</span>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type-text">
                                                                <span className="tb-lead text-primary">{item?.c3}</span>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <a
                                                                    href="#offerDetailles"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        offerDetaillesClicks(item)
                                                                    }}>
                                                                    <span className="tb-lead text-primary">{item?.clicks}</span>

                                                                </a>
                                                                <div className="nk-tnx-type-text">
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <a
                                                                    href="#offerDetailles"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        offerDetaillesCV(item)
                                                                    }}>
                                                                    <span className="tb-lead text-primary">{item?.sales}</span>

                                                                </a>
                                                                <div className="nk-tnx-type-text">
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{item?.conv} %</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{NumberFormatEnUS (item?.epc)} </span>

                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{NumberFormatEnUS (item?.earned)} </span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                    </DataTableItem>
                                                </>
                                            );

                                        }) : null}
                                        {campaings?.length ? <>
                                            <DataTableItem className="bg-gray-200">
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">TOTAL </span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size="xl">
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size="xl">
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size="xl">
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size="xl">
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead"></span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                            <span
                                className="tb-lead">{campaings?.reduce ((accumulator, item) => accumulator + item.clicks, 0)}</span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                            <span
                                className="tb-lead">{campaings?.reduce ((accumulator, item) => accumulator + item.sales, 0)}</span>

                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                            <span
                                className="tb-lead">{campaings?.reduce ((accumulator, item) => accumulator + item.conversion, 0)} %</span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                            <span
                                className="tb-lead">{NumberFormatEnUS (campaings?.reduce ((accumulator, item) => accumulator + item.epc, 0))} </span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                            <span
                                className="tb-lead">{NumberFormatEnUS (campaings?.reduce ((accumulator, item) => accumulator + item.earned, 0))} </span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>

                                            </DataTableItem>
                                        </> : null}
                                    </DataTableBody>
                                    <div className="card-inner">
                                        {campaings?.length ?
                                            <PaginationComponent
                                                itemPerPage={itemPerPage}
                                                totalItems={totalItems}
                                                paginate={paginate}
                                                currentPage={currentPage}
                                            /> :
                                            !loadingTable? <><div className="text-center">
                                                    <span className="text-silent">No data found</span>
                                                </div></>:<><LoaderTable/></>


                                        }
                                    </div>
                                </DataTable>
                                <Modal isOpen={modal.ClixFlow} toggle={() => setModal({ ClixFlow: false })} className="modal-dialog-centered" size="xl" style={{width:"90%",minWidth:'90%'}}>
                                    <ModalBody>
                                        {
                                            !loadingOffer? <>
                                                <DataTable className="card-stretch">
                                                    <div className="card-inner">
                                                        <div className="card-title-group">
                                                            <div className="card-title">
                                                                <h5 className="title">Report </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <DataTableBody bodyclass="nk-tb-tnx">
                                                        <DataTableHead className="text-uppercase">
                                                            <DataTableRow>
                                                                <span className="tb-lead">Date</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead"> IP </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">C1</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">C2</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">C3</span>
                                                            </DataTableRow>

                                                            <DataTableRow>
                                                                <span className="tb-lead">Clicks </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">Salse</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">Earnings</span>
                                                            </DataTableRow>
                                                        </DataTableHead>
                                                        {clicks?.length ?
                                                            clicks?.map ((item, index) => {
                                                                return (
                                                                    <DataTableItem key={index}>
                                                                        <DataTableRow>
                                                                            <Badge className='badge-sm badge-dim'>

                                                                                <span className="tb-lead">{item?.hitdate??item?.saledate}</span>
                                                                            </Badge>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead text-primary"> {item?.ip} </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.c1} </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.c2} </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.c3} </span>
                                                                        </DataTableRow>

                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.hits} </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.sales} </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.earnings} </span>
                                                                        </DataTableRow>
                                                                    </DataTableItem>
                                                                )}):null }
                                                    </DataTableBody>
                                                    <div className="card-inner">
                                                        { clicks?.length ? null:<><LoaderTable/></>}
                                                    </div>
                                                </DataTable>

                                            </>:<Loader/>
                                        }
                                    </ModalBody>
                                </Modal>

                            </Block>
                        </> :
                        (account?.type === "B2direct") ? <>
                                <Block>
                                    <Row className="mb-3">
                                        <Col size={2}>
                                            <PreviewAltCard className="card-full">
                                                <div className="card-title-group align-start mb-0">
                                                    <div className="card-title">
                                                        <h6 className="subtitle">Clicks</h6>
                                                    </div>
                                                    <div className="card-tools">
                                                        <TooltipComponent
                                                            iconClass="card-hint"
                                                            icon="help-fill"
                                                            direction="left"
                                                            id="total_click"
                                                            text="total_click"
                                                        ></TooltipComponent>
                                                    </div>
                                                </div>
                                                <div className="card-amount">
                  <span className="amount">
                    {campaings?.summary?.total_click} <span className="currency currency-usd"></span>
                  </span>
                                                </div>

                                            </PreviewAltCard>
                                        </Col>
                                        <Col size={2}>
                                            <PreviewAltCard className="card-full">
                                                <div className="card-title-group align-start mb-0">
                                                    <div className="card-title">
                                                        <h6 className="subtitle">CV</h6>
                                                    </div>
                                                    <div className="card-tools">
                                                        <TooltipComponent
                                                            iconClass="card-hint"
                                                            icon="help-fill"
                                                            direction="left"
                                                            id="cv"
                                                            text="cv"
                                                        ></TooltipComponent>
                                                    </div>
                                                </div>
                                                <div className="card-amount">
                  <span className="amount">
                    {campaings?.summary?.cv} <span className="currency currency-usd"></span>
                  </span>
                                                </div>

                                            </PreviewAltCard>
                                        </Col>
                                        <Col size={2}>
                                            <PreviewAltCard className="card-full">
                                                <div className="card-title-group align-start mb-0">
                                                    <div className="card-title">
                                                        <h6 className="subtitle">VT Convs</h6>
                                                    </div>
                                                    <div className="card-tools">
                                                        <TooltipComponent
                                                            iconClass="card-hint"
                                                            icon="help-fill"
                                                            direction="left"
                                                            id="view_through_cv"
                                                            text="view_through_cv"
                                                        ></TooltipComponent>
                                                    </div>
                                                </div>
                                                <div className="card-amount">
                  <span className="amount">
                   {campaings?.summary?.view_through_cv} <span className="currency currency-usd"></span>
                  </span>
                                                </div>

                                            </PreviewAltCard>
                                        </Col>
                                        <Col size={2}>
                                            <PreviewAltCard className="card-full">
                                                <div className="card-title-group align-start mb-0">
                                                    <div className="card-title">
                                                        <h6 className="subtitle">Event</h6>
                                                    </div>
                                                    <div className="card-tools">
                                                        <TooltipComponent
                                                            iconClass="card-hint"
                                                            icon="help-fill"
                                                            direction="left"
                                                            id="Event"
                                                            text="Event"
                                                        ></TooltipComponent>
                                                    </div>
                                                </div>
                                                <div className="card-amount">
                  <span className="amount">
                     {campaings?.summary?.event} <span className="currency currency-usd"></span>
                  </span>
                                                </div>

                                            </PreviewAltCard>
                                        </Col>
                                        <Col size={2}>
                                            <PreviewAltCard className="card-full">
                                                <div className="card-title-group align-start mb-0">
                                                    <div className="card-title">
                                                        <h6 className="subtitle">EVR</h6>
                                                    </div>
                                                    <div className="card-tools">
                                                        <TooltipComponent
                                                            iconClass="card-hint"
                                                            icon="help-fill"
                                                            direction="left"
                                                            id="EVR"
                                                            text="EVR"
                                                        ></TooltipComponent>
                                                    </div>
                                                </div>
                                                <div className="card-amount">
                  <span className="amount">
                    {campaings?.summary?.evr} <span className="currency currency-usd"></span>
                  </span>
                                                </div>

                                            </PreviewAltCard>
                                        </Col>
                                        <Col size={2}>
                                            <PreviewAltCard className="card-full">
                                                <div className="card-title-group align-start mb-0">
                                                    <div className="card-title">
                                                        <h6 className="subtitle">CVR</h6>
                                                    </div>
                                                    <div className="card-tools">
                                                        <TooltipComponent
                                                            iconClass="card-hint"
                                                            icon="help-fill"
                                                            direction="left"
                                                            id="CVR"
                                                            text="CVR"
                                                        ></TooltipComponent>
                                                    </div>
                                                </div>
                                                <div className="card-amount">
                  <span className="amount">
                    {campaings?.summary?.cvr}<span className="currency currency-usd"></span>
                  </span>
                                                </div>

                                            </PreviewAltCard>
                                        </Col>

                                    </Row>
                                    <Row className="mb-3">
                                        <Col size={4}>
                                            <PreviewAltCard className="card-full">
                                                <div className="card-title-group align-start mb-0">
                                                    <div className="card-title">
                                                        <h6 className="subtitle">RPC</h6>
                                                    </div>
                                                    <div className="card-tools">
                                                        <TooltipComponent
                                                            iconClass="card-hint"
                                                            icon="help-fill"
                                                            direction="left"
                                                            id="RPC"
                                                            text="RPC"
                                                        ></TooltipComponent>
                                                    </div>
                                                </div>
                                                <div className="card-amount">
                  <span className="amount">
                     {NumberFormatEnUS (campaings?.summary?.rpc)} <span className="currency currency-usd"></span>
                  </span>
                                                </div>

                                            </PreviewAltCard>
                                        </Col>
                                        <Col size={4}>
                                            <PreviewAltCard className="card-full">
                                                <div className="card-title-group align-start mb-0">
                                                    <div className="card-title">
                                                        <h6 className="subtitle">Revenue</h6>
                                                    </div>
                                                    <div className="card-tools">
                                                        <TooltipComponent
                                                            iconClass="card-hint"
                                                            icon="help-fill"
                                                            direction="left"
                                                            id="Revenue"
                                                            text="Revenue"
                                                        ></TooltipComponent>
                                                    </div>
                                                </div>
                                                <div className="card-amount">
                  <span className="amount">
                    {NumberFormatEnUS (campaings?.summary?.revenue)} <span className="currency currency-usd"></span>
                  </span>
                                                </div>

                                            </PreviewAltCard>
                                        </Col>
                                        <Col size={4}>
                                            <PreviewAltCard className="card-full">
                                                <div className="card-title-group align-start mb-0">
                                                    <div className="card-title">
                                                        <h6 className="subtitle">Redirect Traffic Rev</h6>
                                                    </div>
                                                    <div className="card-tools">
                                                        <TooltipComponent
                                                            iconClass="card-hint"
                                                            icon="help-fill"
                                                            direction="left"
                                                            id="redirect_traffic_revenue"
                                                            text="redirect_traffic_revenue"
                                                        ></TooltipComponent>
                                                    </div>
                                                </div>
                                                <div className="card-amount">
                  <span className="amount">
                      {NumberFormatEnUS (campaings?.summary?.redirect_traffic_revenue)} <span
                      className="currency currency-usd"></span>
                  </span>
                                                </div>

                                            </PreviewAltCard>
                                        </Col>

                                    </Row>
                                    <DataTable className="card-stretch">
                                        <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <h5 className="title">Detailed Report</h5>
                                                </div>
                                                <div className="card-tools border-0 ">
                                                    <ul className="btn-toolbar gx-1">
                                                        <li>
                                                            <Button
                                                                href="#search"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    toggle();
                                                                }}
                                                                className="btn-icon search-toggle toggle-search"
                                                            >
                                                                <Icon name="search"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li className="btn-toolbar-sep"></li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="a" className="btn  btn-icon dropdown-toggle">
                                                                    <Icon name="building" />
                                                                    <span>{timeZone ? `(GMT ${timeZone?.utc_offset}) ${timeZone?.timezone_name} `: "(GMT+ --:--) ----/----"}</span>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <ul className="link-list-opt no-bdr">

                                                                        {meta?.timezones?.map((item)=>{
                                                                            return <>
                                                                                <li key={item?.timezone_id} className={timeZone === item?.timezone_id ? "active" : ""}>
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setTimeZone(item);
                                                                                        }}
                                                                                    >
                                                                                        (GMT {item?.utc_offset}) {item?.timezone_name}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            </>
                                                                        })}
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li className="btn-toolbar-sep"></li>

                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Filter By</span>
                                                                        </li>
                                                                        <li className={itemPerPage === 10 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={itemPerPage === 30 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(30);
                                                                                }}
                                                                            >
                                                                                30
                                                                            </DropdownItem>

                                                                        </li>
                                                                        <li className={itemPerPage === 50 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(50);
                                                                                }}
                                                                            >
                                                                                50
                                                                            </DropdownItem>

                                                                        </li>
                                                                        <li className={itemPerPage === 100 ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(100);
                                                                                }}
                                                                            >
                                                                                100
                                                                            </DropdownItem>

                                                                        </li>
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={"sort" === "dsc" ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();

                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={"sort" === "asc" ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();

                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>

                                                    </ul>
                                                </div>
                                                    <div className={`card-search search-wrap  ${!onSearch && "active"}`}>
                                                    <div className="search-content">
                                                        <Button
                                                            onClick={() => {
                                                                setSearchText("");
                                                                toggle();

                                                            }}
                                                            className="search-back btn-icon toggle-search"
                                                        >
                                                            <Icon name="arrow-left"></Icon>
                                                        </Button>
                                                        <input
                                                            type="text"
                                                            className="border-transparent form-focus-none form-control"
                                                            placeholder="Search by Sub ID "
                                                            value={onSearchText}
                                                            onChange={(e) => onFilterChange(e)}
                                                        />
                                                        <Button className="search-submit btn-icon"
                                                                onClick={async () => {
                                                                    await fillterhandel()
                                                                }}>
                                                            <Icon name="search"></Icon>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <DataTableBody bodyclass="nk-tb-tnx">
                                            <DataTableHead className="text-uppercase">
                                                {columnsSelected?.map ((column, index) => {
                                                    if (column.checked) return (<DataTableRow key={`table-column-${index}`}>
                                                        <span className="tb-lead " onClick={() => handleSort(column.column)} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ===column.column && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ===column.column && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                            {column.column}
                                                        </span>
                                                    </DataTableRow>)
                                                })}
                                                <DataTableRow>
                                                     <span className="tb-lead " onClick={() => handleSort('imp')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='imp' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='imp' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                        Impression
                                                </span>

                                                </DataTableRow>
                                                <DataTableRow>
                                                       <span className="tb-lead " onClick={() => handleSort('cs')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='cs' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='cs' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                        Clicks
                                                </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                     <span className="tb-lead " onClick={() => handleSort('dcs')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='dcs' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='dcs' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                        Duplicate Clicks
                                                </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="tb-lead " onClick={() => handleSort('ctr')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='ctr' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='ctr' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                       CTR
                                                </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                     <span className="tb-lead " onClick={() => handleSort('ics')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='ics' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='ics' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                       Invalid clicks
                                                </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                     <span className="tb-lead " onClick={() => handleSort('tcv')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='tcv' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='tcv' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                        Total CV
                                                </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                     <span className="tb-lead " onClick={() => handleSort('cv')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='cv' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='cv' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                        CV
                                                </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="tb-lead " onClick={() => handleSort('cvtcv')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='cvtcv' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='cvtcv' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                        CVT CV
                                                </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="tb-lead " onClick={() => handleSort('cvr')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='cvr' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='cvr' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                       CVR
                                                </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                     <span className="tb-lead " onClick={() => handleSort('rpc')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='rpc' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='rpc' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                      RPC
                                                        </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                     <span className="tb-lead " onClick={() => handleSort('rpm')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='rpm' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='rpm' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                      RPM
                                                        </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                      <span className="tb-lead " onClick={() => handleSort('rev')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='rev' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='rev' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                      Rev
                                                        </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                       <span className="tb-lead " onClick={() => handleSort('rtr')} role="button" >
                                                        <span className='px-1'>
                                                            <span className={sortCol ==='rtr' && sort ==='desc'?'text-primary fs-6 fw-bold':''}>&darr;</span>
                                                            <span className={sortCol ==='rtr' && sort ==='asc'?'text-primary fs-6 fw-bold':''} >&uarr;</span>
                                                        </span>
                                                       Redirect Traffic Rev
                                                        </span>
                                                </DataTableRow>


                                            </DataTableHead>
                                            {campaings?.table?.length ?
                                                campaings?.table?.map ((item, index) => {
                                                    return (
                                                        <DataTableItem key={index}>

                                                            {item.columns.map ((column, index) => {
                                                                if(columnsSelected.find(item => item.column === column.column_type).checked){
                                                                    return ( <DataTableRow key={`table-${index}`}>
                                <span
                                    className={(column.column_type === "offer" ? "text-primary tb-lead" : "tb-lead")}>{column.label} </span>
                                                                    </DataTableRow>)
                                                                }

                                                            })}

                                                            <DataTableRow>
                                                                <span className="tb-lead">{item.reporting.imp}</span>

                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <a
                                                                    href="#offerDetailles"
                                                                    onClick={async (ev) => {
                                                                        ev.preventDefault();
                                                                        await setSearch(item?.columns[0]['id'])
                                                                        await offerDetaillesClicks(item)
                                                                    }}

                                                                >
                                                                    <span className="tb-lead text-primary">{item.reporting.total_click}</span>

                                                                </a>

                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">{item.reporting.duplicate_click}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">{item.reporting.ctr}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">{item.reporting.invalid_click}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">{item.reporting.invalid_click}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <a
                                                                    href="#offerDetaillescv"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        offerDetaillesCV(item)
                                                                    }}

                                                                >
                                                                    <span className="tb-lead text-primary">{item.reporting.cv}</span>

                                                                </a>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">{item.reporting.view_through_cv}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">{item.reporting.cvr}</span>

                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">{item.reporting.rpc}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">{NumberFormatEnUS (item.reporting.rpm)}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">{NumberFormatEnUS (item.reporting.revenue)}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                              <span
                                  className="tb-lead">{NumberFormatEnUS (item.reporting.redirect_traffic_revenue)}</span>
                                                            </DataTableRow>
                                                        </DataTableItem>);

                                                }) : null}

                                        </DataTableBody>

                                        <div className="card-inner">
                                            {
                                                loadingTable ? <LoaderTable/>: campaings?.table?.length ?<></>:<div className="text-center"><span className="text-silent">No data found</span></div>
                                            }

                                        </div>

                                    </DataTable>
                                </Block>
                                <Modal isOpen={modal.B2direct} toggle={() => setModal({ B2direct: false })} className="modal-dialog-centered" size="xl" style={{width:"95%",minWidth:'95%'}}>
                                    <ModalBody>
                                        {
                                            !loadingOffer? <>
                                                <DataTable className="card-stretch">
                                                    <div className="card-inner">
                                                        <div className="card-title-group">
                                                            <div className="card-title">
                                                                <h5 className="title">Click Report </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <DataTableBody bodyclass="nk-tb-tnx">
                                                        <DataTableHead className="text-uppercase">
                                                            <DataTableRow>
                                                                <span className="tb-lead"> Error </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead"> Date </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">Offer</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead"> Revenue </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead"> Source ID </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">Sub 1</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">Sub 2</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">Sub 3</span>
                                                            </DataTableRow>

                                                            <DataTableRow>
                                                                <span className="tb-lead"> Country / City  </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">  IP Address  </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead">  Transaction ID  </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead"> ISP </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead"> Tracking url </span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="tb-lead"> Platform</span>
                                                            </DataTableRow>

                                                        </DataTableHead>
                                                        {clicks?.length ?
                                                            clicks?.map ((item, index) => {
                                                                return (
                                                                    <DataTableItem style={{fontSize: "10px"}}>
                                                                        <DataTableRow>
                                                                            <span style={{fontSize: "10px"}} className={item?.error_message !=="N/A" ? "tb-lead text-danger fs-xs":"tb-lead"}> {item?.error_message} </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <Badge className='badge-sm badge-dim'>

                                                                                <span className="tb-lead text-nowrap">{convertUnixTimestampToMMDDYY(item?.unix_timestamp)}</span>
                                                                            </Badge>

                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead">{item.relationship?.offer?.name}</span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.revenue} / {item?.revenue_type} </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.source_id}  </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.sub1}  </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.sub2}  </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.sub3}  </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead">
                                                                                {item.relationship?.geolocation?.country_name} /
                                                                                {item.relationship?.geolocation?.city_name}
                                                                            </span>
                                                                        </DataTableRow>

                                                                        <DataTableRow>
                                                                            <span className="tb-lead text-primary"> {item?.user_ip}  </span>

                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item?.transaction_id} </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead"> {item.relationship?.geolocation?.isp_name} </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead ">
                                                                                <Badge className='badge-sm badge-dim'>
                                                                                    {item?.tracking_url}
                                                                                </Badge>
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="tb-lead ">
                                                                                <Badge className='badge-sm badge-dim'>
                                                                                    {item.relationship?.device_information?.platform_name}
                                                                                </Badge>
                                                                            </span>
                                                                        </DataTableRow>
                                                                    </DataTableItem>
                                                                )}):null }
                                                    </DataTableBody>
                                                    <div className="card-inner">
                                                        { clicks?.length ? null:<><LoaderTable/></>}
                                                    </div>
                                                </DataTable>

                                            </>:<Loader/>
                                        }
                                    </ModalBody>
                                </Modal>

                            </> :
                            (account?.type ==='Cx3ads' )? <>
                                    <Block>
                                        {action === 'clicks'? <>
                                            <DataTable className="card-stretch">
                                                <div className="card-inner">
                                                    <div className="card-title-group">
                                                        <div className="card-title">
                                                            <h5 className="title">Campaigns Report</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <DataTableBody bodyclass="nk-tb-tnx">
                                                    <DataTableHead >
                                                        <DataTableRow>
                                                            <span className="tb-lead"> Id </span>

                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">  Offer Name </span>

                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">  IP </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">  Conversion Date </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead"> Campaign ID </span>
                                                        </DataTableRow>

                                                        <DataTableRow>
                                                            <span className="tb-lead">Sub Id 1</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Sub Id 2</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Sub Id 3</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Sub Id 4</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Sub Id 5</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Price</span>
                                                        </DataTableRow>

                                                    </DataTableHead>
                                                    {campaings?.length ? campaings?.map ((item, index) => {
                                                        return (<>
                                                                <DataTableItem key={index}>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead">{item?.offer?.offer_id}</span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead">{item?.offer?.offer_name} </span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <Badge className='badge-sm badge-dim'>

                                                                            <span className="tb-lead"> {item?.ip_address}  </span>
                                                                        </Badge>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <Badge className='badge-sm badge-dim'>

                                                                            <span className="tb-lead"> {convertDateTimeToMMDDYYHHMM(item?.click_date)} </span>
                                                                        </Badge>

                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead text-primary">{item?.campaign_id}</span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>

                                                                    <DataTableRow>
                                                                        <span className="tb-lead ">{item?.subid_1}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="tb-lead ">{item?.subid_2}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="tb-lead ">{item?.subid_3}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="tb-lead ">{item?.subid_4}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="tb-lead ">{item?.subid_5}</span>
                                                                    </DataTableRow>

                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead">{NumberFormatEnUS (item?.price)} </span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>
                                                                </DataTableItem>
                                                            </>
                                                        );

                                                    }) : null}
                                                    {campaings?.length ? <>
                                                        <DataTableItem className="bg-gray-200">
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead">TOTAL </span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                            <span
                                className="tb-lead">{NumberFormatEnUS (campaings?.reduce ((accumulator, item) => accumulator + item?.price, 0))} </span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>

                                                        </DataTableItem>
                                                    </> : null}
                                                </DataTableBody>
                                                <div className="card-inner">
                                                    {campaings?.length ?
                                                        <PaginationComponent
                                                            itemPerPage={itemPerPage}
                                                            totalItems={totalItems}
                                                            paginate={paginate}
                                                            currentPage={currentPage}
                                                        /> :
                                                        !loadingTable? <>
                                                            <div className="text-center">
                                                                <span className="text-silent">No data found</span>
                                                            </div>
                                                        </>:<><LoaderTable/></>


                                                    }
                                                </div>
                                            </DataTable>
                                        </>:action === 'SubAffiliateSummary'?<>
                                            <DataTable className="card-stretch">
                                                <div className="card-inner">
                                                    <div className="card-title-group">
                                                        <div className="card-title">
                                                            <h5 className="title">Sub Affiliate Summary Report</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <DataTableBody bodyclass="nk-tb-tnx">
                                                    <DataTableHead >
                                                        <DataTableRow>
                                                            <span className="tb-lead"> Sub ID </span>

                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead"> Impressions </span>

                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead"> Clicks </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">  Conversions </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead"> Conversion Rate </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead"> Revenue </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Epc</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Events</span>
                                                        </DataTableRow>
                                                    </DataTableHead>
                                                    {campaings?.length ? campaings?.map ((item, index) => {
                                                        return (<>
                                                                <DataTableItem key={index}>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead">{item?.sub_id}</span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type-text">
                                                                            <span className="tb-lead">{item?.impressions} </span>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type-text">
                                                                            <span className="tb-lead">{item?.clicks} </span>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type-text">
                                                                            <span className="tb-lead">{item?.conversions} </span>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type-text">
                                                                            <span className="tb-lead">{item?.conversion_rate} </span>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type-text">
                                                                            <span className="tb-lead">{NumberFormatEnUS (item?.revenue)} </span>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type-text">
                                                                            <span className="tb-lead">{item?.epc} </span>
                                                                        </div>
                                                                    </DataTableRow>
                                                                     <DataTableRow>
                                                                        <div className="nk-tnx-type-text">
                                                                            <span className="tb-lead">{item?.events} </span>
                                                                        </div>
                                                                    </DataTableRow>



                                                                </DataTableItem>
                                                            </>
                                                        );

                                                    }) : null}
                                                    {campaings?.length ? <>
                                                        <DataTableItem className="bg-gray-200">
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead">TOTAL </span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>


                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                            <span
                                className="tb-lead">{NumberFormatEnUS (campaings?.reduce ((accumulator, item) => accumulator + item.revenue, 0))} </span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>


                                                        </DataTableItem>
                                                    </> : null}
                                                </DataTableBody>
                                                <div className="card-inner">
                                                    {campaings?.length ?
                                                        <PaginationComponent
                                                            itemPerPage={itemPerPage}
                                                            totalItems={totalItems}
                                                            paginate={paginate}
                                                            currentPage={currentPage}
                                                        /> :
                                                        !loadingTable? <>
                                                            <div className="text-center">
                                                                <span className="text-silent">No data found</span>
                                                            </div>
                                                        </>:<><LoaderTable/></>


                                                    }
                                                </div>
                                            </DataTable>
                                        </>:<>
                                            <DataTable className="card-stretch">
                                                <div className="card-inner">
                                                    <div className="card-title-group">
                                                        <div className="card-title">
                                                            <h5 className="title">Campaigns Report</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <DataTableBody bodyclass="nk-tb-tnx">
                                                    <DataTableHead >
                                                        <DataTableRow>

                                                            <span className="tb-lead"> Id </span>

                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead"> Creative ID </span>

                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">  Conversion Id </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">  Conversion Date </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead"> Campaign Name </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead"> Event Name </span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Sub Id 1</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Sub Id 2</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Sub Id 3</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Sub Id 4</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Sub Id 5</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="tb-lead">Price</span>
                                                        </DataTableRow>

                                                    </DataTableHead>
                                                    {campaings?.length ? campaings?.map ((item, index) => {
                                                        return (<>
                                                                <DataTableItem key={index}>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead">{item?.offer_id}</span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead">{item?.creative_id} </span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead"> {item?.conversion_id} </span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <Badge className='badge-sm badge-dim'>

                                                                            <span className="tb-lead"> {convertDateTimeToMMDDYYHHMM(item?.conversion_date)} </span>
                                                                        </Badge>

                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead text-primary">{item?.offer_name}</span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead ">{item?.event_name}</span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="tb-lead ">{item?.subid_1}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="tb-lead ">{item?.subid_2}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="tb-lead ">{item?.subid_3}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="tb-lead ">{item?.subid_4}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="tb-lead ">{item?.subid_5}</span>
                                                                    </DataTableRow>

                                                                    <DataTableRow>
                                                                        <div className="nk-tnx-type">
                                                                            <div className="nk-tnx-type-text">
                                                                                <span className="tb-lead">{NumberFormatEnUS (item?.price)} </span>
                                                                            </div>
                                                                        </div>
                                                                    </DataTableRow>
                                                                </DataTableItem>
                                                            </>
                                                        );

                                                    }) : null}
                                                    {campaings?.length ? <>
                                                        <DataTableItem className="bg-gray-200">
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead">TOTAL </span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow size="xl">
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                                                                        <span className="tb-lead"></span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="nk-tnx-type">
                                                                    <div className="nk-tnx-type-text">
                            <span
                                className="tb-lead">{NumberFormatEnUS (campaings?.reduce ((accumulator, item) => accumulator + item.price, 0))} </span>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>

                                                        </DataTableItem>
                                                    </> : null}
                                                </DataTableBody>
                                                <div className="card-inner">
                                                    {campaings?.length ?
                                                        <PaginationComponent
                                                            itemPerPage={itemPerPage}
                                                            totalItems={totalItems}
                                                            paginate={paginate}
                                                            currentPage={currentPage}
                                                        /> :
                                                        !loadingTable? <>
                                                            <div className="text-center">
                                                                <span className="text-silent">No data found</span>
                                                            </div>
                                                        </>:<><LoaderTable/></>
                                                    }
                                                </div>
                                            </DataTable>
                                            </>}


                                    </Block>
                                </>  :
                                <><Welcome/></>}
                </> : <Loader />}
            </Content>
        </React.Fragment>
    );
};

export default Report;

