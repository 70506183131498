import useAxiosPrivate from './../axiosClient';
import {notification, urlBackend, ValidationMessageError} from "../../utils/Utils";


const uri =`${urlBackend}/api/server/azure`

const MESSAG_RESPONSE ={
  loading: 'Chargement...',
  create: 'Server créé avec succès.',
  update: 'Server mis à jour avec succès.',
  delete: 'Server supprimé avec succès.',
  error: 'Une erreur est survenue. Veuillez réessayer.'
}


const useAzureApi = () => {

  const axiosPrivate = useAxiosPrivate();

  const list = async (itemPerPage,current_page,onSearchText ='') => {
    return await axiosPrivate.get(`${uri}?page=${current_page}&per_page=${itemPerPage}${onSearchText!==''?`&search=${onSearchText}` :''}`).then((require) => {
      return require.data



    }).catch((error) => {
      return  error.response

    })
  };
  const getServerTeam = async () => {
    return await axiosPrivate.get(`${uri}/team-server`).then((require) => {
      return require.data

    }).catch((error) => {
      return  error.response

    })
  };




    const rdns = async (data,name) => {
        return await axiosPrivate.post(`${uri}/rdns-server/${name}`,data).then((response) => {
            if (response.data.success)
                notification('success', MESSAG_RESPONSE.update)
            else
                ValidationMessageError(response.data)
            return response.data

        }).catch((error) => {
            ValidationMessageError(error.data)
            return  error.response
        })
    };


    const store = async (data) => {
    return await axiosPrivate.post(`${uri}/create`,data).then((response) => {
      if (response.data.success)
          notification('success', MESSAG_RESPONSE.create)
      else
          ValidationMessageError(response.data)
      return response.data

    }).catch((error) => {
      ValidationMessageError(error.data)
      return  error.response
    })
  };
    const update = async (data) => {
    return await axiosPrivate.put(`${uri}/update`,data).then((response) => {
      if (response.data.success)
          notification('success', MESSAG_RESPONSE.update)
      else
          ValidationMessageError(response.data)
      return response.data

    }).catch((error) => {
      ValidationMessageError(error.data)
      return  error.response
    })
  };


  const handlePostAction = async(action,data) =>{
        return await axiosPrivate.post(`${uri}/${action}`,data).then((response) => {
            if (response.data.success){
                notification('success', `${action} Server  has passed successfully | ${data.name} `)
                return response.data
            }
            else
                ValidationMessageError(response.data)
            return response.data

        }).catch((error) => {
            ValidationMessageError(error.data)
            return  error.response
        })
    }

  const remove = async (id) => {
    return await axiosPrivate.post(`${uri}/delete`,{id}).then((response) => {
      if (response.data.success)
          notification('success', MESSAG_RESPONSE.delete)
      else
          notification('error', MESSAG_RESPONSE.error)
      return response.data;

    }).catch((error) => {
      notification('error', MESSAG_RESPONSE.error)
      return  error.response
    })
  };

  return {
    list,
    store,
    update,
    remove,
    handlePostAction,
    getServerTeam,
    rdns,
  };
};

export default useAzureApi;


