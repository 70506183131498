import React, {  useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useForm } from "react-hook-form";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../../components/block/Block";
import {
    Button, Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon, PaginationComponent, RSelect, TooltipComponent
} from "../../../components/Component";
import {
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Modal,
    ModalBody,
    UncontrolledDropdown
} from "reactstrap";
import Loader from "../../../layout/loader/Loader";
import useActivityApi from "../../../api/activity/activityApi";

const Gsuit = () => {
    const {
        list,
        list_gsuit,
    } = useActivityApi();


    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [users, setusers] = useState();
    const [sm, updateSm] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(100);
    const [totalItems , setTotalItems] = useState(100);
    const [currentItems, setIcurrentItems] = useState([]);
    const [indexOfLastItem, setindexOfLastItem] = useState(0);
    const [indexOfFirstItem, setindexOfFirstItem] = useState(0);

    const init =async () => {
        setLoading(true)

        const result = await list(itemPerPage, currentPage)
        if (result.success) {
            setData([...result.data.data])
            setCurrentPage(result.data.current_page)
            setItemPerPage(result.data.per_page)
            setIcurrentItems(result.data.data)
            setindexOfFirstItem(result.from)
            setindexOfLastItem(result.data.last_page)
            setTotalItems(result.data.total)
            setLoading(false)
        }
    }
    //  loads data
    useEffect(async () => {
        await init()
    }, [currentPage]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Head title="Catégories des dépenses"></Head>
            <Content>
                {!loading? <>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle tag="h3" page>
                                    Smtp  Activities
                                </BlockTitle>
                                <BlockDes className="text-soft">
                                    <p></p>
                                </BlockDes>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>

                    <Block>
                        <DataTable className="card-stretch">

                            <DataTableBody>
                                <DataTableHead>
                                    <DataTableRow>
                                        <span className="sub-text">ID</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Object</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Action</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">User</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Server</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Method</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Ip</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="">DATE</span>
                                    </DataTableRow>


                                    <DataTableRow className="">
                                        <span className="sub-text">Data </span>
                                    </DataTableRow>
                                </DataTableHead>
                                {/*Head*/}
                                {currentItems.length > 0
                                    ? currentItems.map((item) => {
                                        return (
                                            <DataTableItem key={item.id}>
                                                <DataTableRow>
                                                    <span className="tb-lead text-primary">
                                                        #{item.id}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow>{item.subject}</DataTableRow>
                                                <DataTableRow>
                                                    <Badge className={`bg-${item.color}`}>
                                                        {item.status}
                                                    </Badge>
                                                </DataTableRow>
                                                <DataTableRow>{item?.user?.name}</DataTableRow>
                                                <DataTableRow>{item.model}</DataTableRow>

                                                <DataTableRow>{item.method}</DataTableRow>
                                                <DataTableRow>
                                                    <span className='icon-text'>
                                                          <em class="text-primary icon ni ni-globe"></em>
                                                    <span className='text-primary bold'>
                                                    {item.ip}
                                                    </span>
                                                    </span>

                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className='tb-sub tb-amount'>
                                                         {item.created_at}
                                                    </span>

                                                </DataTableRow>
                                                <DataTableRow>{item.countryName}/{item.regionName}</DataTableRow>

                                            </DataTableItem>
                                        );
                                    })
                                    : null}
                            </DataTableBody>
                            <div className="card-inner">
                                {currentItems.length > 0 ? (
                                    <PaginationComponent
                                        itemPerPage={itemPerPage}
                                        totalItems={totalItems}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <span className="text-silent">No data found</span>
                                    </div>
                                )}
                            </div>
                        </DataTable>
                    </Block>

                </>:<Loader/>}

            </Content>
        </React.Fragment>
    );
};

export default Gsuit;
