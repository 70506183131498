import useAxiosPrivate from './../axiosClient';
import {notification, urlBackend, ValidationMessageError} from "../../utils/Utils";
import Gsuit from "../../pages/app/activity/gsuit";


const uri =`${urlBackend}/api/activity`

const MESSAG_RESPONSE ={
    loading: 'Chargement...',
    create: 'activity créé avec succès.',
    update: 'activity mis à jour avec succès.',
    delete: 'activity supprimé avec succès.',
    error: 'Une erreur est survenue. Veuillez réessayer.'
}


const useActivityApi = () => {
    const axiosPrivate = useAxiosPrivate();

    const list = async (itemPerPage='',current_page='',onSearchText='') => {
        return await axiosPrivate.get(`${uri}?page=${current_page}&per_page=${itemPerPage}${onSearchText!==''?`&search=${onSearchText}` :''}`).then((require) => {
            return require.data

        }).catch((error) => {
            return  error.response

        })
    };
    const list_gsuit = async (itemPerPage='',current_page='') => {
        return await axiosPrivate.get(`${uri}/gsuit/?page=${current_page}&per_page=${itemPerPage}`).then((require) => {
            return require.data

        }).catch((error) => {
            return  error.response

        })
    };


    return {
        list,list_gsuit

    };
};

export default useActivityApi;


