import useAxiosPrivate from './../axiosClient';
import {notification, urlBackend, ValidationMessageError} from "../../utils/Utils";


const uri =`${urlBackend}/api/storage/azure`

const MESSAG_RESPONSE ={
    loading: 'Chargement...',
    create: 'Upload de fichiers créé avec succès.',
    update: 'Upload de fichiers mis à jour avec succès.',
    delete: 'Upload de fichiers supprimé avec succès.',
    error: 'Une erreur est survenue. Veuillez réessayer.'
}


const useAzureStorageApi = () => {

    const axiosPrivate = useAxiosPrivate();

    const list = async (itemPerPage,current_page,onSearchText ='') => {
        return await axiosPrivate.get(`${uri}?page=${current_page}&per_page=${itemPerPage}${onSearchText!==''?`&search=${onSearchText}` :''}`).then((require) => {
            return require.data
        }).catch((error) => {
            return  error.response

        })
    };

    const edit = async (id) => {
        return await axiosPrivate.get(`${uri}/${id}`).then((response) => {
            return response.data

        }).catch((error) => {
            notification('error', MESSAG_RESPONSE.error)
            return  error.response
        })
    };
    const totalStorage = async () => {
        return await axiosPrivate.get(`${uri}/totalStorage`).then((response) => {
            return response.data

        }).catch((error) => {
            notification('error', MESSAG_RESPONSE.error)
            return  error.response
        })
    };


    const store = async (data) => {
      const toast_id =   notification('info', MESSAG_RESPONSE.loading)

        return await axiosPrivate.post(`${uri}/upload`,data,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            if (response.data.success)
                notification('success', MESSAG_RESPONSE.create,toast_id)
            else
                ValidationMessageError(response.data)
            return response.data

        }).catch((error) => {
            console.log('error', error)
            notification('error',error.response.data.data,toast_id)
            return  error.response
        })
    };


    return {
        list,
        edit,
        totalStorage,
        store,

    };
};

export default useAzureStorageApi;


